import React,{useState}from "react";
import {Dropdown} from 'react-bootstrap';
import { MdPersonOutline,MdExitToApp,MdMenu } from 'react-icons/md';
import Profile from '../account/Profile';
import { useSelector } from 'react-redux';
import { useFirebase, isLoaded } from 'react-redux-firebase'
import {NotificationContainer, NotificationManager} from 'react-notifications';

const Navbar = ({match,setShowSidebar}) => {
    const firebase = useFirebase()
    const logout = () => {
        return firebase.logout();
    }
     // New dialog
    const profile = useSelector(state => state.firebase.profile)
    const [editDialogOpen, changeDialogState] = useState(false)
    const toggleDialog = () => {
      changeDialogState(!editDialogOpen)
    }
    var headline = "Bücher"
    
    if(process.env.REACT_APP_ENV=== "teachers"){
      headline = "Fächer"
    }
    let path = ""+(match.path);
    if (path.includes("users")) {
      headline = "Benutzer"
    }
    if (path.includes("dashboard")) {
      headline = "Dashboard"
    }
    if (path.includes("feedback")) {
      headline = "Feedback"
    }
    if (path.includes("bibliothek")) {
      headline = "Bibliothek"
    }
    if (path.includes("subjects")) {
      headline = "Fächer"
    }
    

    const handleChangeSuccess = () => {
      NotificationManager.success('Ihr Passwort wurde erfolgreich geändert.');
      toggleDialog();
    }

  if(isLoaded(profile)){
    return(
      <nav className="navbar navbar-light bg-white flex-md-nowrap border-bottom" style={{padding: "10px 30px"}}>
        <span className="d-block d-md-none">
          <MdMenu size="1.5em" className="text-button" onClick={() => setShowSidebar(true)}/>
        </span>
        <span className="navbar-brand" >{headline}</span>
        <ul className="navbar-nav">
          <li className="nav-item text-nowrap">
          <Dropdown alignRight={true} >
            <Dropdown.Toggle variant="light" id="dropdown-basic" className="rounded-circle">
              {profile.displayName.charAt(0)}
            </Dropdown.Toggle>

            <Dropdown.Menu className="border-0 shadow">
              <Dropdown.Item onClick={toggleDialog} className="d-flex align-items-center text-button"><MdPersonOutline /><span className="ml-2">Profil</span></Dropdown.Item>
              <Dropdown.Item onClick={logout} className="d-flex align-items-center text-button"><MdExitToApp /><span className="ml-2">Logout</span></Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          </li>
        </ul>
        <NotificationContainer/>
        <Profile show={editDialogOpen} handleModalClose={toggleDialog} handleChangeSuccess={handleChangeSuccess}></Profile>
      </nav>
    )
  }else{
    return(
      <nav className="navbar navbar-light bg-white flex-md-nowrap border-bottom" style={{padding: "10px 30px"}}>
      <a className="navbar-brand" href="/">Bücher</a>
    </nav>
    )
  
  }
}

export default Navbar;