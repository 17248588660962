// this Layout component wraps every page with the app header on top
// check out App.js to see how it's used
import React, {useState} from 'react'
import { Redirect } from "react-router-dom";
import { useSelector } from 'react-redux'
import { useFirebase } from 'react-redux-firebase'
import { isEmpty } from 'react-redux-firebase'
import customer from 'theme/customer';
import {Form,Button} from 'react-bootstrap'
import { Link } from "react-router-dom";
import {NotificationContainer, NotificationManager} from 'react-notifications';
 

const Login = () => {
    const firebase = useFirebase()
    const auth = useSelector(state => state.firebase.auth)
    const [loginerror, setLoginError] = useState(false)
    const onSubmit = event => {
        event.preventDefault();
        const {email,password} = event.target.elements
        firebase.login({
            email:email.value,password:password.value
        })
        .catch(err => {
            NotificationManager.error('Bitte überprüfen Sie Ihre Daten', 'Login Fehler!');
            setLoginError(true);
        })
    }
    
    const errorClasses = loginerror ? "border border-danger" : "";
    if (!isEmpty(auth)) {
        return <Redirect to="/" />;
    } else {
        return(
            <div className="d-flex align-items-center justify-content-center flex-column" style={{height:"100vh"}}>
                   <div className="d-flex align-items-center justify-content-center text-primary mb-5">
                        <h2 className="mb-0 font-weight-normal">{customer.name} <b>LMS</b></h2>
                    </div>
                   <div  className="bg-white p-4 shadow-border login-form">
                    <h3 className="text-center">Anmelden</h3>
                    <Form onSubmit={onSubmit} className="mt-5">
                            <Form.Group>
                                <Form.Label>E-Mail Adresse</Form.Label>
                                <Form.Control type="email" name="email"  required placeholder="E-Mail" className={errorClasses} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Ihr Passwort</Form.Label>
                                <Form.Control type="password" name="password" required placeholder="Ihr Passwort" className={errorClasses} />
                            </Form.Group>
                            <Button className="w-100 p-3 mt-3" variant="primary" type="submit">
                                Login
                            </Button>
                            <Link to='/recovery' className="text-center mt-5 d-block">Passwort vergessen?</Link>
                        </Form>
                        
                        <NotificationContainer/>
                    </div>
                    <div className="d-flex pt-4 justify-content-end" style={{minWidth:"50%"}}>
                        <a className="pr-2" target="_blank" href="/datenschutz">Datenschutzerklärung</a> 
                        <a className="pl-2" target="_blank" href="/agb">AGB</a>
                    </div>
                   
               </div>
        )
    }
}


export default Login;