import React,{useState} from 'react';
import ImageCropper from '../../components/ImageCropper';
import {Button, Form} from 'react-bootstrap'
import {uploadFile} from  '../../helpers/upload'
import { useFirebase,useFirestore } from 'react-redux-firebase';
import { useSelector } from 'react-redux';

const ImageContent = ({handleSubmit,image,format,modeEdit,changeMode,taskId}) =>  {
    const firebase = useFirebase()
    const firestore = useFirestore()
    const profile = useSelector(state => state.firebase.profile)
    // useFirestoreConnect([
    //     {
    //       collection: 'files',
    //       where: [['publisherId','==',profile.publisherId],['type','==',"Image"]]
    //     }
    //   ])
    // const images = useSelector(state => state.firestore.ordered.files)
    const saveImageContent = (event) => {
        event.preventDefault();
        const {imageType} = event.target.elements
        var imageFormat = "original";
        for(var i = 0;i<imageType.length;i++){
            if(imageType[i].checked){
                imageFormat = imageType[i].value
            }
        }
        if(!modeEdit){
            uploadFile(firebase,croppedImage,"task-images/"+taskId)
            .then((path) => {
                firestore.add('files', {
                  path:path,
                  type:"Image",
                  name:croppedImage.name,
                  publisherId:profile.publisherId
                })
                handleSubmit({
                    path:path,
                    text:croppedImage.name,
                    type:"Image",
                    format:imageFormat
                })
            });
        }else{
            handleSubmit({
                format:imageFormat
            })
        }
       
    }
    const [croppedImage, setCroppedImage] = useState();
    return  (
        <div className="modal-body-container">
            <Form onSubmit={saveImageContent} className="modal-content-container-fixed">
                <div className="p-4">
                    {!modeEdit &&
                        <div>
                            {/* {
                                images &&
                                <div>
                                    <h5 className="mb-3">Bild auswählen</h5>
                                    <div className="d-flex flex-wrap">
                                        {images.map((image) => {
                                            return <div key={image.id} style={{maxWidth: 80,overflow: 'hidden'}} className="mr-2">
                                                <div style={{width:80,height:80,backgroundImage:`url(${image.path})`,backgroundSize: 'contain'}}></div>
                                                <small > {image.name}</small>
                                            </div>
                                        })}
                                    </div>
                                </div>

                            }
                            */}
                            <h5 className="mb-3">Bild hochladen</h5>
                            <ImageCropper setCroppedImage={setCroppedImage} image={image}/>

                            
                            

                        </div>
                        
                    }
                    {modeEdit && <h5 className="mb-3">Bild bearbeiten</h5>}
                    {modeEdit && 
                        <div className="d-flex flex-column align-items-start">
                            <img src={image} style={{maxWidth: "100%",maxHeight: 300}} alt="Bildvorschau"/>
                            <span className="text-button text-primary" onClick={() => changeMode(false)}>Bild löschen</span>
                        </div>
                    }
                    
                    {(croppedImage || modeEdit) && <div className="mt-3">
                        <h6>Optionen</h6>
                        <Form.Group>
                            <Form.Check defaultChecked={(format && format==="original") || !format ? true : false} custom inline label="Original Größe" type="radio" value="original" name="imageType" id="custom-inline-1" />
                            <Form.Check  defaultChecked={format && format==="full" ? true : false} custom inline label="Komplette Breite" type="radio" value="full" name="imageType" id="custom-inline-2"  />
                            <Form.Check  defaultChecked={format && format==="thumb" ? true : false} custom inline label="Vorschau" type="radio" value="thumb" name="imageType" id="custom-inline-3" />
                        </Form.Group>
                    </div>}
                </div>
                <div className="p-3 bg-white d-flex justify-content-end">
                    <Button variant="primary" type="submit" >Speichern</Button>
                </div>
            </Form>
        </div>
    )
}

export default ImageContent;