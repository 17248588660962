// this Layout component wraps every page with the app header on top
// check out App.js to see how it's used

import React from 'react';
import { Redirect } from "react-router-dom";
import { useSelector } from 'react-redux'
import { useFirebase } from 'react-redux-firebase'
import { isEmpty,isLoaded } from 'react-redux-firebase'
import LoadingPage from '../../components/LoadingPage';
import {Button} from 'react-bootstrap'
import customer from 'theme/customer';
import sent_messages from 'theme/sent_messages.svg'


const MailConfirmation = ({ match }) => {
    const firebase = useFirebase()
    const logout = () => {
        return firebase.logout();
    }
    const auth = useSelector(state => state.firebase.auth)

    const resendMail = () => {
        firebase.auth().currentUser.sendEmailVerification().then(function() {
          }).catch(function(error) {
          });
    }
    var timer = {
        interval: null,
        seconds: 30,
    
        start: function () {
            var self = this
            this.interval = setInterval(function () {
                self.seconds--;
    
                if (self.seconds == 0) 
                    window.location.reload();
            }, 1000);
        },
    
        stop: function () {
            window.clearInterval(this.interval)
        }
    }
    
    timer.start();
    
    if(isLoaded(auth)){
        if(isEmpty(auth)){
            return <Redirect to={{pathname: "/"}}></Redirect>
        }else{
            if(auth.emailVerified){
                return <Redirect to={{pathname: "/"}}></Redirect>
            }else{
                return(
                    <div className="d-flex align-items-center justify-content-center flex-column" style={{height:"100vh"}}>
                   <div className="d-flex align-items-center justify-content-center text-primary mb-5">
                        <h2 className="mb-0 font-weight-normal">{customer.name} <b>LMS</b></h2>
                    </div>
                   <div style={{minWidth:"50%"}} className="bg-white p-4 shadow-border d-flex align-items-center justify-content-center flex-column">
                    <h3 className="text-center mb-5">Bitte bestätigen Sie noch<br></br> Ihre E-Mail Adresse!</h3>
                    <img alt="Email Sent" src={sent_messages} style={{width:"40%"}}/>
                    <span className="text-center mt-5">
                        Wir haben an "{auth.email}" eine E-Mail Adresse zur Bestätigung versendet.<br></br> Bitte überprüfen Sie Ihr Postfach und Ihren Spam Ordner.
                    </span>
                    <Button onClick={resendMail} variant="primary mt-5">E-Mail erneut senden</Button>
                    <span  onClick={logout} className="text-button mt-3 text-primary">Logout</span>
                    </div>
               </div>
                )
            }
        }
    }else{
        return <LoadingPage />
    }
 }


export default MailConfirmation;