import React, { useState } from "react";
import QuizTitleForm from "./QuizTitleForm";
import QuizQuestionForm from "./QuizQuestionForm";
import { Button } from "react-bootstrap";
import StepZilla from "react-stepzilla";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { useSelector } from "react-redux";
import { useFirestore } from "react-redux-firebase";

const QuizContent = ({ quiz, handleSubmit }) => {
  const profile = useSelector((state) => state.firebase.profile);
  const auth = useSelector((state) => state.firebase.auth);
  const firestore = useFirestore();
  const [state, updateState] = useState({
    quiz: {},
    questions: [
      {
        question: "",
        answers: [],
      },
      {
        question: "",
        answers: [],
      },
    ],
    initialQuestions: [],
  });
  let quizId = undefined;
  if (quiz && Object.keys(state.quiz).length === 0) {
    let quizId = quiz;
    firestore.get({ collection: "quizes", doc: quiz }).then((doc) => {
      const quiz = { ...doc.data(), id: quizId };
      firestore
        .get({
          collection: "quizes/" + doc.id + "/quizquestions",
          orderBy: "order",
        })
        .then((querySnapshot) => {
          let questions = [];
          querySnapshot.forEach(function (doc) {
            questions.push({
              id: doc.id,
              question: doc.data().title,
              answers: doc.data().options.map((answer, i) => {
                return { answer: answer, correct: doc.data().solutions[i] };
              }),
              image: doc.data().image,
            });
          });
          let initialQuestions = [...questions];
          questions.push({
            question: "",
            answers: [],
          });
          updateState({
            ...state,
            quiz,
            questions,
            initialQuestions,
          });
        });
    });
  }

  const updateQuiz = (key, value) => {
    const { quiz } = state;
    quiz[key] = value;
    updateState({
      ...state,
      quiz,
    });
  };

  const updateQuestions = (key, value, number) => {
    const { questions } = state;
    if (key === "question" || key === "image") {
      questions[number][key] = value;
    } else {
      var index = 0;
      if (key.indexOf("answer") !== -1) {
        index = parseInt(key.replace("answer", ""));
        if (!questions[number]["answers"][index]) {
          questions[number]["answers"][index] = [];
          questions[number]["answers"][index]["correct"] = false;
        }
        questions[number]["answers"][index]["answer"] = value;
      }
      if (key.indexOf("solution") !== -1) {
        index = parseInt(key.replace("solution", ""));
        if (!questions[number]["answers"][index]) {
          questions[number]["answers"][index] = [];
          questions[number]["answers"][index]["correct"] = false;
        }
        questions[number]["answers"][index]["correct"] =
          !questions[number]["answers"][index]["correct"];
      }
    }
    updateState({
      ...state,
      questions,
    });
  };

  const saveQuiz = () => {
    if (!saveRequirements()) {
      return;
    }
    if (!quiz) {
      const { quiz, questions } = state;
      var num = parseInt(quiz.numberAnswers ?? 4);
      var questiondata = questions.flatMap((question, index) => {
        if (question.question !== "") {
          return {
            title: question.question,
            options: question.answers
              .slice(0, num)
              .map((answer) => answer.answer),
            solutions: question.answers
              .slice(0, num)
              .map((answer) => answer.correct),
            order: index,
            image: question.image ? question.image : null,
          };
        }
      });
      firestore
        .add("quizes", {
          ...quiz,
          publisherId: profile.publisherId,
          creator: {
            id: auth.uid,
            name: profile.displayName,
          },
          type: "Quiz",
        })
        .then((data) => {
          let batch = firestore.batch();
          questiondata.forEach((question) => {
            if (question) {
              var newQuestionRef = firestore
                .collection("quizes/" + data.id + "/quizquestions")
                .doc();
              batch.set(newQuestionRef, { ...question });
            }
          });
          batch
            .commit()
            .then(() => {
              handleSubmit({
                text: quiz.title,
                numberAnswers: quiz.numberAnswers ?? 4,
                type: "Quiz",
                quizId: data.id,
              });
            })
            .catch((err) => {
              console.error("Error:", err);
            });
        });
    } else {
      editQuiz();
    }
  };

  const editQuiz = (questiondata) => {
    if (!saveRequirements()) {
      return false;
    }
    const { quiz, questions, initialQuestions } = state;
    var num = parseInt(quiz.numberAnswers ?? 4);
    questiondata = questions.flatMap((question, index) => {
      if (question.question !== "") {
        return {
          id: question.id,
          title: question.question,
          options: question.answers
            .slice(0, num)
            .map((answer) => answer.answer),
          solutions: question.answers
            .slice(0, num)
            .map((answer) => answer.correct),
          image: question.image ? question.image : null,
        };
      }
    });
    firestore
      .update("quizes/" + quiz.id, {
        title: quiz.title,
        text: quiz.text ?? "",
        numberAnswers: quiz.numberAnswers ?? 4,
      })
      .then(() => {
        let batch = firestore.batch();
        var newQuestionRef;
        questiondata.forEach((question, index) => {
          if (question) {
            if (question.id) {
              newQuestionRef = firestore
                .collection("quizes/" + quiz.id + "/quizquestions")
                .doc(question.id);
              batch.update(newQuestionRef, {
                title: question.title,
                options: question.options,
                solutions: question.solutions,
                order: index,
                image: question.image,
              });
            } else {
              newQuestionRef = firestore
                .collection("quizes/" + quiz.id + "/quizquestions")
                .doc();
              batch.set(newQuestionRef, {
                title: question.title,
                options: question.options,
                solutions: question.solutions,
                order: index,
                image: question.image,
              });
            }
          }
        });

        var questionDataWithoutNull = questiondata.filter((q) => q && q.id);
        initialQuestions.forEach((question) => {
          if (
            questionDataWithoutNull.filter((q) => q.id === question.id)
              .length === 0
          ) {
            newQuestionRef = firestore
              .collection("quizes/" + quiz.id + "/quizquestions")
              .doc(question.id);
            batch.delete(newQuestionRef);
          }
        });
        batch
          .commit()
          .then(() => {
            handleSubmit({
              text: quiz.title,
            });
          })
          .catch((err) => {
            console.error("Error:", err);
          });
      });
  };

  const addQuestion = () => {
    const { questions } = state;
    const newQuestions = [...questions, { question: "", answers: [] }];
    return updateState({
      ...state,
      questions: newQuestions,
    });
  };

  const saveRequirements = () => {
    const { questions, quiz } = state;
    let ready = true;
    if (!quiz.title) {
      NotificationManager.error(
        "Achtung ohne Titel kann das Quiz nicht gespeichert werden.",
        "Titel fehlt!"
      );
      ready = false;
    }
    if (quiz.numberAnswers) {
      var num = parseInt(quiz.numberAnswers);
      if (num < 2 || num > 6) {
        NotificationManager.error(
          "Achtung die Anzahl an Antworten muss zwischen 2-6 liegen.",
          "Anzahl Antworten fehlerhaft!"
        );
        ready = false;
      }
    }
    questions.forEach((question, index) => {
      if (question.question !== "") {
        if (question.answers.filter((answer) => answer.correct).length === 0) {
          NotificationManager.error(
            "Frage " + (index + 1) + " enthält keine Lösung",
            "Lösung fehlt."
          );
          ready = false;
        }
      } else {
        if (index !== questions.length - 1) {
          NotificationManager.error(
            "Frage " + (index + 1) + " enthält keine Frage",
            "Frage fehlt."
          );
          ready = false;
        }
      }
    });
    return ready;
  };

  const removeQuestion = (index) => {
    const { questions } = state;
    if (questions.length === 2) {
      NotificationManager.error(
        "Achtung ein Quiz muss mindestens eine Frage enthalten",
        "Löschen fehlgeschlagen!"
      );
      return false;
    }

    const newQuestions = questions.filter((value, i) => i !== index);
    return updateState({
      ...state,
      questions: newQuestions,
    });
  };
  const [imagepicker, showImagePicker] = useState(false);

  const { questions } = state;
  var questionsteps = questions.map((question, i) => {
    return {
      name: "Question " + (i + 1),
      component: (
        <QuizQuestionForm
          firestore={firestore}
          profile={profile}
          key={i}
          imagepicker={imagepicker}
          showImagePicker={showImagePicker}
          update={updateQuestions}
          number={i}
          add={addQuestion}
          stepNum={i + 1}
          question={question}
          max={questions.length}
          removeQuestion={removeQuestion}
          numberAnswers={parseInt(state.quiz.numberAnswers)}
        />
      ),
    };
  });

  let steps = [
    {
      name: "Title",
      component: (
        <QuizTitleForm
          key={"xxx"}
          update={updateQuiz}
          stepNum={0}
          quiz={state.quiz}
          isEdit={!quizId}
        />
      ),
    },
    ...questionsteps,
  ];
  console.log(state);
  return (
    <div className="modal-body-container">
      <div className="p-3 modal-content-container-fixed">
        <StepZilla
          steps={steps}
          showNavigation={false}
          showSteps={false}
          hocValidationAppliedTo={[1, 2]}
        />
      </div>
      {!imagepicker && (
        <div className="p-3 bg-white d-flex justify-content-end">
          <Button variant="primary" onClick={saveQuiz}>
            Speichern
          </Button>
        </div>
      )}
      <NotificationContainer />
    </div>
  );
};

export default QuizContent;
