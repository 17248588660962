import React,{useState} from 'react';
import {Form,Button} from 'react-bootstrap'
import RichTextEditor from 'react-rte'
const TextblockContent = ({handleSubmit}) =>  {
    const onSubmit = event => {
        event.preventDefault()
        const values = {
            text:editorState.toString('markdown'),
            type:"Textblock",
        }
        handleSubmit(values);
        setEditorState(RichTextEditor.createEmptyValue());
      }
    const [editorState, setEditorState] = useState(
        RichTextEditor.createEmptyValue(),
    );
    const toolbarConfig = {
        // Optionally specify the groups to display (displayed in the order listed).
        display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
        INLINE_STYLE_BUTTONS: [
          {label: 'Fett', style: 'BOLD'},
          {label: 'Kursiv', style: 'ITALIC'},
        ],
        BLOCK_TYPE_DROPDOWN: [
          {label: 'Normal', style: 'unstyled'},
          {label: 'Überschrift Groß', style: 'header-one'},
          {label: 'Überschrift Medium', style: 'header-two'},
          {label: 'Überschrift Klein', style: 'header-three'}
        ],
        BLOCK_TYPE_BUTTONS: [
          {label: 'UL', style: 'unordered-list-item'},
          {label: 'OL', style: 'ordered-list-item'}
        ]
      };
    return  (
        <Form onSubmit={onSubmit} className="modal-body-container" > 
            <Form.Group style={{minHeight:200}} className="p-3">
            <RichTextEditor 
                value={editorState}
                onChange={setEditorState}
                rows="8"
                placeholder="Seiteninhalt"
                toolbarConfig={toolbarConfig}
            />
            </Form.Group>   
           
            <div className="p-3 bg-white d-flex justify-content-end">
                <Button variant="primary" type="submit">Speichern</Button>
            </div>
        </Form>
    )
}

export default TextblockContent;