import React from 'react'
import {Link} from 'react-router-dom'
import { MdChevronRight} from 'react-icons/md';
import { useSelector } from 'react-redux';
import {
  useFirestoreConnect,
  isLoaded,
} from 'react-redux-firebase'

const useTaskList = (subjectId,topicId) => {
  const myTasks = 'myTasks'+topicId
  useFirestoreConnect([
    {
      collection: `subjects`,
      doc: subjectId,
      subcollections: [{ 
        collection: "topics",doc: topicId,
        subcollections:[{collection:"tasks",orderBy:['number'],}] 
      }],
      storeAs: myTasks
    }
  ])

  const tasks = useSelector(state => state.firestore.ordered[myTasks])

  return { tasks,}
}
const TaskList  = ({subjectId,topic}) => {
  const {tasks} = useTaskList(subjectId,topic.id)
  if(isLoaded(tasks)){
  return(
    <div>
          <div className="list-group">
          {tasks.map(task => (
              <Link key={task.id} to={{pathname:`${topic.id}/tasks/${task.id}`}} className="list-group-item border-0 list-group-item-action px-4 shadow-border mb-3">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <span className="small text-muted">Aufgabe {task.number}</span>
                    <span>{task.title}</span>
                  </div>
                  <MdChevronRight className="text-muted" size="1.5em" />
                </div>
              </Link>
            ))}
          </div>
    </div>
)
}else{
  return <div></div>
}
}

export default TaskList
