import React, {useState,useEffect} from 'react'
import { Row,Col,Pagination, Container,Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { AiFillAudio} from 'react-icons/ai';
import { BsFillCameraVideoFill,BsThreeDots } from 'react-icons/bs';
import {GiPerspectiveDiceThree } from 'react-icons/gi';
import {deleteFile} from '../../helpers/upload'
import { MdPictureAsPdf,MdDeleteForever} from 'react-icons/md';
import DeleteModal from '../helpers/DeleteModal'
import FilePreview from './FilePreview'
import {
    useFirestoreConnect,
    useFirestore,
    useFirebase
  } from 'react-redux-firebase'
import { Input } from 'react-dropzone-uploader';

const useBibData = (publisherId) => {
    const firebase = useFirebase()
    const firestore = useFirestore()

    const [pointer, setPointer] = useState(undefined) // Current pointer
    const [forwardPointer, setForwardPointer] = useState(undefined) // Forward pointer
    const [backwardPointers, setBackwardPointers] = useState([]) // Backward pointers chain
    const [page, setPage] = useState(0) // current  page
    const [limit, setLimit] = useState(8) // limit
    const [search,setSearch] = useState("")
    const [pageLimitReached,setPageLimitReached] = useState(false)

    const myPublisher = 'myPublisher'+publisherId
    useFirestoreConnect([
        {
        collection: 'publishers',doc:publisherId,storeAs: myPublisher
        }
    ])
    const publishers = useSelector(state => state.firestore.ordered[myPublisher])
    useFirestoreConnect([
        {
            collection: 'files',
            where: [['publisherId','==',publisherId]],
            orderBy: [["name", "asc"]],
            limit: limit + 1,
            startAt:pointer
        }
      ])
    const files = useSelector(state => state.firestore.ordered.files)

    useEffect(() => {
        if (files && files.length > limit) { 
          setForwardPointer(files[files.length-1]?.name)
          files.splice(-1,1)
        }
      }, [files])
    
      // add pointer to back chain 
    useEffect(() => {
        if (files) {
            const firstItem = files[0]
            if (firstItem) { 
                setBackwardPointers((prevState) => { 
                    const newState = prevState
                    newState[page] = firstItem.name
                    return newState
                  })
            }
        }
    }, [page, files])

    const handlePageChange = (newPage) => {
       
        if (files) {
          if (page < newPage && forwardPointer) {
            // Forward
            setPointer(forwardPointer)
          } else if (page > newPage) {
            // Backward
            setPointer(backwardPointers[newPage])
          }
        }
        if (files && files.length === limit) {
          setPage(newPage);
          setPageLimitReached(false)
        } else { 
          setPageLimitReached(true)
        }
      };

    const formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
    
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    
        const i = Math.floor(Math.log(bytes) / Math.log(k));
    
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    const deleteThisFile = () => {
        deleteFile(firebase,selectedFile.path).then(() => {
            firestore.delete({ collection: 'files/', doc: selectedFile.id }).then(() => {
                changeDialogStateDelete(!deleteDialogOpen)
                setSelectedFile(false)
            })
        })
    }

    const [deleteDialogOpen, changeDialogStateDelete] = useState(false)
    const [selectedFile, setSelectedFile] = useState(false)

    return{publishers,formatBytes,files,deleteDialogOpen,changeDialogStateDelete,selectedFile, setSelectedFile,deleteThisFile,limit, setLimit, page, handlePageChange,setSearch,pageLimitReached}
}


const FileBib = () => {
    const profile = useSelector(state => state.firebase.profile)
    const {publishers,formatBytes,files,deleteDialogOpen,changeDialogStateDelete,selectedFile, setSelectedFile,deleteThisFile,page,handlePageChange,setSearch,search,pageLimitReached} = useBibData(profile.publisherId)
    const getFilePreview = (file) => {
        switch (file.type) {
            case "Image": return <FilePreview file={file} setSelectedFile={setSelectedFile} changeDialogStateDelete={changeDialogStateDelete} /> 
            case "ArRefImage": return <FilePreview file={file} setSelectedFile={setSelectedFile} changeDialogStateDelete={changeDialogStateDelete} />       
            case "Cover Image": return <FilePreview file={file} setSelectedFile={setSelectedFile} changeDialogStateDelete={changeDialogStateDelete} />             
            case "Audio": return    <FilePreview file={file} icon={<AiFillAudio size={"2em"}/>} setSelectedFile={setSelectedFile} changeDialogStateDelete={changeDialogStateDelete} />
            case "Video": return  <FilePreview file={file} icon={<BsFillCameraVideoFill size={"2em"}/>} setSelectedFile={setSelectedFile} changeDialogStateDelete={changeDialogStateDelete} />
            case "Pdf": return  <FilePreview file={file} icon={<MdPictureAsPdf size={"2em"} />} setSelectedFile={setSelectedFile} changeDialogStateDelete={changeDialogStateDelete} />
            case "3D Model": return  <FilePreview file={file} icon={<GiPerspectiveDiceThree size={"2.6em"} />} setSelectedFile={setSelectedFile} changeDialogStateDelete={changeDialogStateDelete} />
            default: return <div></div>
        }
           
    }

    if(!publishers || !files){
        return(
            <div></div>
        )
    }

    return(
        <div>
            <Container>
                <Row className="mt-3">
                    <Col>
                    <div className="d-flex align-items-center justify-content-between">
                        <p>Belegter Speicherplatz: {formatBytes(publishers[0].usedStorage ?? 0)}</p>
                        <Pagination>
                            <Pagination.Item disabled={page==0} onClick={() => handlePageChange(page - 1)}>{"Zurück"}</Pagination.Item>
                            <Pagination.Item disabled={pageLimitReached} onClick={() => handlePageChange(page + 1)}>{"Weiter"}</Pagination.Item>
                        </Pagination>

                    </div>
                    
                    </Col>
                </Row>
            
                <Row>
                    {
                        files.map((file) => {
                            return <Col lg="3" key={file.id} className="mb-4">
                                {getFilePreview(file)     }   
                            </Col>
                        })
                    }
                </Row>

            </Container>
            
            <DeleteModal show={deleteDialogOpen} handleModalClose={ () =>changeDialogStateDelete(!deleteDialogOpen)} handleSubmit={() => {deleteThisFile()}}></DeleteModal>
        </div>
)
}

export default FileBib