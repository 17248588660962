import React, {useEffect, useState} from 'react'
import { useSelector } from 'react-redux';
import {
  useFirestoreConnect,
  useFirestore,
  isLoaded,
  useFirebase,
  isEmpty
} from 'react-redux-firebase'
import  Content from '../../layout/Content';
import { MdArrowBack,MdAddCircleOutline,MdMoreHoriz,MdModeEdit,MdDeleteForever,MdPerson } from 'react-icons/md';
import { AiOutlineClockCircle } from 'react-icons/ai';
import {CardColumns, Dropdown} from 'react-bootstrap';
import DeleteModal from '../../helpers/DeleteModal'
import {Row,Col,Table} from 'react-bootstrap'
import Moment from 'react-moment';
import SubjectEdit from './SubjectEdit'
import TopicNew from '../topics/TopicNew'
import TopicList from '../topics/TopicList';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

const useSubjectData = (subjectId,history) => {
    const firestore = useFirestore()
    const profile = useSelector(state => state.firebase.profile)
    const auth = useSelector(state => state.firebase.auth)
    const [subjectUsers,setSubjectUsers] = useState([])
  const mySubject = 'mySubject'+subjectId
  useFirestoreConnect([
    {
      collection: 'subjects',doc:subjectId,storeAs: mySubject
    }
  ])

  useFirestoreConnect([
    {
      collection: 'icons'
    }
  ])

  useEffect(() => {
    async function getUserBooks(){
      var bookUsers = [];
      await firestore.collectionGroup('userBooks').where('bookId', '==', subjectId).get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          var bookUser = doc.data();
          bookUser.id = doc.ref.parent.parent.id;
          if(bookUser.id !== auth.uid){
            bookUsers.push(bookUser)
          }
         
        });
      });
      return bookUsers;
    }
    getUserBooks().then((result) => {
      var dbPromises = [];
      for (var index = 0; index < result.length; index++) {
        dbPromises.push(firestore.collection("users").doc(result[index].id).get())
      }
      Promise.all(dbPromises).then((querySnapshots) => {
        return querySnapshots.filter(qs => qs.data() != undefined).map(qs => {
          return{
            ...qs.data(),id:qs.id
          }
        }
      )          
      }).then((users) => {
        
        let subUsers = users.map((user) => {
          return{
            ...user,
            added:result.filter(result => result.id === user.id)[0].added
          }
        })
        subUsers.sort(function(a,b){
          return new Date(b.added.seconds) - new Date(a.added.seconds);
        });
        setSubjectUsers(subUsers)
      });
    })
    
  },[]);


  const icons = useSelector(state => state.firestore.ordered.icons)
  const subjectData = useSelector(state => state.firestore.ordered[mySubject])

  const [newDialogOpen, changeDialogState] = useState(false)
  const [editDialogOpen, changeDialogStateEdit] = useState(false)
  const [deleteDialogOpen, changeDialogStateDelete] = useState(false)

  function deleteSubject() {
    recursiveDelete({path:'subject/'+subjectId,project:process.env.REACT_APP_FIREBASE_PROJECT_ID}).then(() => {
      history.push('/');
    });
  }

  function editSubject(instance){
    return firestore.update(
      'subjects/'+subjectId,{
        ...instance
      }).then(() => {
        changeDialogStateEdit(false)
      })
      .catch(err => {
        console.error('Error:', err) // eslint-disable-line no-console
        //showError(err.message || 'Could not add project')
        return Promise.reject(err)
      })
  }

  function addTopic(newInstance) {
    return firestore
      .add('subjects/'+subjectId+"/topics", {
        ...newInstance,
        subjectId:subjectId,
      })
      .then(() => {
        firestore.update('subjects/'+subjectId, {
          chapterCount:firebase.firestore.FieldValue.increment(1)
        });
        changeDialogState(false)
        //showSuccess('Project added successfully')
      })
      .catch(err => {
        console.error('Error:', err) // eslint-disable-line no-console
        //showError(err.message || 'Could not add project')
        return Promise.reject(err)
      })
  }

  const firebase = useFirebase()
  const app = firebase.app();
  const functions = app.functions("europe-west3");
  const recursiveDelete = functions.httpsCallable('recursiveDelete');

  return {subjectUsers,subjectData,editSubject,deleteSubject,addTopic,newDialogOpen,changeDialogState,editDialogOpen,changeDialogStateEdit,deleteDialogOpen,changeDialogStateDelete,auth,profile,icons}
}

const Subject = ({match,history}) => {
  const {subjectUsers,subjectData,editSubject,deleteSubject,addTopic,newDialogOpen,changeDialogState,editDialogOpen,changeDialogStateEdit,deleteDialogOpen,changeDialogStateDelete,icons,profile}
  = useSubjectData(match.params.subject,history)

  if(!isLoaded(subjectData)){
    return <p>Einen Moment...</p>
  }
  if(isEmpty(subjectData)){
    return <Redirect to={{pathname: "/"}}></Redirect>
  }
  if((profile.role !== "admin") && subjectData[0].publisherId !== profile.publisherId){
    return <Redirect to={{pathname: "/"}}></Redirect>
  }
  else{
    const subject = subjectData[0];
    return(
      <div style={{height:"calc(100vh - 121px)"}}>
        <Content 
        leftHeadline={
          <div className="d-flex align-items-center justify-content-between">
            <Link to={`/subjects`} className="d-flex align-items-center"><MdArrowBack /><span className="ml-2">Fach Details</span></Link>
             <Dropdown alignRight={true}>
              <Dropdown.Toggle className="icon-toggle" variant="light" id="dropdown-basic">
                <MdMoreHoriz />
              </Dropdown.Toggle>
              <Dropdown.Menu className="border-0 shadow">
                <Dropdown.Item  onClick={() => changeDialogStateEdit(true)} className="d-flex align-items-center text-button"><MdModeEdit /><span className="ml-2">Bearbeiten</span></Dropdown.Item>
                <Dropdown.Item onClick={() => changeDialogStateDelete(true)} className="d-flex align-items-center text-button"><MdDeleteForever /><span className="ml-2">Löschen</span></Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown> 
          </div>
        }
        rightHeadline={
          <span className="d-flex align-items-center justify-content-between text-button text-primary" onClick={() => changeDialogState(true)}><span>Themen</span><MdAddCircleOutline size="1.5em" /></span>
        }
        left={
          <div>
            <div className="bg-white p-4 shadow-border">
              <h1>{subject.title}</h1>
              
              <div className="d-flex mt-4 flex-wrap">
                <div className="mr-5">
                  <p className="text-muted">Klasse</p>
                  <div className={ `text-center d-flex align-items-center justify-content-center rounded small font-weight-bold` } style={{height:35}}>{subject.className}</div>
                </div>
                <div className="mr-5">
                  <p className="text-muted">Erstellt von</p>
                  <div className="d-flex align-items-center font-weight-medium small font-weight-bold" style={{height:35}}> <span className="bg-secondary text-white rounded-circle d-flex align-items-center justify-content-center mr-2" style={{width:23,height:23,overflow:'hidden',flexShrink:0}}><MdPerson size="3em" /></span> {subject.creator.name}</div>
                </div>
                <div className="mr-4">
                  <p className="text-muted">Erstellt am</p>
                  <div className="d-flex align-items-center font-weight-medium small font-weight-bold" style={{height:35}}> <span className="bg-secondary text-white rounded-circle d-flex align-items-center justify-content-center mr-2" style={{width:23,height:23,overflow:'hidden',flexShrink:0}}><AiOutlineClockCircle size="3em" /></span> <Moment format="LL" date={subject.created} /></div>
            
                </div>
                <div className="mr-4">
                  <p className="text-muted">Zugriffscode (App)</p>
                  <div className="d-flex align-items-center justify-content-center font-weight-medium small font-weight-bold" style={{height:35}}>  {subject.joinId}</div>
            
                </div>
              </div>
            </div>
            <Row>

            <Col md="12" lg="12">
              <div className="bg-white p-4 shadow-border mt-4">
                <h5>Beschreibung</h5>
                <p>{subject.description}</p>
              </div>
              
              </Col>

              <Col md="12" lg="12">
              <div className="bg-white p-4 shadow-border mt-4">
                <h5 className="mb-3">Lernende</h5>
                <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Beigetreten am</th>
                  </tr>
                </thead>
                <tbody>
                {
                  subjectUsers.map((user) => {
                    return(
                      <tr  key={user.id}>
                        <td> {user.displayName}</td>
                        <td>{user.added && <Moment format="LL" date={user.added.seconds * 1000} />}</td>
                      </tr>
                    )
                  })
                }
                </tbody>
              </Table>
                
              </div>
              
              </Col>
              
            </Row>
          </div>
        } 
        right={
            <TopicList subject={subject} />
        }
        
        />
        <SubjectEdit show={editDialogOpen} handleModalClose={() => changeDialogStateEdit(false)} handleSubmit={editSubject} subject={subject} icons={icons}></SubjectEdit>
        <DeleteModal show={deleteDialogOpen} handleModalClose={() => changeDialogStateDelete(false)} handleSubmit={deleteSubject}></DeleteModal>
        <TopicNew show={newDialogOpen} handleModalClose={() => changeDialogState(false)} handleSubmit={addTopic}></TopicNew>
        </div>
    )
  }
}

export default Subject