import React from 'react'
import {Form,Button, Modal} from 'react-bootstrap'
import { useSelector } from 'react-redux';

const ChapterForm = ({handleSubmit,chapter}) => {

  const auth = useSelector(state => state.firebase.auth)
  const profile = useSelector(state => state.firebase.profile)

  const onSubmit = event => {
    event.preventDefault()
    const {title,number} = event.target.elements
      let values = {
        title: title.value,
        number: parseInt(number.value),
      }
      if(!chapter){
        values = {
          title: title.value,
          number: parseInt(number.value),
          created:Date.now(),
          creator:{id:auth.uid,name:profile.displayName}
        }
      }
      handleSubmit(values);
  }
  return (
    <Form onSubmit={onSubmit}>
     <Modal.Body>
      <Form.Group>
        <Form.Label>Titel</Form.Label>
        <Form.Control type="text" name="title"  defaultValue={chapter ? chapter.title : ""} required placeholder="Kapitelüberschrift"/>
      </Form.Group>

      <Form.Group>
        <Form.Label>Nummer</Form.Label>
        <Form.Control type="number" name="number"  defaultValue={chapter ? chapter.number : ""} required placeholder="Fortlaufende Nummer des Kapitels"/>
      </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" type="submit">Speichern</Button>
      </Modal.Footer>
    </Form>
  )
}

export default ChapterForm
