// this Layout component wraps every page with the app header on top
// check out App.js to see how it's used

import React from 'react';
import { Redirect } from "react-router-dom";
import { useSelector } from 'react-redux'
import { useFirebase } from 'react-redux-firebase'
import { isEmpty,useFirestoreConnect,isLoaded,useFirestore } from 'react-redux-firebase'
import LoadingPage from '../../components/LoadingPage';
import {Form,Button} from 'react-bootstrap'
import customer from 'theme/customer';


const Registration = ({ match }) => {
    const firestore = useFirestore()
    useFirestoreConnect([
        {
          collection: 'inactive_users',doc:match.params.user
        }
      ])
    const userData = useSelector(state => state.firestore.ordered.inactive_users)

    const firebase = useFirebase()
    const onSubmit = event => {
        event.preventDefault();
        const {email,password,password_confirm,name} = event.target.elements
        const role = userData[0].role
        const publisherName = userData[0].publisherName
        const publisherId = userData[0].publisherId
        if(password.value === password_confirm.value ){
            return firebase.createUser(
                { email:email.value, password:password.value },
                { displayName:name.value, email:email.value,role,publisherName,publisherId,isSuperAdmin:false }
            )
            .then((user) => {
                firebase.auth().currentUser.sendEmailVerification().then(function() {
                }).catch(function(error) {
                    console.log(error)
                });
                firestore.delete({ collection: 'inactive_users', doc: match.params.user })
            })
            .catch(err => console.log(err.message))
        }
    }
    
    if(isLoaded(userData)){
        if(isEmpty(userData)){
            return <Redirect to={{pathname: "/"}}></Redirect>
        }else{
            return(
               <div className="d-flex align-items-center justify-content-center flex-column" style={{height:"100vh"}}>
                   <div className="d-flex align-items-center justify-content-center text-primary mb-5">
                        <h2 className="mb-0 font-weight-normal">{customer.name} <b>LMS</b></h2>
                    </div>
                   <div style={{minWidth:"50%"}} className="bg-white p-4 shadow-border">
                    <h3 className="text-center">Bitte vervollständigen Sie Ihre Daten!</h3>
                    <Form onSubmit={onSubmit} className="mt-5">
                            <Form.Group>
                                <Form.Label>E-Mail Adresse</Form.Label>
                                <Form.Control type="text" name="email"  defaultValue={userData[0].email} required placeholder="E-Mail" readOnly />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Ihr Name</Form.Label>
                                <Form.Control type="text" name="name"  required placeholder="Ihr Name" />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Ihr Passwort</Form.Label>
                                <Form.Control type="password" name="password" minLength={6}  required placeholder="Ihr Passwort" />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Passwort Bestätigen</Form.Label>
                                <Form.Control type="password" name="password_confirm" minLength={6}  required placeholder="Bitte geben Sie ihr Passwort erneut ein" />
                            </Form.Group>
                                <Form.Group controlId="formBasicCheckbox">
                                <Form.Check  type="checkbox" required>
                                    <Form.Check.Input type="checkbox"  required/>
                                    <Form.Check.Label>Ich habe die <a target="_blank" href="/datenschutz">Datenschutzerklärung</a> und <a target="_blank" href="/agb">AGB</a> gelesen und stimme diesen zu.</Form.Check.Label>
                                </Form.Check>
                            </Form.Group>
                           
                            <Button className="w-100 p-3 mt-3" variant="primary" type="submit">
                                Registrierung abschließen
                            </Button>
                        </Form>
                    </div>
               </div>
            )
        }
    }else{
        return <LoadingPage />
    }
 }


export default Registration;