import React from 'react';
import {Form} from 'react-bootstrap'
import {AiFillCaretRight} from 'react-icons/ai'

const MemoryTitleForm = (props) => {
    const update = (e) => {
        props.update(e.target.name, e.target.value);
    };
    return(
        <div className="modal-content-container">
            <Form>
                <Form.Group>
                    <Form.Label>Titel</Form.Label>
                    <Form.Control type="text" name="title"  defaultValue={props.quiz.title ? props.quiz.title :  ""} onChange={update} required placeholder="Titel des Pairing Games"/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Beschreibung</Form.Label>
                    <Form.Control type="text" name="text" defaultValue={props.quiz.text ? props.quiz.text :  ""}  onChange={update} required placeholder="Beschreibung des Pairing Games"/>
                </Form.Group>
                {/* <Form.Group>
                    <Form.Label>Art des Pairing Games</Form.Label>
                    <Form.Control as="select" name="type" >
                    <option value="text">Text</option>
                    <option value="image">Bilder</option>
                    </Form.Control>
                </Form.Group> */}
            
            </Form>
        <div className="d-flex align-items-center justify-content-end pt-3" >
                <span className="text-button text-primary d-flex align-items-center" onClick={() => {props.goToStep(2)}}> Zu den Fragen <AiFillCaretRight /></span>
            </div>

        </div>
    )
}

export default MemoryTitleForm;