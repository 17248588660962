import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import { MdChevronRight} from 'react-icons/md';
import TaskNew from './TaskNew';
import TaskEdit from './TaskEdit';
import DeleteModal from '../helpers/DeleteModal';
import { useSelector } from 'react-redux';
import {
  useFirestore,
  useFirestoreConnect,
  isLoaded,
} from 'react-redux-firebase'

const useTaskList = (bookId,chapterId) => {
  const firestore = useFirestore()
  const myTasks = 'myTasks'+chapterId
  useFirestoreConnect([
    {
      collection: `books`,
      doc: bookId,
      subcollections: [{ 
        collection: "chapters",doc: chapterId,
        subcollections:[{collection:"tasks",orderBy:['page'],}] 
      }],
      storeAs: myTasks
    }
  ])

  const tasks = useSelector(state => state.firestore.ordered[myTasks])

  const [newDialogOpen, changeDialogState] = useState(false)
  const toggleDialog = () => {
    changeDialogState(!newDialogOpen)
  }

  const [editDialogOpen, changeDialogStateEdit] = useState(false)
  const toggleDialogEdit = () => {
    changeDialogStateEdit(!editDialogOpen)
  }

  const [deleteDialogOpen, changeDialogStateDelete] = useState(false)
  const toggleDialogDelete = () => {
    changeDialogStateDelete(!deleteDialogOpen)
  }

  const [selectedTask, changeSelectedTask] = useState(false)


  function addTask(newInstance) {
    return firestore
      .add('books/'+bookId+"/chapters/"+chapterId+"/tasks", {
        ...newInstance,
      })
      .then(() => {
        toggleDialog()
        //showSuccess('Project added successfully')
      })
      .catch(err => {
        console.error('Error:', err) // eslint-disable-line no-console
        //showError(err.message || 'Could not add project')
        return Promise.reject(err)
      })
  }

  function editTask(instance){
    return firestore.update(
      'books/'+bookId+"/chapters/"+chapterId+"/tasks/"+selectedTask.id,{
        ...instance
      }).then(() => {
        toggleDialogEdit()
        changeSelectedTask(null)
      })
      .catch(err => {
        console.error('Error:', err) // eslint-disable-line no-console
        //showError(err.message || 'Could not add project')
        return Promise.reject(err)
      })
  }

  function deleteTask(){
    return firestore.delete(
    { collection: 'books/'+bookId+"/chapters/"+chapterId+"/tasks/", doc: selectedTask.id }
    ).then(() => {
      toggleDialogDelete()
      changeSelectedTask(null)
    })
    .catch(err => {
      console.error('Error:', err) // eslint-disable-line no-console
      //showError(err.message || 'Could not add project')
      return Promise.reject(err)
    })
  }
  return { tasks,addTask,newDialogOpen, toggleDialog,editDialogOpen,toggleDialogEdit,editTask,selectedTask,deleteDialogOpen,toggleDialogDelete,deleteTask }
}
const TaskList  = ({bookId,chapter}) => {
  const {
    tasks, addTask,newDialogOpen, toggleDialog,editDialogOpen,toggleDialogEdit,editTask,selectedTask,deleteDialogOpen,toggleDialogDelete,deleteTask
  } = useTaskList(bookId,chapter.id)
  if(isLoaded(tasks)){
  return(
    <div>
      <TaskNew show={newDialogOpen} handleModalClose={toggleDialog} handleSubmit={addTask} ></TaskNew>
      <TaskEdit show={editDialogOpen} handleModalClose={toggleDialogEdit} handleSubmit={editTask} task={selectedTask} ></TaskEdit>
      <DeleteModal show={deleteDialogOpen} handleModalClose={toggleDialogDelete} handleSubmit={deleteTask}></DeleteModal>
          <div className="list-group">
          {tasks.map(task => (
              <Link key={task.id} to={{pathname:`${chapter.id}/tasks/${task.id}`}} className="list-group-item border-0 list-group-item-action px-4 shadow-border mb-3">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column">
                    <span className="small text-muted">Seite {task.page}</span>
                    <span>{task.title}</span>
                  </div>
                  <MdChevronRight className="text-muted" size="1.5em" />
                </div>
              </Link>
            ))}
          </div>
    </div>
)
}else{
  return <p>Loading...</p>
}
}

export default TaskList
