import React,{useState} from 'react'
import {Form,Button, Modal} from 'react-bootstrap'
import { useFirebase,useFirestore } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import {selectStyles} from '../../helpers/selectColors';
import CreatableSelect from 'react-select/creatable';
import {uploadFile} from  '../../helpers/upload'

const BookForm = ({handleSubmit,book,subjects}) => {

  const firebase = useFirebase()
  const firestore = useFirestore()
  const profile = useSelector(state => state.firebase.profile)
  const auth = useSelector(state => state.firebase.auth)

  const [selectedSubject, changeSelectedSubject] = useState(book ? {label:book.subject.name,value:book.subject.id} : "")
  const selectSubject = (subject) => {
    changeSelectedSubject(subject)
  }
  const [bookCover, changeBookCover] = useState(book ? book.image : false)
  const [isLoading, setLoading] = useState(false)
 // const selectedSubject = book ? {label:book.subject,value:''} : {};

  const onSubmit = event => {
    event.preventDefault()
    const {title,description,image} = event.target.elements
    if(image){
      uploadFile(firebase,image.files[0],"book-covers/")
      .then((path)=>{
          firestore.add('files', {
            path:path,
            type:"Cover Image",
            name:image.files[0].name,
            publisherId:profile.publisherId,
          })
          const values = {
            title: title.value,
            description: description.value,
            publisher: profile.publisherName,
            publisherId:profile.publisherId,
            image:path,
            subject:{id:selectedSubject.value,name:selectedSubject.label},
            created:Date.now(),
            creator:{id:auth.uid,name:profile.displayName}
          }
          handleSubmit(values)
        }
      )
    }else{
      if(!book){
        const values = {
          title: title.value,
          description: description.value,
          publisherId:profile.publisherId,
          publisher: profile.publisherName,
          subject:{id:selectedSubject.value,name:selectedSubject.label},
          created:Date.now(),
          creator:{id:auth.uid,name:profile.displayName}
        }
        handleSubmit(values)
      }else{
        const values = {
          title: title.value,
          description: description.value,
          subject:{id:selectedSubject.value,name:selectedSubject.label},
        }
        handleSubmit(values)
      }
    }
  }
  const handleCreate = (inputValue) => {
    setLoading(true)
      firestore
      .add('subjects', {
        name:inputValue,
      })
      .then((result) => {
        subjects = [...subjects,{id:result.id,name:inputValue}]
        selectSubject({id:result.id,name:inputValue});
        setLoading(false)
        
        //showSuccess('Project added successfully')
      })
      .catch(err => {
        console.error('Error:', err) // eslint-disable-line no-console
        //showError(err.message || 'Could not add project')
        return Promise.reject(err)
      })
    };
  const subjectDefault = {label:book ? book.subject.name : null,value:book ? book.subject.id : null}

    return (
      <Form onSubmit={onSubmit}>
      <Modal.Body>
      
        <Form.Group>
          <Form.Label >Titel</Form.Label>
          <Form.Control type="text" name="title" defaultValue={book ? book.title : ""} placeholder="Titel des Buches" required />
        </Form.Group>

        <Form.Group>
        <Form.Label >Fach</Form.Label>
        <React.Fragment>
        {book &&
        <CreatableSelect
          isLoading={isLoading}
          placeholder={<span>Wähle ein Fach aus</span>}  
          defaultValue={subjectDefault}
          onChange={selectSubject}
          onCreateOption={handleCreate}
          options={subjects.map(subject => {
            return {label:subject.name,value:subject.id}
          })}
          styles={selectStyles}
        />
        }
        {!book &&
        <CreatableSelect
          isLoading={isLoading}
          placeholder={<span>Wähle ein Fach aus</span>}  
          onChange={selectSubject}
          onCreateOption={handleCreate}
          options={subjects.map(subject => {
            return {label:subject.name,value:subject.id}
          })}
          styles={selectStyles}
        />
        }
        <input
              tabIndex={-1}
              autoComplete="off"
              style={{ opacity: 0, height: 0,position:"absolute",width:"100%" }}
              required={true}
              onChange={selectSubject}
              value={selectedSubject}
            />
        </React.Fragment>
         </Form.Group>

        <Form.Group>
          <Form.Label >Beschreibung</Form.Label>
          <Form.Control as="textarea" rows="3"  name="description" placeholder="Was erwartet den Nutzer im Buch " defaultValue={book ? book.description : ""}  required />
        </Form.Group>

        { bookCover !== false ? 
        <div >
          <img style={{width:80,display:"block"}} alt="Book Cover" className="mb-3" src={book.image} />
          <span className="text-button text-primary" onClick={() => changeBookCover(false)}>Bild ändern</span>
        </div>  
        :
         <Form.Group>
            <Form.Label >Buchcover</Form.Label>
            <Form.Control type="file" name="image" accept="image/jpeg" required   />
          </Form.Group>
        }

       
      
      </Modal.Body>
      <Modal.Footer>
      <Button variant="primary" type="submit">Speichern</Button>
      </Modal.Footer>
      </Form>
    )
  }

export default BookForm
