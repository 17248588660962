import React, {useState}  from 'react'
import {Form,Button, Modal} from 'react-bootstrap'
import ImageDropzone from '../../components/ImageDropzone';
import { useSelector } from 'react-redux';
import ImagePreview from '../../components/ImagePreview';

const FeedbackForm = ({handleSubmit}) => {

    const profile = useSelector(state => state.firebase.profile)
    const auth = useSelector(state => state.firebase.auth)

    const [feedbackImage, setFeedbackImage] = useState(false)

    const onSubmit = event => {
        event.preventDefault()
        const {topic,description} = event.target.elements
        if(feedbackImage){
              saveFeedback(topic.value,description.value,feedbackImage)
        }else{
            saveFeedback(topic.value,description.value,null)
        }
    }

    const saveFeedback = (topic,description,imagepath) => {
        const values = {
            topic: topic,
            description: description,
            image:imagepath,
            created:Date.now(),
            creator:{id:auth.uid,name:profile.displayName}
        }
        handleSubmit(values)
    }

    const updateFeedbackImage = (id,path,name) => {
        setFeedbackImage(path)
    }

    return (
        <Form onSubmit={onSubmit}>
        <Modal.Body>
        
        <Form.Group>
            <Form.Label >Thema</Form.Label>
            <Form.Control type="text" name="topic" placeholder="Um was geht es?" required />
        </Form.Group>
        <Form.Group>
            <Form.Label >Beschreibung</Form.Label>
            <Form.Control type="textarea" as="textarea" rows="5" name="description" placeholder="Beschreiben Sie Ihr Feedback, den aufgetretenen Fehler oder was Sie uns sonst noch so mitteilen wollen." required />
        </Form.Group>
        <Form.Group>
            <Form.Label>Bild (optional)</Form.Label>
                {
                    feedbackImage ?
                    <div className="w-25"><ImagePreview image={feedbackImage} handleImageDelete={() => {setFeedbackImage(false)}} /></div>
                    
                    :
                    <ImageDropzone lassName="bg-white" handleUploadSuccess={updateFeedbackImage} refId={"test"} folder="feedback-images" />

                }
                
        </Form.Group>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="primary" type="submit">Speichern</Button>
        </Modal.Footer>
        </Form>
    )
}
  
  export default FeedbackForm;