import React from 'react';
import {Link} from 'react-router-dom'
import {RiFoldersLine} from 'react-icons/ri';
import {MdArrowForward} from 'react-icons/md';

const BookCard = ({image,title,publisher,id,subject,chapterCount,description,path}) => (
    <Link to={{pathname:`/${path}/${id}`}} style={{ textDecoration: 'none',color:'inherit'}}>
        <div className="bg-white position-relative shadow-border" style={{height:"100%",width:"100%"}}>
            <div className="d-flex justify-content-start align-items-start px-4 py-5 flex-column">
                <div style={{position:"absolute",bottom:0,right:0,width:40,height:40,color:"white",opacity:0.7}} className="bg-primary d-flex justify-content-center align-items-center">
                        <MdArrowForward size="1.5em" />
                </div>
                <div className="rounded shadow-sm" style={{width:85,height:85,backgroundImage:`url(${image})`,backgroundSize:'cover',backgroundPosition:'center center'}}></div>
                <div className="mt-3 text-left">
                    <h5 className="font-weight-normal">{title}</h5>
                    <span className="text-muted">{subject}</span>
                </div>
                {chapterCount &&
                <div className="d-flex align-items-center mt-2 text-muted">
                      <RiFoldersLine></RiFoldersLine> <span className="ml-2">{chapterCount ? chapterCount : 0} Kapitel</span>
                </div>
                }
            </div>
            
        </div>
    </Link>
);


export default BookCard;