import React from 'react';
import customer from 'theme/customer';

const Data = () => {
    return (
        <div className="container pt-5 pb-5">
            <div className="text-center mb-4">
                <h2>Datenschutz</h2> 
                <h5>
                Einwilligung
                </h5>
                
            </div>
            <p>
            Die personenbezogenen Daten, die für das Anlegen eines Autor:innen-Zugangs für das {customer.name} LMS
                notwendig und erforderlich sind, werden auf Grundlage gesetzlicher Berechtigungen erhoben.
            </p>
            <p>
                Hiermit willige ich ein, dass thea GbR folgende personenbezogene Daten:
            </p>
            <ul>
                <li>
                    Name
                </li>
                <li>
                    E-Mail-Adresse
                </li>
            </ul>
            <p >Für folgende Zwecke:</p>
            <ul>
                <li>
                    Einrichten eines Autor:innen-Zugangs
                </li>
                <li>
                    Zuordnung der angelegten Inhalte
                </li>
            </ul>
            <p className="font-weight-bold pt-3">Speicherdauer:</p>
            <p>Ihre personenbezogenen Daten werden gelöscht, sobald sie für die Zwecke, für die wir sie nach den vorstehenden Ziffern erhoben oder verwendet haben, nicht mehr erforderlich sind.</p>
            <p className="font-weight-bold pt-3">Weitergabe und Übergabe an Dritte:</p>
            <p>Ihre personenbezogenen Daten werden nicht weitergegeben.</p>
                




<p className="pt-5">Mir ist bewusst, dass ich diese Einwilligung jederzeit ohne Angabe von Gründen für die Zukunft widerrufen kann, indem ich postalisch unter Uhlandstraße 1, 78464 Konstanz oder per E-Mail info@thea-ar.de meinen Widerruf gegen die Verarbeitung meiner personenbezogenen Daten mitteile.
</p>
<p>
Thea GbR weist Sie darauf hin, dass Sie ein Recht auf Auskunft, Berichtigung, Löschung, Einschränkung der Verarbeitung, Datenübertragbarkeit haben (Art. 15-21 DS-GVO), sowie auf Beschwerde bei einer Aufsichtsbehörde (Art. 77 DS-GVO). Der Datenschutzbeauftrage der thea GbR ist erreichbar unter info@thea-ar.de.
    </p>          
        </div>
    );
};

export default Data;