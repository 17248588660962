import React from 'react'
import {Form,Button,Modal} from 'react-bootstrap';
import { useSelector } from 'react-redux';

const TaskForm = ({handleSubmit,task}) => {
  const auth = useSelector(state => state.firebase.auth)
  const profile = useSelector(state => state.firebase.profile)
  const onSubmit = event => {
    event.preventDefault()
    const {title,description,number} = event.target.elements
      let values = {
        title: title.value,
        description:description.value,
        number:parseInt(number.value),
        page:parseInt(number.value)
      }
      if(!task){
        values = {
          title: title.value,
          number:parseInt(number.value),
          page:parseInt(number.value),
          description:description.value,
          created:Date.now(),
          creator:{id:auth.uid,name:profile.displayName},
          pageCount:0,
          publisherId:profile.publisherId
        }
      }
      handleSubmit(values);
  }
  return (
    <Form onSubmit={onSubmit}>
      <Modal.Body>
      <Form.Group>
        <Form.Label >Titel</Form.Label>
        <Form.Control type="text" name="title" defaultValue={task ? task.title : ""}  required placeholder="Titel der Aufgabe" />
      </Form.Group>

      <Form.Group>
          <Form.Label >Nummer</Form.Label>
          <Form.Control type="number" name="number" defaultValue={task ? task.number : ""} placeholder="Nummer der Aufgabe (dient der Sortierung)" />
        </Form.Group>


      <Form.Group>
        <Form.Label >Beschreibung</Form.Label>
        <Form.Control type="text" name="description" defaultValue={task ? task.description : ""}  required placeholder="Was erwartet die Schüler:innen?" />
      </Form.Group>

      </Modal.Body>
      <Modal.Footer>
      <Button variant="primary" type="submit">Speichern</Button>
      </Modal.Footer>
    </Form>
  )
}

export default TaskForm
