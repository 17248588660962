import React from "react";
import {Row,Col,Container} from 'react-bootstrap'

const Content = ({leftHeadline,rightHeadline,left,right}) => (
  <div style={{width:"100%",height:"100%"}}>
    <Container fluid style={{height: "100%"}}>
        <Row >
            <Col md="12" lg="8" >
            <div>
                    {leftHeadline}
                </div>
                <div className="mt-3">
                    {left}
                </div>
            </Col>
            <Col md="12"  lg="4" className="mt-lg-0 mt-4">
                <div>
                    {rightHeadline}
                </div>
                <div  className="mt-3">
                    {right}
                </div>
            </Col>
        </Row>
    </Container>
  </div>
);
export default Content;