import React, { useState } from 'react';
import { useFirebase } from 'react-redux-firebase'
import LoadingPage from '../../components/LoadingPage';
import { Redirect } from 'react-router-dom';
import customer from 'theme/customer';
import {Form,Button} from 'react-bootstrap'


const PasswordReset = ({actionCode, continueUrl, lang}) => {
    const firebase      = useFirebase()
    const auth          = firebase.auth();
    const [mailVerified, changeVerifiedStatus] = useState(false)
    const [userMail, changeUserMail] = useState(false)

    if(!mailVerified){
        auth.verifyPasswordResetCode(actionCode).then(function(email) {
            changeUserMail(email);
            changeVerifiedStatus("verified")
        }).catch(function(error) {
            changeVerifiedStatus("error")
        });
    }

    const onSubmit = (event) => {
        event.preventDefault();
        const {password} = event.target.elements;
        const newPassword = password.value;
        auth.confirmPasswordReset(actionCode, newPassword).then(function(resp) {
            firebase.login({
                email:userMail,password:newPassword
            })
            changeVerifiedStatus("redirect")
          }).catch(function(error) {
            
          });
    } 
   
    if(mailVerified === "verified"){
        return(
            <div className="d-flex align-items-center justify-content-center flex-column" style={{height:"100vh"}}>
                <div className="d-flex align-items-center justify-content-center text-primary mb-5">
                    <h2 className="mb-0 font-weight-normal">{customer.name} <b>LMS</b></h2>
                </div>
                <div style={{minWidth:"50%"}} className="bg-white p-4 shadow-border">
                <h3 className="text-center">Neues Passwort</h3>
                <Form onSubmit={onSubmit} className="mt-5">
                        <Form.Group>
                            <Form.Label>Ihre E-Mail Adresse</Form.Label>
                            <Form.Control type="text" name="email"  required placeholder="E-Mail" defaultValue={userMail} readOnly />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Ihr Neues Passwort</Form.Label>
                            <Form.Control type="password" name="password" minLength={6}  required placeholder="Ihr Passwort" />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Passwort Bestätigen</Form.Label>
                            <Form.Control type="password" name="password_confirm" minLength={6}  required placeholder="Bitte geben Sie ihr Passwort erneut ein" />
                        </Form.Group>
                        <Button className="w-100 p-3 mt-3" variant="primary" type="submit">
                            Passwort ändern
                        </Button>
                    </Form>
                </div>
            </div>
        )
    }else if(mailVerified === "redirect"){
        return(
            <Redirect to="/" />
        )
    }else if(mailVerified === "error"){
        return(
            <Redirect to="/" />
        )
    }
    else{
        return(
            <LoadingPage />
        )
    }
 }


export default PasswordReset;