import React from "react";
import { Provider } from "react-redux";
import App from "./App";
import configureStore from "./configureStore";
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import { createFirestoreInstance } from 'redux-firestore' 
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/storage";
import "firebase/firestore";
import 'firebase/functions';
import 'moment/locale/de';
import Moment from 'react-moment';

Moment.globalLocale = 'de';

const firebaseConfig = { 
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
}

const rrfConfig = {
    userProfile: 'users',
    useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
}

firebase.initializeApp(firebaseConfig)
firebase.app().functions('europe-west3');
//firebase.functions().useFunctionsEmulator("http://localhost:5001");

const store = configureStore();

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
}


const Root = () => {
    return (
      <Provider store={store}>
        <ReactReduxFirebaseProvider {...rrfProps}>
            <App />
        </ReactReduxFirebaseProvider>
      </Provider>
    );
  }
  export default Root;