import React,{useState} from 'react';
import {Modal} from 'react-bootstrap'
import {
    useFirestore,
  } from 'react-redux-firebase'
import { MdViewHeadline,MdPictureAsPdf,MdFullscreen,MdFullscreenExit } from 'react-icons/md';
import { IoMdArrowDropright } from 'react-icons/io';
import { AiOutlineQuestion,AiFillAudio } from 'react-icons/ai';
import { BsImage,BsFillCameraVideoFill,BsPuzzle } from 'react-icons/bs';
import { GiBrain } from 'react-icons/gi';
import QuizContent from '../content/QuizContent';
import TextblockContent from '../content/TextblockContent';
import ImageContent from '../content/ImageContent';
import VideoContent from '../content/VideoContent';
import PDFContent from '../content/PDFContent';
import MemoryContent from '../content/MemoryContent';
import BlanksContent from '../content/BlanksContent';
import AudioContent from '../content/AudioContent';
import ConnectContent from '../content/ConnectContent';


const TaskContentAdd = ({show,handleModalClose,contentPath,orderArray,taskId,taskCount}) => {
    const firestore = useFirestore()
    const addContent = (values) => {
        firestore.add(contentPath+"/contents", {
                ...values,
                updated:Date.now()
            }
        ).then((data) => {
            if(orderArray){
               orderArray = [...orderArray,data.id]
            }else{
                orderArray = [data.id]
            }
            if(!taskCount){
                taskCount = 0
            }
            if(values.quizId){
                taskCount += 1
            }
            firestore.update(contentPath, {
                    contentOrder:orderArray,taskCount
            }).then(() => {
                selectType(false);
                handleModalClose(false);
            })
        })
        .catch(err => {
            console.error('Error:', err) // eslint-disable-line no-console
        })
    }
    const [selectedType, changeSelectedType] = useState(false)
    const [isFullscreen, setFullscreen] = useState(false)
    const selectType = (type) => {
        changeSelectedType(type)
    }

    return(
        <Modal show={show} backdrop="static" onHide={() => {selectType(false);handleModalClose(false);}} className={isFullscreen ? "modal-fullscreen" : ""}>
            <Modal.Header closeButton>
                <div className="d-flex align-items-center justify-content-between w-100">
                    <Modal.Title>Inhalt hinzufügen</Modal.Title>
                    {isFullscreen && <MdFullscreenExit className="fullscreen-icon" size="1.6em" onClick={() => setFullscreen(!isFullscreen)}/>}
                    {!isFullscreen && <MdFullscreen className="fullscreen-icon" size="1.6em" onClick={() => setFullscreen(!isFullscreen)}/>}
                </div>
            </Modal.Header>
            <div className="d-flex px-4 py-3" style={{backgroundColor:"rgb(226, 226, 226)"}}>
                <div className="d-flex align-items-center mr-2">
                        <span onClick={() => changeSelectedType(false)} className="text-uppercase text-primary text-small text-button">Inhalte</span>
                    </div>
                    {
                        selectedType ? 
                        <div className="d-flex align-items-center text-primary">
                        <IoMdArrowDropright />
                        <span className="ml-2 text-uppercase  text-small">{selectedType}</span>
                        </div>
                        :
                        <span></span>
                    }
                </div>
            <Modal.Body className="bg-light p-0">
                {!selectedType ? 
                <div className="p-3">
                    <h5 className="m-2">Ergänzende Medien</h5>
                    <div className="d-flex flex-wrap">
                        <div onClick={() =>selectType("Textblock")} className="m-2 p-4 px-5 d-flex flex-column align-items-center bg-white border border-gray-900 rounded text-button" style={{minWidth:200}}>
                            <div className="p-3 rounded-circle bg-primary text-white d-flex align-items-center justify-content-center" style={{width: "3em",height: "3em"}}>
                                <MdViewHeadline size="1.2em" />
                            </div>
                            <span className="mt-2">Textblock</span>
                        </div>
                        <div onClick={() =>selectType("Image")} className="m-2 p-4 px-5 d-flex flex-column align-items-center bg-white border border-gray-900 rounded text-button" style={{minWidth:200}}>
                            <div className="p-3 rounded-circle bg-primary text-white d-flex align-items-center justify-content-center" style={{width: "3em",height: "3em"}}>
                                <BsImage size="1.2em" />
                            </div>
                            <span className="mt-2">Bild</span>
                        </div>
                        <div onClick={() =>selectType("Video")} className="m-2 p-4 px-5 d-flex flex-column align-items-center bg-white border border-gray-900 rounded text-button" style={{minWidth:200}}>
                            <div className="p-3 rounded-circle bg-primary text-white d-flex align-items-center justify-content-center" style={{width: "3em",height: "3em"}}>
                                <BsFillCameraVideoFill size="1.2em" />
                            </div>
                            <span className="mt-2">Video</span>
                        </div>
                        <div onClick={() =>selectType("Audio")} className="m-2 p-4 px-5 d-flex flex-column align-items-center bg-white border border-gray-900 rounded text-button" style={{minWidth:200}}>
                            <div className="p-3 rounded-circle bg-primary text-white d-flex align-items-center justify-content-center" style={{width: "3em",height: "3em"}}>
                                <AiFillAudio size="1.2em" />
                            </div>
                            <span className="mt-2">Audio</span>
                        </div>
                        <div onClick={() =>selectType("Pdf")} className="m-2 p-4 px-5 d-flex flex-column align-items-center bg-white border border-gray-900 rounded text-button" style={{minWidth:200}}>
                            <div className="p-3 rounded-circle bg-primary text-white d-flex align-items-center justify-content-center" style={{width: "3em",height: "3em"}}>
                                <MdPictureAsPdf size="1.2em" />
                            </div>
                            <span className="mt-2">Pdf</span>
                        </div>
                    </div>
                    <h5 className="m-2">Gamification</h5>
                    <div className="d-flex flex-wrap">
                        <div onClick={() =>selectType("Quiz")} className="m-2 p-4 px-5 d-flex flex-column align-items-center bg-white border border-gray-900 rounded text-button" style={{minWidth:200}}>
                            <div className="p-3 rounded-circle bg-primary text-white d-flex align-items-center justify-content-center" style={{width: "3em",height: "3em"}}>
                                <AiOutlineQuestion size="1.2em" />
                            </div>
                            <span className="mt-2">Quiz</span>
                        </div>
                        <div onClick={() =>selectType("Memory")} className="m-2 p-4 px-5 d-flex flex-column align-items-center bg-white border border-gray-900 rounded text-button" style={{minWidth:200}}>
                            <div className="p-3 rounded-circle bg-primary text-white d-flex align-items-center justify-content-center" style={{width: "3em",height: "3em"}}>
                                <GiBrain size="1.2em" />
                            </div>
                            <span className="mt-2">Memory</span>
                        </div>
                        <div onClick={() =>selectType("Lückentext")} className="m-2 p-4 px-5 d-flex flex-column align-items-center bg-white border border-gray-900 rounded text-button" style={{minWidth:200}}>
                            <div className="p-3 rounded-circle bg-primary text-white d-flex align-items-center justify-content-center" style={{width: "3em",height: "3em"}}>
                                <BsPuzzle size="1.2em" />
                            </div>
                            <span className="mt-2">Lückentext</span>
                        </div>
                    </div>
                    <h6 className="m-2 mt-4 d-flex align-items-center text-primary text-button" onClick={() =>selectType("Verknüpfen")}>Bestehende Inhalte verwenden <IoMdArrowDropright /></h6>
                </div>
                :
                <div style={{height:"100%"}}>
                {selectedType === "Textblock" && <TextblockContent handleSubmit={addContent} />}
                {selectedType === "Image" && <ImageContent handleSubmit={addContent} taskId={taskId} />}
                {selectedType === "Quiz" && <QuizContent handleSubmit={addContent}  />}
                {selectedType === "Video" && <VideoContent handleSubmit={addContent} taskId={taskId}  />}
                {selectedType === "Memory" && <MemoryContent handleSubmit={addContent} />}
                {selectedType === "Lückentext" && <BlanksContent handleSubmit={addContent} />}
                {selectedType === "Pdf" && <PDFContent handleSubmit={addContent} taskId={taskId} />}
                {selectedType === "Audio" && <AudioContent handleSubmit={addContent} taskId={taskId} />}
                {selectedType === "Verknüpfen" && <ConnectContent handleSubmit={addContent} taskId={taskId} /> }
                    
                </div>
                }
            </Modal.Body>
        </Modal>
    )

}
export default TaskContentAdd;