import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import { MdChevronRight } from 'react-icons/md';
import ChapterNew from './ChapterNew'
import ChapterEdit from './ChapterEdit';
import DeleteModal from '../helpers/DeleteModal';
import { useSelector } from 'react-redux';
import {
  useFirestore,
  useFirestoreConnect,
  isLoaded,
} from 'react-redux-firebase'

const useChapterList = (bookId) => {
  const firestore = useFirestore()
  const myChapters = 'myChapters'+bookId
  useFirestoreConnect([
    {
      collection: `books`,
      doc: bookId,
      subcollections: [{ collection: "chapters",orderBy: ['number'], }],
      storeAs: myChapters
    }
  ])
  
  // Get chapters from redux state
  const chapters = useSelector(state => state.firestore.ordered[myChapters])

  const [newDialogOpen, changeDialogState] = useState(false)
  const toggleDialog = () => {
    changeDialogState(!newDialogOpen)
  }

  const [editDialogOpen, changeDialogStateEdit] = useState(false)
  const toggleDialogEdit = () => {
    changeDialogStateEdit(!editDialogOpen)
  }

  const [deleteDialogOpen, changeDialogStateDelete] = useState(false)
  const toggleDialogDelete = () => {
    changeDialogStateDelete(!deleteDialogOpen)
  }

  const [selectedChapter, changeSelectedChapter] = useState(false)
  const selectChapter = (chapter) => {
    changeSelectedChapter(chapter)
  }

  function addChapter(newInstance) {
    return firestore
      .add('books/'+bookId+"/chapters", {
        ...newInstance,
      })
      .then(() => {
        toggleDialog()
        //showSuccess('Project added successfully')
      })
      .catch(err => {
        console.error('Error:', err) // eslint-disable-line no-console
        //showError(err.message || 'Could not add project')
        return Promise.reject(err)
      })
  }

  function editChapter(instance){
    return firestore.update(
      'books/'+bookId+"/chapters/"+selectedChapter.id,{
        ...instance
      }).then(() => {
        toggleDialogEdit()
        changeSelectedChapter(null)
      })
      .catch(err => {
        console.error('Error:', err) // eslint-disable-line no-console
        //showError(err.message || 'Could not add project')
        return Promise.reject(err)
      })
  }

  function deleteChapter(){
    return firestore.delete(
    { collection: 'books/'+bookId+"/chapters/", doc: selectedChapter.id }
    ).then(() => {
      toggleDialogDelete()
      changeSelectedChapter(null)
    })
    .catch(err => {
      console.error('Error:', err) // eslint-disable-line no-console
      //showError(err.message || 'Could not add project')
      return Promise.reject(err)
    })
  }
  return { chapters,addChapter,toggleDialog,newDialogOpen,editDialogOpen,toggleDialogEdit,editChapter,selectedChapter,selectChapter,deleteDialogOpen,toggleDialogDelete,deleteChapter}
}
const ChapterList  = ({book}) => {
  const {
    chapters,addChapter,toggleDialog,newDialogOpen,editDialogOpen,toggleDialogEdit,editChapter,selectedChapter,deleteDialogOpen,toggleDialogDelete,deleteChapter
  } = useChapterList(book.id)
    if(isLoaded(chapters)){
      return(
          <div >
            <ChapterNew show={newDialogOpen} handleModalClose={toggleDialog} handleSubmit={addChapter}></ChapterNew>
            <ChapterEdit show={editDialogOpen} handleModalClose={toggleDialogEdit} handleSubmit={editChapter} chapter={selectedChapter}></ChapterEdit>
            <DeleteModal show={deleteDialogOpen} handleModalClose={toggleDialogDelete} handleSubmit={deleteChapter}></DeleteModal>
                <div className="list-group">
                  {chapters.map(chapter => (
                    <Link key={chapter.id} to={{pathname:book.id+`/chapters/${chapter.id}`}} className="list-group-item border-0 list-group-item-action px-4 shadow-border mb-3">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex flex-column">
                          <span className="small text-muted">Kapitel {chapter.number}</span>
                          <span>{chapter.title}</span>
                        </div>
                        <MdChevronRight className="text-muted" size="1.5em" />
                      </div>
                    </Link>
                  ))}
                </div>
          </div>
      )
  }else{
    return <p>Loading...</p>
  }
}

export default ChapterList