import React,{useState} from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from 'react-redux'
import { isLoaded, isEmpty,useFirebase } from 'react-redux-firebase'
import Sidebar from "../views/layout/Sidebar";
import Navbar from "../views/layout/Navbar";
import {Container} from 'react-bootstrap';
import LoadingPage from '../components/LoadingPage';
import {routes} from '../helpers/routeRoles'

function ProtectedRoute({ component: Component, path,...rest }) {
  const firebase = useFirebase()
  const auth = useSelector(state => state.firebase.auth)
  const profile = useSelector(state => state.firebase.profile)
  const [showSidebar,setShowSidebar] = useState(false)
  if(isLoaded(auth)){
    if(!isEmpty(auth)){
      if(!auth.emailVerified){
        return (
          <Redirect
              to={{
                pathname: "/inactive"
              }}
            />
        )
      }
      
      if(isLoaded(profile)){
        if(profile.role === "user"){
          firebase.logout();
          return <Redirect
          to={{
            pathname: "/error"
          }}
        />

        }
        let filteredRoutes = routes.filter(route => path.includes(route.link))
        let userAllowed = false
        for(var route in filteredRoutes){
          if(filteredRoutes[route].roles.includes(profile.role)){
            userAllowed = true
          }
        }
        if(!userAllowed && path !== "/"){
          return <Redirect
            to={{
              pathname: "/"
            }}
          />
        }
        return (
          <Route
            {...rest}
            render={( props ) => 
            <div className="wrapper">
                <Sidebar  {...props} showSidebar={showSidebar} setShowSidebar={setShowSidebar}/>
                <main className="float-right main-container" >
                  <Navbar {...props} setShowSidebar={setShowSidebar}/>
                    <Container fluid className="content bg-light" style={{padding:30}}>
                      <Component {...props} />
                    </Container>
                </main>
              </div>
            }
          />
        )}else{
          return(
            <LoadingPage />
          )
        }
    }else{
      return (
        <Redirect
            to={{
              pathname: "/login"
            }}
          />
      )
    }

  }else{
    return(
      <LoadingPage />
    )
  }
}
export default ProtectedRoute;
