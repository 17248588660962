import React, {useState} from 'react'
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import UserNew from './UserNew';
import UserEdit from './UserEdit';
import {
  useFirestoreConnect,
  useFirestore,
  isLoaded,
  isEmpty
} from 'react-redux-firebase'
import { MdAddCircleOutline,MdModeEdit,MdFilterList } from 'react-icons/md';
import { Row,Col,Table } from 'react-bootstrap';

const useUserList = () => {
    const firestore = useFirestore()
    const profile = useSelector(state => state.firebase.profile)
    var query = ['publisherId','>',''];
    var queryInactive = ['publisherId','>',''];

    if(!profile.isSuperAdmin && profile.role !== "adminTeacher"){
      query = [['publisherId','==',profile.publisherId],['isSuperAdmin','==' ,false]];
      queryInactive = [['publisherId','==',profile.publisherId]];
    }
    useFirestoreConnect([
      {
        collection: 'users',
        where:query
      }
    ])
    useFirestoreConnect([
      {
        collection: 'inactive_users',
        where:queryInactive
      }
    ])
   
    const users          = useSelector(state => state.firestore.ordered.users)
    const inactive_users = useSelector(state => state.firestore.ordered.inactive_users)
    // New dialog
    const [newDialogOpen, changeDialogState] = useState(false)
    const toggleDialog = () => {
      changeDialogState(!newDialogOpen)
    }

    const [editDialogOpen, changeDialogStateEdit] = useState(false)
    const toggleDialogEdit = () => {
      changeDialogStateEdit(!editDialogOpen)
    }
  
    //const [deleteDialogOpen, changeDialogStateDelete] = useState(false)
    // const toggleDialogDelete = () => {
    //   changeDialogStateDelete(!deleteDialogOpen)
    // }

    const [selectedUser, selectUser] = useState(false);
    function addUser(instance) {
      return firestore.add(
        'inactive_users',{
          ...instance,
          "inactive":true
        }).then((user) => {
          toggleDialog();
        })
        .catch(err => {
          console.error('Error:', err) // eslint-disable-line no-console
          //showError(err.message || 'Could not add project')
          return Promise.reject(err)
        })
    }

    function editUser(instance) {
      return firestore.update(
        'users/'+selectedUser.id,{
          ...instance,
          "inactive":true
        }).then(() => {
          toggleDialogEdit();
        })
        .catch(err => {
          console.error('Error:', err) // eslint-disable-line no-console
          //showError(err.message || 'Could not add project')
          return Promise.reject(err)
        })
    }
    return { users,profile,newDialogOpen,toggleDialog,addUser,inactive_users,editDialogOpen,toggleDialogEdit,selectedUser, selectUser,editUser }
}

const UserList = () => {
    const {users,profile,newDialogOpen,toggleDialog,addUser,inactive_users,editDialogOpen,toggleDialogEdit,selectedUser, selectUser,editUser} = useUserList()
    if(profile.role !== "creator"){
      if(isLoaded(users,inactive_users)){
          return(
              <div style={{height:"calc(100vh - 121px)"}}>
                <UserNew show={newDialogOpen} handleModalClose={toggleDialog} handleSubmit={addUser} ></UserNew>
                <UserEdit show={editDialogOpen} handleModalClose={toggleDialogEdit} handleSubmit={editUser} user={selectedUser} ></UserEdit>
                <Row>
                    <Col>
                    <div className="d-flex justify-content-between">
                      <div>
                        <span className="d-flex align-items-center small text-uppercase" style={{cursor:"pointer"}} >
                          <span className="text-muted d-flex align-items-center">
                            <MdFilterList className="mr-2"/>Sortiert:
                          </span>
                          <span className="ml-2">
                            A-Z
                          </span>
                        </span>
                      </div>
                      <span className="d-flex align-items-center text-primary" style={{cursor:"pointer"}} onClick={toggleDialog}><MdAddCircleOutline className="mr-2"/>Neuen Benutzer einladen</span>
                    </div>
                    </Col>
                </Row>
                {!isEmpty(inactive_users) &&
                <Row className="mt-4">
                  <Col>
                  <div className="bg-white p-4 shadow-border">
                    <h5 className="mb-3">Ausstehende Einladungen</h5>
                    <Table striped bordered hover >
                      <thead>
                        <tr>
                          <th>Mail</th>
                          <th>Rolle</th>
                          {profile.isSuperAdmin && <th>Verlag</th>}
                          <th>Link</th>
                        </tr>
                      </thead>
                      <tbody>
                        {inactive_users.map(user => (
                          <tr key={user.id}>
                            <td>{user.email}</td>
                            <td>{user.role}</td>
                            {profile.isSuperAdmin && <td>{user.publisherName}</td>}
                            <td>{"https://"+window.location.hostname + "/registration/"+user.id}</td>
                          </tr>
                      ))}
                      </tbody>
                    </Table>
                    </div>
                  </Col>
                </Row>
                }
                <Row className="mt-4">
                  <Col>
                  <div className="bg-white p-4 shadow-border">
                    <h5 className="mb-3">Registrierte Nutzer</h5>
                    <Table striped bordered hover>
                      <thead>
                        <tr >
                          <th>Mail</th>
                          <th>Name</th>
                          <th>Rolle</th>
                          {profile.isSuperAdmin && <th>Verlag</th>}
                          <th>Aktion</th>
                        </tr>
                      </thead>
                      <tbody>
                        {users.map(user => (
                          <tr key={user.id}>
                            <td>{user.email}</td>
                            <td>{user.displayName}</td>
                            <td>{user.role}</td>
                            {profile.isSuperAdmin && <td>{user.publisherName}</td>}
                            <td><MdModeEdit className="text-button mr-2" onClick={() => {selectUser(user);toggleDialogEdit()}} /></td>
                          </tr>
                      ))}
                      </tbody>
                    </Table>
                    </div>
                  </Col>
                </Row>
            </div>
          )
      }else{
          return(
              <div style={{height:"calc(100vh - 121px)"}}>
                
            </div>
          )
      }
    }else{
      return <Redirect to={{pathname: "/"}}></Redirect>
    }
}

export default UserList