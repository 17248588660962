import React,{useState} from 'react';
import {Button,Form} from 'react-bootstrap'
import {
    useFirestore,
  } from 'react-redux-firebase'
import { MdClose } from 'react-icons/md';
import {AiFillCaretLeft,AiFillCaretRight} from 'react-icons/ai'
import { useSelector } from 'react-redux';
import BlanksTitleForm from './BlanksTitleForm';


const BlanksContent = ({dbQuiz,handleSubmit}) =>  {
    const profile = useSelector(state => state.firebase.profile)
    const auth = useSelector(state => state.firebase.auth)
    const firestore = useFirestore()
    const [state, updateState] = useState({
        step:1,
        quiz: {
        },
        questions:[],
        initialText:"",
        title:"",
        quizquestionsId:""
    });

    if(dbQuiz && Object.keys(state.quiz).length === 0 ){
        let quizId = dbQuiz;
        firestore.get({ collection: 'quizes',doc:dbQuiz}).then((doc) => {
            const quiz = {...doc.data(),id:quizId}
            firestore.get({ collection: 'quizes/'+doc.id+'/quizquestions'}).then((querySnapshot) => {
                let questions = [];
                var title = "";
                var quizquestionsId = "";
                var initialText = "";
                querySnapshot.forEach(function(doc) {
                    var dbText = doc.data().textArray
                    title = doc.data().task
                    quizquestionsId = doc.id
                    for(var i= 0;i<dbText.words.length;i++){
                        questions.push({
                           word:dbText.words[i],
                           active:dbText.activeStates[i]
                       })
                    }
                    initialText = dbText.words.join(" ");
                });
                
                updateState({
                    ...state,
                    quiz,
                    questions,
                    title,
                    quizquestionsId,
                    initialText
                });
            })
        })
    }

    const goToStep = (step) =>{
        updateState({
            ...state,
            step:step,
        });
    }

    const updateBlanks = (key, value) => {
        const { quiz } = state;
        quiz[key] = value;
        updateState({
            ...state,
            quiz,
        });
    };


    const updateTextArea = (e) => {
        let { questions } = state;
        //const text = e.target.value.replace(/(\r\n|\n|\r)/gm, "").split(" ");
        let x = e.target.value;
        x = x.replace(/(\r\n|\n|\r)/gm, " \n");
        const text = x.split(" ");
        questions = text.map((word,i)=>{
            return {
                word:word,
                active:false
            }
        })
        updateState({
            ...state,
            initialText:e.target.value,
            questions
        });
    }

    const changeWordArray = (i,active) => {
        let { questions } = state;
        questions[i].active = active;
        updateState({
            ...state,
            questions
        });
    }

    const saveBlanksText = () => {
        if(!dbQuiz){
            let { quiz,questions } = state;
            let words = questions.map((text) => {
                return text.word;
            })
            let activeStates = questions.map((text) => {
                return text.active;
            })
            let textArray = {
                words:words,
                activeStates:activeStates,
            }

            firestore.add("quizes", {
                ...quiz,
                type:"Lückentext"
            }).then((data) => {
                let quizId = data.id
                firestore.add('quizes/'+data.id+'/quizquestions', {
                    textArray:textArray,
                    task:quiz.title
                }).then(() => {
                    handleSubmit({
                        text:quiz.title,
                        type:'Lückentext',
                        quizId:quizId
                    })
                })
            })
        }else{
            updateBlanksText();
        }
    }

    const updateBlanksText = () => {
        const { quiz,questions,title,quizquestionsId } = state;
        let words = questions.map((text) => {
            return text.word;
        })
        let activeStates = questions.map((text) => {
            return text.active;
        })
        let textArray = {
            words:words,
            activeStates:activeStates,
        }
        firestore.update("quizes/"+dbQuiz, {
                ...quiz,
                type:"Lückentext",
                publisherId:profile.publisherId,
                creator:{
                    id:auth.uid,
                    name:profile.displayName
                }
            }).then(() => {
                firestore.update('quizes/'+dbQuiz+'/quizquestions/'+quizquestionsId, {
                    textArray:textArray,
                    task:title
                }).then(() => {
                    handleSubmit({
                        text:quiz.title
                    })
                })
            })
    }

    const saveRequirements = () => {
        const { questions,quiz } = state;
        return quiz.title && questions.length > 0 && questions.filter(word => word.active).length > 0;
    }

   
    return(
        <div className="modal-body-container">
            <div className="p-3 modal-content-container-fixed">
                {state.step === 1 && <BlanksTitleForm quiz={state.quiz} update={updateBlanks} goToStep={goToStep}/>}
                {state.step === 2 && 
                <div  className="modal-content-container">
                    <Form.Group>
                        <Form.Label>Fließtext</Form.Label>
                        <Form.Control as="textarea" rows="10"  name="description" placeholder="Tippen Sie hier den ganzen Text ein..." value={state.initialText} onChange={updateTextArea} required />
                    </Form.Group>
                    <div className="d-flex align-items-center justify-content-between">
                        <span className="text-button text-primary d-flex align-items-center" onClick={() => goToStep(1)}> <AiFillCaretLeft className="mr-1"/> Zurück</span>
                        <span className="text-button text-primary d-flex align-items-center" onClick={() => goToStep(3)} > Lücken auswählen <AiFillCaretRight className="ml-1" /></span>
                    </div>
                </div>
                }
                {state.step === 3 && 
                <div className="modal-content-container">
                    <div className="modal-content-scroll pr-3">
                        <h5 className="mb-3">Wählen Sie die Lücken aus.</h5>
                        <div className="d-flex flex-wrap">
                            {state.questions.flatMap((word,i) => {
                            var patt1 = /\n/;
                            if(word.word !== "\n" && word.word.length > 0){
                                if(word.active){
                                    if(word.word.search(patt1) > -1){
                                        return[
                                            <span key={i+"-break"} className="flex-break"></span>,
                                            <span key={i} className="m-1" style={{width:70,height:32,borderBottom:"1px dashed #757575"}}></span>
                                            
                                        ]
                                    }
                                    return <span key={i} className="m-1"  style={{width:70,height:32,borderBottom:"1px dashed #757575"}}></span>
                                }else {
                                    
                                    if(word.word.search(patt1) > -1){
                                        return[
                                            <span key={i+"-break"} className="flex-break"></span>,
                                            <span className="p-1 blank-word" onClick={() => changeWordArray(i,true)}  key={i}>{word.word}</span>
                                            
                                        ]

                                    }
                                    return(
                                        <span className="p-1 blank-word" onClick={() => changeWordArray(i,true)}  key={i}>{word.word}</span>
                                    )
                                }
                                
                            }

                            })}
                        </div>
                        <div className="d-flex flex-wrap mt-3">
                            {state.questions.flatMap((word,i) => {
                                if(word.active){
                                    return(
                                        <span className="p-1 px-2 bg-dark d-flex m-1 text-white align-items-center rounded text-button"  onClick={() => changeWordArray(i,false)} key={i} >{word.word} <MdClose className="ml-2"/></span>
                                    )
                                }
                            })}
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between mt-4">
                        <span className="text-button text-primary d-flex align-items-center" onClick={() => goToStep(2)}> <AiFillCaretLeft className="mr-1"/> Zurück</span>
                    </div>
                </div>
                }
            </div>
            <div className="p-3 bg-white d-flex justify-content-end">
                <Button variant="primary" disabled={!saveRequirements()} onClick={saveBlanksText}>Speichern</Button>
            </div>
        </div>
    )   
}

export default BlanksContent;