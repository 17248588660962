import React, { useState } from 'react';
import {Modal,Form,Button} from 'react-bootstrap'
import { useSelector } from 'react-redux';
import {
  useFirestore,
  useFirestoreConnect,
  isLoaded,
  useFirebase,
} from 'react-redux-firebase'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {Row,Col} from 'react-bootstrap'
import {NotificationContainer, NotificationManager} from 'react-notifications';

const useUserSettings = () => {
  const profile = useSelector(state => state.firebase.profile)
  const auth = useSelector(state => state.firebase.auth)
  const firestore = useFirestore()
  const firebase = useFirebase()
  useFirestoreConnect([{collection: 'publishers',}])
  // Get books from redux state
  const publishers = useSelector(state => state.firestore.ordered.publishers)

  const [selectedPublisher] = useState(false)

  const onSubmitPasswordForm = handleChangeSuccess => event => {
    event.preventDefault()
    const {cur_password,new_password,new_password_confirm} = event.target.elements
    
    firebase.reauthenticate({
      credential: firebase.auth.EmailAuthProvider.credential(auth.email, cur_password.value)
    }).then(function() {
       if(new_password.value === new_password_confirm.value){
          firebase.auth().currentUser.updatePassword(new_password.value).then(function() {
            handleChangeSuccess();
          })
        }else{
          NotificationManager.error('Bitte überprüfen Sie Ihre Daten', 'Fehler!');
          //setLoginError(true);
        }
    }).catch(function(error) {
        NotificationManager.error('Bitte überprüfen Sie Ihre Daten', 'Fehler!');
        //setLoginError(true);
    });

   
  }

  const onSubmitUserForm = event => {
    event.preventDefault()
   
  }

  
  const updateUser = (publisher) => {
    if(publisher){
      return firestore.update(
        'users/'+auth.uid,{
          publisherId:publisher.value,
          publisherName:publisher.label,
        }).then(() => {
        })
        .catch(err => {
          console.error('Error:', err) // eslint-disable-line no-console
          //showError(err.message || 'Could not add project')
          return Promise.reject(err)
        })
    }
    return null;
  }
  return { profile,publishers,updateUser,selectedPublisher,auth,onSubmitPasswordForm,onSubmitUserForm }
}

const Profile = ({show,handleModalClose,handleChangeSuccess}) => {
  
  const { profile,publishers,auth,onSubmitPasswordForm,onSubmitUserForm} = useUserSettings();

  if(!isLoaded(publishers)){
    return(
      <div></div>
    )
  }else{
    return(
      <Modal show={show} onHide={() => handleModalClose(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Profil Einstellungen</Modal.Title>
            </Modal.Header>
            <NotificationContainer/>
            <Tabs>
              <TabList className="d-flex" >
                <Tab className="text-uppercase small text-button text-medium py-3 mr-4">allgemein</Tab>
                <Tab className="text-uppercase small text-button text-medium py-3 mr-4">sicherheit</Tab>
                {/* {profile.role == "admin" ? <Tab className="text-uppercase small text-button text-medium py-3">Admin</Tab> : <span></span>} */}
              </TabList>
             
              <TabPanel>
              <Form onSubmit={onSubmitUserForm}>
              <Modal.Body>
             
                <Form.Group>
                <Form.Label >Name</Form.Label>
                  <Form.Control type="text" name="title" defaultValue={profile ? profile.displayName : ""} readOnly placeholder="Name" />
                </Form.Group>
                <Form.Group>
                <Form.Label >E-Mail</Form.Label>
                  <Form.Control type="text" name="title" defaultValue={auth ? auth.email : ""} readOnly placeholder="E-Mail Adresse" />
                </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                   <div className="d-flex align-items-center justify-content-between w-100">
                    <Button onClick={() => {handleModalClose(false)}} variant="secondary">Abbrechen</Button>
                    <Button type="submit" disabled variant="primary">Speichern</Button>
                  </div> 
                
                  </Modal.Footer>
                  </Form>
              </TabPanel>
              <TabPanel>
                <Form onSubmit={onSubmitPasswordForm(handleChangeSuccess)}>
                  <Modal.Body>
                  <Row>
                        <Col>
                        <Form.Group>
                          <Form.Label >Aktuelles Passwort</Form.Label>
                          <Form.Control type="password" name="cur_password"  placeholder="Aktuelles Passwort" minLength={6} required />
                        </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                        <Form.Group>
                        <Form.Label >Neues Passwort</Form.Label>
                      <Form.Control type="password" name="new_password"  placeholder="Neues Passwort" minLength={6} required />
                    </Form.Group>
                        </Col>
                        <Col>
                        <Form.Group>
                      <Form.Label >Passwort bestätigen</Form.Label>
                      <Form.Control type="password" name="new_password_confirm"  placeholder="Passwort bestätigen" minLength={6} required />
                    </Form.Group>
                        </Col>
                      </Row>
                    </Modal.Body>
                    <Modal.Footer>
                   <div className="d-flex align-items-center justify-content-between w-100">
                    <Button onClick={() => {handleModalClose(false)}} variant="secondary">Abbrechen</Button>
                    <Button type="submit" variant="primary">Speichern</Button>
                  </div> 
                </Modal.Footer>
              </Form>
              </TabPanel>
              {/* <TabPanel>
                  <Modal.Body>
                    <Form>
                    <Form.Group controlId="exampleForm.SelectCustom">
                      <Form.Label>Publisher</Form.Label>
                      <Select onChange={updateUser}
                        defaultValue={selectedPublisher}
                        options={publishers.map(publisher => {
                          return {label:publisher.name,value:publisher.id}
                        })}
                      />
                    </Form.Group>
                  </Form>
                </Modal.Body>
              </TabPanel> */}
        
            </Tabs>
        </Modal>
    )
  }
}

export default Profile
