import React from 'react'
import {Modal} from 'react-bootstrap'
import FeedbackForm from './FeedbackForm'


const FeedbackNew = ({show,handleModalClose,handleSubmit}) => (
  <div>
    <Modal show={show} onHide={() => handleModalClose(false)}>
        <Modal.Header closeButton>
            <Modal.Title>Neues Feedback Melden</Modal.Title>
        </Modal.Header>
        <FeedbackForm handleSubmit={handleSubmit} />
    </Modal>
  </div>
)

export default FeedbackNew;