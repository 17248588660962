import React,{useState} from 'react'
import  Content from '../../layout/Content';
import DeleteModal from '../../helpers/DeleteModal';
import { useSelector } from 'react-redux';
import {
  useFirestore,
  useFirebase,
  useFirestoreConnect,
  isEmpty,
  isLoaded
} from 'react-redux-firebase'
import { MdArrowBack,MdAddCircleOutline,MdMoreHoriz,MdModeEdit,MdDeleteForever,MdPerson } from 'react-icons/md';
import { AiOutlineClockCircle } from 'react-icons/ai';
import {Dropdown} from 'react-bootstrap';
import Moment from 'react-moment';
import TopicEdit from './TopicEdit';
import TaskNew from '../tasks/TaskNew'
import TaskList from '../tasks/TaskList'
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';


const useTopicData = (subjectId,topicId,history) => {
  const firebase = useFirebase()
  const profile = useSelector(state => state.firebase.profile)
  const firestore = useFirestore()
  const app = firebase.app();
  const functions = app.functions("europe-west3");
  const recursiveDelete = functions.httpsCallable('recursiveDelete');
  const myTopic = 'myTopic'+topicId
  useFirestoreConnect([
    {
      collection: `subjects`,
      doc: subjectId,
      subcollections: [{ collection: "topics",doc:topicId }],
      storeAs: myTopic
    }
  ])
  const topicData = useSelector(state => state.firestore.ordered[myTopic])
  function addTask(newInstance) {
    return firestore
      .add('subjects/'+subjectId+"/topics/"+topicId+"/tasks", {
        ...newInstance,
        subjectId:subjectId,
        topicId:topicId
      })
      .then(() => {
        firestore.update('subjects/'+subjectId+"/topics/"+topicId, {
          pageCount:firebase.firestore.FieldValue.increment(1)
        });
        toggleDialog()
        //showSuccess('Project added successfully')
      })
      .catch(err => {
        console.error('Error:', err) // eslint-disable-line no-console 
        //showError(err.message || 'Could not add project')
        return Promise.reject(err)
      })
  }

  function editTopic(instance){
    return firestore.update(
      'subjects/'+subjectId+"/topics/"+topicId,{
        ...instance,
        subjectId:subjectId,
      }).then(() => {
        toggleDialogEdit(false)
      })
      .catch(err => {
        console.error('Error:', err) // eslint-disable-line no-console
        //showError(err.message || 'Could not add project')
        return Promise.reject(err)
      })
  }

  function deleteTopic(){
    recursiveDelete({path:'subjects/'+subjectId+'/topics/'+topicId,project:process.env.REACT_APP_FIREBASE_PROJECT_ID}).then(() => {
      firestore.update('subjects/'+subjectId, {
        chapterCount:firebase.firestore.FieldValue.increment(-1)
      });
      history.push('/subjects/'+subjectId)
    });
  }
  const [newDialogOpen, toggleDialog] = useState(false)
  const [editDialogOpen, toggleDialogEdit] = useState(false)
  const [deleteDialogOpen, toggleDialogDelete] = useState(false)

  return {newDialogOpen, toggleDialog,editDialogOpen, toggleDialogEdit,deleteDialogOpen, toggleDialogDelete,deleteTopic,editTopic,addTask,topicData,profile}

}


const Topic = ({history,match}) => {
  const {newDialogOpen, toggleDialog,editDialogOpen, toggleDialogEdit,deleteDialogOpen, toggleDialogDelete,deleteTopic,editTopic,addTask,topicData,profile} 
  = useTopicData(match.params.subject,match.params.topic,history);
  if(!isLoaded(topicData)){
    return <p>Einen Moment...</p>
  }
  if(isEmpty(topicData)){
    return <Redirect to={{pathname: "/"}}></Redirect>
  }
  if((profile.role !== "admin") && topicData[0].publisherId !== profile.publisherId){
    return <Redirect to={{pathname: "/"}}></Redirect>
  }
  else{
    const topic = topicData[0];
    return(
      <div style={{height:"calc(100vh - 121px)"}}>
        <Content 
        leftHeadline={
          <div className="d-flex align-items-center justify-content-between">
            <Link to={`/subjects/${match.params.subject}`} className="d-flex align-items-center"><MdArrowBack /><span className="ml-2">Thema Details</span></Link>
            <Dropdown alignRight={true}>
              <Dropdown.Toggle className="icon-toggle" variant="light" id="dropdown-basic">
                <MdMoreHoriz />
              </Dropdown.Toggle>
              <Dropdown.Menu className="border-0 shadow">
                <Dropdown.Item  onClick={() => {toggleDialogEdit(!editDialogOpen)}} className="d-flex align-items-center text-button"><MdModeEdit /><span className="ml-2">Bearbeiten</span></Dropdown.Item>
                <Dropdown.Item onClick={() => {toggleDialogDelete(!deleteDialogOpen)}} className="d-flex align-items-center text-button"><MdDeleteForever /><span className="ml-2">Löschen</span></Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        }
        rightHeadline={
          <span className="d-flex align-items-center justify-content-between text-button text-primary" onClick={() => {toggleDialog(!newDialogOpen)}}><span>Aufgaben</span><MdAddCircleOutline size="1.5em" /></span>
        }
        left={
          <div>
            <div className="bg-white p-4 shadow-border">
              <h1>{topic.number}. {topic.title}</h1>
              <div className="d-flex mt-4 flex-wrap">
                  <div className="mr-5">
                    <p className="text-muted">Erstellt von</p>
        <div className="d-flex align-items-center font-weight-medium small font-weight-bold" style={{height:35}}> <span className="bg-secondary text-white rounded-circle d-flex align-items-center justify-content-center mr-2" style={{width:23,height:23,overflow:'hidden',flexShrink:0}}><MdPerson size="3em" /></span> {topic.creator.name}</div>
                  </div>
                  <div className="mr-4">
                    <p className="text-muted">Erstellt am</p>
                    <div className="d-flex align-items-center font-weight-medium small font-weight-bold" style={{height:35}}> <span className="bg-secondary text-white rounded-circle d-flex align-items-center justify-content-center mr-2" style={{width:23,height:23,overflow:'hidden',flexShrink:0}}><AiOutlineClockCircle size="3em" /></span> <Moment format="LL" date={topic.created} /></div>
              
                  </div>
                </div>
            </div>
          </div>
        } 
        right={
             <TaskList topic={topic} subjectId={match.params.subject} />
        }
        
        />
        <TopicEdit show={editDialogOpen} handleModalClose={() => {toggleDialogEdit(!editDialogOpen)}} handleSubmit={editTopic} topic={topic}></TopicEdit>
        <DeleteModal show={deleteDialogOpen} handleModalClose={() => {toggleDialogDelete(!deleteDialogOpen)}} handleSubmit={deleteTopic}></DeleteModal>
        <TaskNew show={newDialogOpen} handleModalClose={() => {toggleDialog(!newDialogOpen)}} handleSubmit={addTask}></TaskNew>
        </div>
    )
  }
}

export default Topic
