import React from 'react';
import { MdViewHeadline,MdPictureAsPdf } from 'react-icons/md';
import { AiOutlineQuestion,AiFillAudio } from 'react-icons/ai';
import { BsImage,BsFillCameraVideoFill,BsPuzzle } from 'react-icons/bs';
import { GiBrain } from 'react-icons/gi';


const ContentIcon = ({type}) => (
    <div>
        {type === "Textblock" && <MdViewHeadline/>}
        {type === "Image" && <BsImage />}
        {type === "Quiz" && <AiOutlineQuestion />}
        {type === "Video" && <BsFillCameraVideoFill />}
        {type === "Memory" && <GiBrain />}
        {type === "Lückentext" && <BsPuzzle/>}
        {type === "Pdf" && <MdPictureAsPdf/>}
        {type === "Audio" && <AiFillAudio/>}
    </div>
);

export default ContentIcon;