import React, { useState } from 'react';
import { useFirebase } from 'react-redux-firebase'
import LoadingPage from '../../components/LoadingPage';
import { Redirect } from 'react-router-dom';


const VerifyMail = ({actionCode, continueUrl, lang}) => {
    const firebase      = useFirebase()
    const auth          = firebase.auth();
    const [mailVerified, changeVerifiedStatus] = useState(false)

    if(!mailVerified){
        auth.applyActionCode(actionCode).then(function(resp) {
            changeVerifiedStatus("verified")
        }).catch(function(error) {
            changeVerifiedStatus("error")
        });
    }
   
    if(mailVerified === "verified"){
        setTimeout(() => {
            window.location.href = "/";
        },3000)
        return(
            <div>
                Bestätigung erfolgreich. Sie werden weitergeleitet...
            </div>
        )
    }else if(mailVerified === "redirect"){
        return(
            <Redirect to="/" />
        )
    }else if(mailVerified === "error"){
        return(
            <Redirect to="/" />
        )
    }
    else{
        return(
            <LoadingPage />
        )
    }
 }


export default VerifyMail;