export const uploadFile = (firebase,file,refPath) => {
    var storageRef = firebase.storage().ref(refPath);
    var fileRef = storageRef.child(file.name);
     
    return fileRef.put(file)
    .then( 
      (snapshot) => snapshot.ref.getDownloadURL())
    .catch( error => {
      console.log(`Whoops, couldn't create the file: ${error.message}`)
    });
}

export const deleteFile = (firebase,path) => {
  var storage = firebase.storage();
  var fileRef = storage.refFromURL(path);
   
  return fileRef.delete()
  .then( () => {console.log("file deleted")})
  .catch( error => {
    console.log(`Whoops, couldn't create the book: ${error.message}`)
  });
}