import React from 'react'
import {Form,Button, Modal} from 'react-bootstrap'
import { useSelector } from 'react-redux';

const TopicForm = ({handleSubmit,topic}) => {
  const profile = useSelector(state => state.firebase.profile)
  const auth = useSelector(state => state.firebase.auth)


    const onSubmit = event => {
        event.preventDefault()
        const {title,number} = event.target.elements
        let values = {
          title: title.value,
          number: parseInt(number.value),
        }
        if(!topic){
          values = {
                title: title.value,
                number: parseInt(number.value),
              created:Date.now(),
              creator:{id:auth.uid,name:profile.displayName},
              publisherId:profile.publisherId
          }
        }
        handleSubmit(values)
    }

    return (
      <Form onSubmit={onSubmit}>
      <Modal.Body>
      
        <Form.Group>
          <Form.Label >Name</Form.Label>
          <Form.Control type="text" name="title" defaultValue={topic ? topic.title : ""} placeholder="Name des Themas" required />
        </Form.Group>

        <Form.Group>
          <Form.Label >Nummer</Form.Label>
          <Form.Control type="number" name="number" defaultValue={topic ? topic.number : ""} placeholder="Nummer des Themas (dient der Sortierung)" />
        </Form.Group>


      </Modal.Body>
      <Modal.Footer>
      <Button variant="primary" type="submit">Speichern</Button>
      </Modal.Footer>
      </Form>
    )
}

export default TopicForm
