import React from "react";

import { BrowserRouter } from "react-router-dom";
import Routes from "./views/Routes"

function App() {
  return (
    <BrowserRouter basename="/">
      <Routes/>
    </BrowserRouter>
  );
}

export default App;