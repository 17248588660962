import React, { useState } from 'react';
import { useFirebase } from 'react-redux-firebase'
import {Form,Button} from 'react-bootstrap'
import customer from 'theme/customer';
import sent_messages from 'theme/sent_messages.svg'


const PasswordReset = () => {
    const firebase = useFirebase()

    const [mailSent, changeMailStatus] = useState(false)

    const onSubmit = (event) => {
        event.preventDefault();
        const {email} = event.target.elements
        var auth = firebase.auth();

        auth.sendPasswordResetEmail(email.value).then(function() {
            changeMailStatus(true);
        }).catch(function(error) {
        // An error happened.
        });
    }
    
    return(
        <div className="d-flex align-items-center justify-content-center flex-column" style={{height:"100vh"}}>
        <div className="d-flex align-items-center justify-content-center text-primary mb-5">
             <h2 className="mb-0 font-weight-normal">{customer.name} <b>LMS</b></h2>
         </div>
        <div style={{minWidth:"50%"}} className="bg-white p-4 shadow-border">
         {!mailSent && 
            <div>
            <h3 className="text-center">Passwort zurücksetzen</h3>
            <Form onSubmit={onSubmit} className="mt-5">
                    <Form.Group>
                        <Form.Label>Ihre E-Mail Adresse</Form.Label>
                        <Form.Control type="text" name="email"  required placeholder="E-Mail"  />
                    </Form.Group>
                    <Button className="w-100 p-3 mt-3" variant="primary" type="submit">
                        Passwort zurücksetzen
                    </Button>
                </Form>
            </div>
        }
        {mailSent && 
            <div className="d-flex align-items-center justify-content-center flex-column">
                <h3 className="text-center mb-5">Die E-Mail zum Zurücksetzen<br></br>Ihres Passwortes wurde versandt.</h3>
                <img alt="Password sent" src={sent_messages} style={{width:"40%"}}/>
                <span className="text-center mt-5 d-block">
                    Bitte überprüfen Sie Ihr Postfach und Ihren Spam Ordner.
                </span>
                <span onClick={() => {changeMailStatus(false);}} className="mt-5 text-button d-block text-center text-primary" >Keine Mail erhalten?</span>
            </div>
        }
            
         </div>
    </div>
    )

 }


export default PasswordReset;