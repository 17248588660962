import React from 'react';
import VerifyMail from '../mail/VerifyMail'
import PasswordReset from '../mail/PasswordReset'


const Verify = (props) => {
    const url           = new URL(window.location);
    const mode          = url.searchParams.get("mode");
    const actionCode    = url.searchParams.get("oobCode");
    const continueUrl   = url.searchParams.get("continueUrl");
    const lang          = url.searchParams.get("lang") || 'de';

    switch (mode) {
        case 'resetPassword':
          // Display reset password handler and UI.
         // handleResetPassword(auth, actionCode, continueUrl, lang);
          return(
            <PasswordReset actionCode={actionCode} continueUrl={continueUrl} lang={lang} />
         )
        case 'recoverEmail':
          // Display email recovery handler and UI.
          //handleRecoverEmail(auth, actionCode, lang);
          return(
            <div>recover</div>
         )
        case 'verifyEmail':
          return(
            <VerifyMail actionCode={actionCode} continueUrl={continueUrl} lang={lang} />
        )
        default:
            return(
                <div>error</div>
             )
          // Error: invalid mode.
      }

 }


export default Verify;