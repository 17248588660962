import React, {useMemo, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import {uploadFile} from '../helpers/upload'
import { useFirebase,useFirestore } from 'react-redux-firebase';
import {Spinner} from 'react-bootstrap';
import {FaCheckSquare} from 'react-icons/fa';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { useSelector } from 'react-redux';

const baseStyle = {
  flex: 1,
  display: 'flex',
  width:"100%",
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

function PdfFileDropzone(props) {
 const [files, setFiles] = useState([]);
 const [loading, setLoading] = useState(false);
 const profile = useSelector(state => state.firebase.profile)
 const {handleUploadSuccess,refId} = props 
 const firebase = useFirebase()
 const firestore = useFirestore()
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
      accept: 'application/pdf',
      multiple:false,
      maxSize:10485760,
      onDropAccepted : acceptedFiles => {
        setFiles(acceptedFiles.map(file => Object.assign(file)));
        setLoading(true)
        uploadFile(firebase,acceptedFiles[0],"task-pdf/"+refId)
          .then( (path) => {
            firestore.add('files', {
              path:path,
              type:"Pdf",
              name:acceptedFiles[0].name,
              publisherId:profile.publisherId
            })
          setLoading(false);
          handleUploadSuccess(refId,path,acceptedFiles[0].name)}
        )
      },
      onDropRejected : () => {
        NotificationManager.error('Achtung die maximale Dateigröße liegt bei 10Mb.','Datei nicht gültig!');
      }
    });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);

  const thumbs = files.map(file => (
    <div key={file.name} className="d-flex align-items-center justify-content-between w-100">
        <span>{file.name}</span>
        { loading ? 
            <Spinner animation="border" variant="secondary" size="sm"  />
            :
            <FaCheckSquare />
        }
    </div>
  ));

    return (
        <div {...getRootProps({style})}>
            {
                files.length === 0 ?
                <div>
                    <input {...getInputProps()} />
                    <p>Datei einfach per Drag 'n' drop ablegen, oder Klicken um eine Datei auszuwählen</p>
                    <em>(Nur *.pdf Dateien werden akzeptiert, max. 10Mb)</em>
                </div>
                : thumbs
            }
            
            <NotificationContainer/>
        </div>
    );
}

export default PdfFileDropzone