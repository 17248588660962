import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import { getFirebase } from 'react-redux-firebase'
import rootReducer from "./reducers";

const middlewares = [
  thunk.withExtraArgument(getFirebase)
]

export default function configureStore(persistedState) {
    const store = createStore(
      rootReducer,
      persistedState,
      applyMiddleware(...middlewares)
    );
    return store;
  }