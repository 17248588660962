import React,{useState} from 'react';
import {Button, Form} from 'react-bootstrap'
import { MdDeleteForever } from 'react-icons/md';
import PdfFileDropzone from '../../components/PdfDropzone';

const PDFContent = ({handleSubmit,pdf,modeEdit,changeMode,taskId }) =>  {
    const savePdfContent = (event) => {
        event.preventDefault();
        if(uploadedFile){
            const {title} = event.target.elements
            handleSubmit({
                path:uploadedFile.path,
                type:"Pdf",
                text:title.value,
                filename:uploadedFile.name
            })
        }
    }
    const [uploadedFile, setuploadedFile] = useState();
    if(modeEdit && !uploadedFile){
        setuploadedFile({
            name:pdf.filename,
            path:pdf.path
        })
    }
    const changeModes = () => {
        if(changeMode){
            changeMode(false)
        }
        setuploadedFile(false);
    }
    return  (
        <div className="modal-body-container">
            <Form onSubmit={savePdfContent} className="modal-body-container">
                 <div className="p-4">
                    <Form.Group>
                        <Form.Label >Titel</Form.Label>
                        <Form.Control type="text" name="title" defaultValue={pdf ? pdf.text : ""}  required placeholder="Titel der PDF Vorschau" />
                    </Form.Group>
                    {!uploadedFile && <PdfFileDropzone handleUploadSuccess={(refId,path,filename) => setuploadedFile({name:filename,path:path})} refId={taskId} />}
                    {uploadedFile && 
                       <div  className="d-flex align-items-center justify-content-between ">
                       <span>{uploadedFile.name}</span>
                       <MdDeleteForever onClick={changeModes} className="text-button"/>
                     </div>}
                </div> 
                <div className="p-3 bg-white d-flex justify-content-end">
                    <Button disabled={uploadedFile ? false : true} variant="primary" type="submit" >Speichern</Button>
                </div>
            </Form>
        </div>
    )
}

export default PDFContent;