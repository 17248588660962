// the main file in our front-end app
// create-react-app expects a file in src/index.js and loads everything from here
import React from "react";
import ReactDOM from "react-dom";
import Root from "./Root";
//import * as serviceWorker from "./serviceWorker";
import './themes/thea/colors.scss'
import './styles/custom.scss';
import 'react-notifications/lib/notifications.css';

ReactDOM.render(<Root />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
