import React from "react";
import { Form } from "react-bootstrap";
import { AiFillCaretRight } from "react-icons/ai";

const QuizTitleForm = (props) => {
  const update = (e) => {
    props.update(e.target.name, e.target.value);
  };
  return (
    <div className="modal-content-container">
      <Form>
        <Form.Group>
          <Form.Label>Titel</Form.Label>
          <Form.Control
            type="text"
            name="title"
            defaultValue={props.quiz.title ? props.quiz.title : ""}
            onChange={update}
            required
            placeholder="Titel des Quizes"
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Beschreibung</Form.Label>
          <Form.Control
            type="text"
            name="text"
            defaultValue={props.quiz.text ? props.quiz.text : ""}
            onChange={update}
            required
            placeholder="Beschreibung des Quizes"
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Anzahl Antwortmöglichkeiten</Form.Label>
          <Form.Control
            type="number"
            name="numberAnswers"
            value={props.quiz.numberAnswers ? props.quiz.numberAnswers : 4}
            min={2}
            max={6}
            onChange={update}
            required
            placeholder="Anzahl Antwortmöglichkeiten"
          />
        </Form.Group>
      </Form>
      <div className="d-flex align-items-center justify-content-end">
        <span
          className="text-button text-primary d-flex align-items-center"
          onClick={() => {
            props.jumpToStep(props.stepNum + 1);
          }}
        >
          {props.isEdit ? "Fragen bearbeiten" : "Fragen hinzufügen"}{" "}
          <AiFillCaretRight />
        </span>
      </div>
    </div>
  );
};

export default QuizTitleForm;
