import React,{useState} from 'react';
import { useSelector } from 'react-redux';
import TaskContentEdit from '../../tasks/TaskContentEdit'
import DeleteModal from '../../helpers/DeleteModal'
import DraggableList from '../../tasks/DraggableList'
import {Dropdown} from 'react-bootstrap';
import { MdMoreHoriz,MdModeEdit,MdDeleteForever } from 'react-icons/md';
import ContentIcon from '../../tasks/ContentIcons';
import {
    useFirestoreConnect,
    useFirestore,
    isLoaded
  } from 'react-redux-firebase'

const TaskContent = ({task,subjectId,topicId}) => {
  const contents = 'contents'+task.id
  useFirestoreConnect([
    {
      collection: `subjects`,
      doc: subjectId,
      subcollections: [{ 
        collection: "topics",doc: topicId,
        subcollections:[{
            collection:"tasks",doc:task.id,
            subcollections:[{
                collection:"contents"
            }] 
        }] 
      }],
      storeAs: contents
    }
  ])
  const firestore = useFirestore()
  function deleteContent() {
    var deletedId = selectedContent.id
    var deleted = selectedContent
    return firestore
      .delete({ collection: 'subjects/'+subjectId+'/topics/'+topicId+'/tasks/'+task.id+"/contents/", doc: selectedContent.id })
      .then(() => {
        var newOrder = [...task.contentOrder];
        var index = newOrder.indexOf(deletedId);
        newOrder.splice(index,1);
        var taskCount = task.taskCount
        if(!taskCount){
          taskCount = 0
        }else if(deleted.quizId){
          taskCount = taskCount - 1
        }
        firestore.update(
         'subjects/'+subjectId+'/topics/'+topicId+'/tasks/'+task.id,{
            'contentOrder':newOrder,taskCount
        }).then(() => {
          selectContent(false)
          toggleDialogDelete()
        })
        
      })
      .catch(err => {
        console.error('Error:', err) // eslint-disable-line no-console
        //showError(err.message || 'Could not add project')
        return Promise.reject(err)
      })
  }
 
  const [editDialogOpen, changeDialogStateEdit] = useState(false)
  const toggleDialogEdit = () => {
    changeDialogStateEdit(!editDialogOpen)
  }

  const [deleteDialogOpen, changeDialogStateDelete] = useState(false)
  const toggleDialogDelete = () => {
    changeDialogStateDelete(!deleteDialogOpen)
  }

  const [selectedContent, changeSelectedContent] = useState(false)
  const selectContent = (topic) => {
    changeSelectedContent(topic)
  }
  const contentData = useSelector(state => state.firestore.ordered[contents])
  const sortingArr = task.contentOrder;
  if(isLoaded(contentData)){
    var sortedContent = [...contentData];
    if(sortingArr){
      sortedContent = [...contentData].sort(function(a, b){  
        return sortingArr.indexOf(a.id) - sortingArr.indexOf(b.id);
      });
    }
    const draggableItems  = sortedContent.map(content => {
      return {
        id:content.id,
        content:
        <div key={content.id} className="p-3 d-flex align-items-center justify-content-between bg-white" style={{borderRadius: 10,boxShadow: "0px 0px 2px #d5d5d5" }}>
        <div className="d-flex align-items-center" style={{width:"90%"}}>
          <div className="mr-2 d-flex align-items-center justify-content-center" style={{minWidth:25,height:25,borderRadius:3,background:"#eaeaea"}}>
            <ContentIcon type={content.type} />
          </div>
          <div style={{overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap"}}>{content.text}</div>
        </div>
        
        <Dropdown alignRight={true} className="pl-3">
          <Dropdown.Toggle className="icon-toggle" variant="light" id="dropdown-basic">
            <MdMoreHoriz />
          </Dropdown.Toggle>
          <Dropdown.Menu className="border-0 shadow">
            <Dropdown.Item  onClick={() => {selectContent(content);toggleDialogEdit();}} className="d-flex align-items-center text-button"><MdModeEdit /><span className="ml-2">Bearbeiten</span></Dropdown.Item>
            <Dropdown.Item onClick={() => {selectContent(content);toggleDialogDelete();}} className="d-flex align-items-center text-button"><MdDeleteForever /><span className="ml-2">Löschen</span></Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      }
    })
    return(
        <div>
           
           {selectedContent ? <TaskContentEdit show={editDialogOpen} handleModalClose={() => {selectContent(false);toggleDialogEdit();}} contentPath={'subjects/'+subjectId+'/topics/'+topicId+'/tasks/'+task.id} content={selectedContent} taskId={task.id} /> : <div></div>}
           <DeleteModal show={deleteDialogOpen} handleModalClose={() => {selectContent(false);toggleDialogDelete();}} handleSubmit={deleteContent}></DeleteModal>
            <div className="d-flex flex-column">
                <DraggableList items={draggableItems} sortArray={sortingArr} contentPath={'subjects/'+subjectId+'/topics/'+topicId+'/tasks/'+task.id}></DraggableList>
            </div>
        </div>
    )
  }else{
      return <div></div>
  }

}
export default TaskContent;