import React from 'react'
import {Modal} from 'react-bootstrap'


const FeedbackNew = ({show,handleModalClose,feedback}) => (
  <div>
    <Modal show={show} onHide={() => handleModalClose(false)}>
        <Modal.Header closeButton>
            <Modal.Title>{feedback.topic}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>{feedback.description}</p>
            <img alt="feedback" className="w-100"  src={feedback.image} />
        </Modal.Body>
    </Modal>
  </div>
)

export default FeedbackNew;