import React from "react";
import {Container,Col} from 'react-bootstrap'
import { useSelector } from "react-redux";

export default function UseDataChartExample() {
  var iFrameUrls = [];
  iFrameUrls["ZnvdKz8ITQpCVkgBnj5D"] =
    "https://datastudio.google.com/embed/reporting/6a89cd4b-ccc8-4796-93cd-31826043b474/page/DOBbB";
  iFrameUrls["Owps3zSj1lOklrSpvuFX"] =
    "https://datastudio.google.com/embed/reporting/0098fec4-be5f-4931-87f8-ecf8e5d67924/page/DOBbB";
  iFrameUrls["M3QD1bQwHodsRREEoRA4"] =
    "https://datastudio.google.com/embed/reporting/0098fec4-be5f-4931-87f8-ecf8e5d67924/page/DOBbB";
  iFrameUrls["kitX4mjUmDQOgqL8sS0v"] =
    "https://datastudio.google.com/embed/reporting/bb4add69-cb0e-45d8-b9a2-bf5289fab898/page/DOBbB";
  iFrameUrls["Mcch2aSow96XjgQni8pT"] =
    "https://datastudio.google.com/embed/reporting/aa47f863-59cd-4767-a698-21e86f01948b/page/DOBbB";
  iFrameUrls["xMytV5HFVqDeMTrBRs5u"] =
    "https://datastudio.google.com/embed/reporting/a4d7cca5-9739-45f9-bd9a-b8d388e660ab/page/DOBbB";
  const profile = useSelector((state) => state.firebase.profile);
  return (
    <Container>
      <Col lg="12">
      <div
        >
          <iframe
            title="Analytics"
            style={{ width: "100%", height: 2000 }}
            src={iFrameUrls[profile.publisherId]}
            frameBorder="0"
            allowFullScreen={false}
          ></iframe>
          <div style={{height:60,width:"100%",marginTop: -50,background: "#f8f9f9",position: "absolute",right:0}}></div>
        </div>
      </Col>
   
    </Container>
    
  );
}
