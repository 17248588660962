import React, {useState} from 'react'
import  Content from '../../layout/Content';
import { useSelector } from 'react-redux';
import {Dropdown} from 'react-bootstrap'
import TaskEdit from './TaskEdit';
import DeleteModal from '../../helpers/DeleteModal';
import {Row,Col} from 'react-bootstrap'
import {
  isLoaded,
  isEmpty,
  useFirestoreConnect,
  useFirestore,
  useFirebase
} from 'react-redux-firebase'
import { MdArrowBack,MdMoreHoriz,MdModeEdit,MdDeleteForever,MdPerson,MdAddCircleOutline } from 'react-icons/md';
import TaskContent from './TaskContent';
import TaskContentAdd from '../../tasks/TaskContentAdd'
import Moment from 'react-moment';
import { AiOutlineClockCircle } from 'react-icons/ai';
import WeekPicker from '../../../components/WeekPicker'
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

const Task = ({history,match}) => {
  const firestore = useFirestore()
  const profile = useSelector(state => state.firebase.profile)
  const firebase = useFirebase()
  const app = firebase.app();
  const functions = app.functions("europe-west3");
  const recursiveDelete = functions.httpsCallable('recursiveDelete');
  const myTask = 'myTask'+match.params.task
  useFirestoreConnect([
    {
      collection: `subjects`,
      doc: match.params.subject,
      subcollections: [{ 
        collection: "topics",doc: match.params.topic,
        subcollections:[{collection:"tasks",doc:match.params.task}] 
      }],
      storeAs: myTask
    }
  ])
  const taskData = useSelector(state => state.firestore.ordered[myTask])
  // const updateTaskImage = (taskId,path,filename) => {
  //   firestore.update(
  //     'subjects/'+match.params.subject+"/topics/"+match.params.topic+"/tasks/"+taskId,{
  //       arRefImage: path,
  //       arImageWidth:8
  //     }).then(() => {
  //       console.log("success")
  //     })
  //     .catch(err => {
  //       console.error('Error:', err) // eslint-disable-line no-console
        
  //     })
  // }

  // const updateModelUrl = (taskId,path,filename) => {
  //   firestore.update(
  //     'subjects/'+match.params.subject+"/topics/"+match.params.topic+"/tasks/"+taskId,{
  //       model : {
  //         url: path,
  //         filename: filename,
  //         timestamp:Date.now()
  //       }
  //     }).then(() => {
  //       console.log("success")
  //       changeUploadShown(false)
  //     })
  //     .catch(err => {
  //       console.error('Error:', err) // eslint-disable-line no-console
        
  //     })
  // }
  function editTask(instance){
    return firestore.update(
      'subjects/'+match.params.subject+"/topics/"+match.params.topic+"/tasks/"+taskData[0].id,{
        ...instance,
        subjectId:match.params.subject,
        topicId:match.params.topic
      }).then(() => {
        toggleDialogEdit()
      })
      .catch(err => {
        console.error('Error:', err) // eslint-disable-line no-console
        //showError(err.message || 'Could not add project')
        return Promise.reject(err)
      })
  }

  function deleteTask(){
    recursiveDelete({path:'subjects/'+match.params.subject+'/topics/'+match.params.topic+'/tasks/'+taskData[0].id,project:process.env.REACT_APP_FIREBASE_PROJECT_ID}).then(() => {
      firestore.update('subjects/'+match.params.subject+"/topics/"+match.params.topic, {
        taskCount:firebase.firestore.FieldValue.increment(-1)
      });
      history.push('/subjects/'+match.params.subject+"/topics/"+match.params.topic);
    });
  }

  function saveDate(data){
    firestore.update(
      'subjects/'+match.params.subject+"/topics/"+match.params.topic+"/tasks/"+taskData[0].id,{
        week:data
      }).then(() => {
        let dueDate = new Date(data[6])
        dueDate.setHours(23,59,59)
        firestore.update(
          'subjects/'+match.params.subject+"/topics/"+match.params.topic+"/tasks/"+taskData[0].id,{
            weekFilter:dueDate
          })
      })
      .catch(err => {
        console.error('Error:', err) // eslint-disable-line no-console
        
      })
  }

  const [showUpload,changeUploadShown] = useState(false) 

  const [newDialogOpen, changeDialogState] = useState(false)
  const toggleDialog = () => {
    changeDialogState(!newDialogOpen)
  }
  
  const [editDialogOpen, changeDialogStateEdit] = useState(false)
  const toggleDialogEdit = () => {
    changeDialogStateEdit(!editDialogOpen)
  }

  const [deleteDialogOpen, changeDialogStateDelete] = useState(false)
  const toggleDialogDelete = () => {
    changeDialogStateDelete(!deleteDialogOpen)
  }

  if(!isLoaded(taskData)){
    return <p>Loading...</p>
  }
  if(isEmpty(taskData)){
    return <Redirect to={{pathname: "/"}}></Redirect>
  }
  if((profile.role !== "admin") && taskData[0].publisherId !== profile.publisherId){
    return <Redirect to={{pathname: "/"}}></Redirect>
  }else{
    const task = taskData[0];
    let week = []
    if(task.week){
      week = task.week.map((date) => {
        return new Date(date.seconds * 1000)
      })
    }
   
    return(
      <div style={{height:"calc(100vh - 121px)"}}>
        <Content 
        leftHeadline={
          <div className="d-flex align-items-center justify-content-between">
          <Link to={`/subjects/${match.params.subject}/topics/${match.params.topic}`} className="d-flex align-items-center text-button"><MdArrowBack /><span className="ml-2">Seiten Details</span></Link>
          <Dropdown alignRight={true}>
            <Dropdown.Toggle className="icon-toggle" variant="light" id="dropdown-basic">
              <MdMoreHoriz />
            </Dropdown.Toggle>
            <Dropdown.Menu className="border-0 shadow">
              <Dropdown.Item  onClick={toggleDialogEdit} className="d-flex align-items-center text-button"><MdModeEdit /><span className="ml-2">Bearbeiten</span></Dropdown.Item>
              <Dropdown.Item onClick={toggleDialogDelete} className="d-flex align-items-center text-button"><MdDeleteForever /><span className="ml-2">Löschen</span></Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        }
        rightHeadline={
          <span className="d-flex align-items-center justify-content-between" onClick={toggleDialog}><span>Inhalte</span><MdAddCircleOutline size="1.5em" className="text-button text-primary"/></span>
        }
        left={
          <div>
            <div className="bg-white p-4 shadow-border">
            <h1>{task.title}</h1>
              <div className="d-flex mt-4 flex-wrap">
                  <div className="mr-5">
                    <p className="text-muted">Erstellt von</p>
                  <div className="d-flex align-items-center font-weight-medium small font-weight-bold" style={{height:35}}> <span className="bg-secondary text-white rounded-circle d-flex align-items-center justify-content-center mr-2" style={{width:23,height:23,overflow:'hidden',flexShrink:0}}><MdPerson size="3em" /></span> {task.creator.name}</div>
                  </div>
                  <div className="mr-4">
                    <p className="text-muted">Erstellt am</p>
                    <div className="d-flex align-items-center font-weight-medium small font-weight-bold" style={{height:35}}> <span className="bg-secondary text-white rounded-circle d-flex align-items-center justify-content-center mr-2" style={{width:23,height:23,overflow:'hidden',flexShrink:0}}><AiOutlineClockCircle size="3em" /></span> <Moment format="LL" date={task.created} /></div>
              
                  </div>
                </div>
            </div>
            <Row>
            <Col xl="6" >
                <div className="bg-white p-4 shadow-border mt-4">
                  <h5 className="mb-4">Aufgabe einplanen</h5>
                  <WeekPicker handleSubmit={saveDate} days={week}/>
                </div>
              
              </Col>
              <Col xl="6" >
                <div className="bg-white p-4 shadow-border mt-4">
                  <h5>Beschreibung</h5>
                  <p>{task.description}</p>
                </div>
              
              </Col>
             
            </Row>
          </div>
        } 
        right={
         <div>
            <TaskContent task={task} subjectId={match.params.subject} topicId={match.params.topic} /> 
         </div>
        }
        
        />
        <TaskEdit show={editDialogOpen} handleModalClose={toggleDialogEdit} handleSubmit={editTask} task={task} ></TaskEdit>
         <DeleteModal show={deleteDialogOpen} handleModalClose={toggleDialogDelete} handleSubmit={deleteTask}></DeleteModal>
         <TaskContentAdd show={newDialogOpen} handleModalClose={() => {toggleDialog()}} taskCount={task.taskCount} orderArray={task.contentOrder} contentPath={'subjects/'+match.params.subject+'/topics/'+match.params.topic+'/tasks/'+task.id} taskId={task.id} />
        </div>
    )
  }
}

export default Task
