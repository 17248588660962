
import React from 'react'
import { Switch, Route } from 'react-router-dom'
import ProtectedRoute from "../components/ProtectedRoute";
import BookList from "./books/BookList";
import SubjectList from "./teachers/subjects/SubjectList";
import Book from "./books/Book";
import Chapter from "./chapters/Chapter";
import Task from "./tasks/Task";
import Login from "./layout/Login";
import Registration from "./layout/Registration";
import MailConfirmation from "./layout/MailConfirmation";
import PasswordReset from "./layout/PasswordReset";
import Verify from "./layout/Verify";
import Profile from "./account/Profile";
import UserList from "./user/UserList";
import Feedback from "./feedback/Feedback";
import UseDataChartExample from "./dashboard/AnalyticsDataChart";
import FileBib from './bibliothek/FileBib';
import Subject from './teachers/subjects/Subject';
import Topic from './teachers/topics/Topic';
import {default as TTask} from './teachers/tasks/Task';
import AGB from './legally/AGB'
import Data from './legally/Data'
import DataApp from './legally/DataApp'
import Error from './Error'

let defaultComponent = BookList;
if(process.env.REACT_APP_ENV=== "teachers"){
  defaultComponent = SubjectList;
}

const Routes = () => (
    <Switch>
        <ProtectedRoute exact path="/" component={defaultComponent}  />
        <ProtectedRoute exact path="/dashboard" component={UseDataChartExample}  />
        <ProtectedRoute path="/books/:book/chapters/:chapter/tasks/:task" component={Task}  />
        <ProtectedRoute path="/books/:book/chapters/:chapter" component={Chapter}  />
        <ProtectedRoute path="/books/:book" component={Book} />
        <ProtectedRoute path="/users" component={UserList} />
        <ProtectedRoute path="/profile" component={Profile} />
        <ProtectedRoute path="/feedback" component={Feedback} />
        <ProtectedRoute path="/bibliothek" component={FileBib} />
        <ProtectedRoute path="/subjects/:subject/topics/:topic/tasks/:task" component={TTask}  />
        <ProtectedRoute path="/subjects/:subject/topics/:topic" component={Topic}  />
        <ProtectedRoute path="/subjects/:subject" component={Subject}  />
        <ProtectedRoute path="/subjects" component={SubjectList} />
        <Route path="/registration/:user" render={(props)=> <Registration  {...props} />} />
        <Route path="/inactive" render={(props)=> <MailConfirmation  {...props} />} />
        <Route path="/recovery" render={(props)=> <PasswordReset  {...props} />} />
        <Route path="/verify" render={(props)=> <Verify  {...props} />} />
        <Route path="/agb" render={()=> <AGB />} />
        <Route path="/datenschutz" render={()=> <Data />} />
        <Route path="/datenschutz-app" render={()=> <DataApp />} />
        <Route path="/login" render={()=> <Login />} />
        <Route path="/error" render={()=> <Error />} />
        <Route render={()=> <Login />}  /> 
    </Switch>
  )
  
export default Routes
  