import React from "react";
import customer from 'theme/customer';
import { useSelector } from 'react-redux';
import {NavLink} from 'react-router-dom'
import {routes} from '../../helpers/routeRoles'
import useScreenSize from '../../helpers/useScreenSize'
import { MdClose } from 'react-icons/md';

const sidebarStyle = {
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    zIndex: 100,
    width:260,
}
const brandName = {
  height:61,
}




const Sidebar = ({showSidebar,setShowSidebar}) => {
  const profile = useSelector(state => state.firebase.profile)
  const {isSmaller} = useScreenSize(768);

  return(
    <div>
      <div className={`${(showSidebar && isSmaller) ? "d-block" : "d-none"}`} style={{position:"absolute",zIndex:99,background:"rgb(152 168 181 / 35%)",width:"100vw",height:"100vh"}}></div>
      <div onClick={() =>  setShowSidebar(false)} className={`${(showSidebar && isSmaller) ? "d-flex" : "d-none"} text-button align-items-center justify-content-center closeIcon`}  >
        <MdClose size="1.2em"/>
      </div>
      <nav className={`${showSidebar ? "d-block" : "d-none"} d-md-block bg-white sidebar border-right`} style={sidebarStyle}>
      <div className="sidebar-sticky">
        <div className="d-flex align-items-center justify-content-center text-primary" style={brandName}>
          <h4 className="mb-0 font-weight-normal">{customer.name} <b>LMS</b></h4>
        </div>
        <ul className="nav flex-column list-group">
          {
            routes.flatMap((route) => {
              if(route.roles.includes(profile.role)){
                return(
                    <li className="nav-item" key={route.name}>
                      <NavLink to={route.link} exact={true} isActive={route.isActive} activeClassName='active-nav-link' className="nav-link text-uppercase text-muted text-body list-group-item border-0 list-group-item-action py-3 " >
                        <span className="d-inline-block nav-icon" style={{width:25}}>{route.icon}</span>
                        <span className="small ml-3">{route.name}</span>
                      </NavLink>
                  </li>
                )
              }
            })
          }
      
        </ul>
      </div>
    </nav>
    </div>
  )
}

export default Sidebar;