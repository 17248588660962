import React,{useState} from 'react'
import {Form,Button, Modal} from 'react-bootstrap'
import { useSelector } from 'react-redux';

const SubjectForm = ({handleSubmit,subject,icons}) => {
  const profile = useSelector(state => state.firebase.profile)
  const auth = useSelector(state => state.firebase.auth)
  const [activeIcon,setActiveIcon] = useState(0)
  if(subject && !activeIcon){
    setActiveIcon(subject.image)
  }

    const onSubmit = event => {
        event.preventDefault()
        const {title,description,className} = event.target.elements
        let values = {
          title: title.value,
          className: className.value,
          description: description.value,
          image:activeIcon,
        }
        if(!subject){
          values = {
              title: title.value,
              className: className.value,
              description: description.value,
              image:activeIcon,
              created:Date.now(),
              creator:{id:auth.uid,name:profile.displayName},
              publisherId:profile.publisherId
          }
        }
        handleSubmit(values)
    }

    return (
      <Form onSubmit={onSubmit}>
      <Modal.Body>
      
        <Form.Group>
          <Form.Label >Name</Form.Label>
          <Form.Control type="text" name="title" defaultValue={subject ? subject.title : ""} placeholder="Name des Faches" required />
        </Form.Group>

        <Form.Group>
          <Form.Label >Klasse</Form.Label>
          <Form.Control type="text" name="className" defaultValue={subject ? subject.className : ""} placeholder="Name der Klasse" />
        </Form.Group>

        <Form.Group>
          <Form.Label >Beschreibung</Form.Label>
          <Form.Control as="textarea" rows="3"  name="description" placeholder="Was erwartet die Schüler:innen" defaultValue={subject ? subject.description : ""}  required />
        </Form.Group>
        <Form.Label >Icon auswählen</Form.Label>
        <div className="d-flex flex-wrap">
        {
            icons.map((icon) => {
                return(
                    <div key={icon.path} className={`p-3 m-1 text-button icon-choose rounded ${activeIcon === icon.path ? 'icon-choose-active' : ''}`} onClick={() => setActiveIcon(icon.path) } >
                        <div style={{width:"45px",height:"45px",backgroundImage:`url(${icon.path})`,backgroundSize:'contain',backgroundRepeat:'no-repeat'}}>
                        </div>
                    </div>
                )
            })
        }
        </div>
           

      </Modal.Body>
      <Modal.Footer>
      <Button variant="primary" type="submit">Speichern</Button>
      </Modal.Footer>
      </Form>
    )
}

export default SubjectForm
