import React from 'react'
import {Form,Button,Modal} from 'react-bootstrap'
import { useSelector } from 'react-redux';

const TaskForm = ({handleSubmit,task}) => {
  const auth = useSelector(state => state.firebase.auth)
  const profile = useSelector(state => state.firebase.profile)
  const onSubmit = event => {
    event.preventDefault()
    const {title,page,description,type} = event.target.elements
      let values = {
        title: title.value,
        description:description.value,
        page:parseInt(page.value),
        type:type.options[type.options.selectedIndex].value,
      }
      if(!task){
         values = {
          title: title.value,
          description:description.value,
          page:parseInt(page.value),
          type:type.options[type.options.selectedIndex].value,
          created:Date.now(),
          creator:{id:auth.uid,name:profile.displayName},
          taskCount:0
        }
      }
      handleSubmit(values);
  }
  return (
    <Form onSubmit={onSubmit}>
      <Modal.Body>
      <Form.Group>
        <Form.Label >Titel</Form.Label>
        <Form.Control type="text" name="title" defaultValue={task ? task.title : ""}  required placeholder="Überschrift der Seite" />
      </Form.Group>

      <Form.Group>
        <Form.Label >Seite</Form.Label>
        <Form.Control type="number" name="page"defaultValue={task ? task.page : ""}required  placeholder="Seitenzahl"/>
      </Form.Group>

      <Form.Group>
        <Form.Label >Beschreibung</Form.Label>
        <Form.Control type="text" name="description" defaultValue={task ? task.description : ""}  required placeholder="Was erwartet den Nutzer auf dieser Seite?" />
      </Form.Group>

      <Form.Group>
        <Form.Label>Art der Erweiterung</Form.Label>
        <Form.Control as="select" name="type" >
          <option value="basic">Neben dem Buch</option>
          <option value="ar">Über dem Buch</option>
        </Form.Control>
      </Form.Group>

      </Modal.Body>
      <Modal.Footer>
      <Button variant="primary" type="submit">Speichern</Button>
      </Modal.Footer>
    </Form>
  )
}

export default TaskForm
