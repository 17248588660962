import React from "react";
import { MdDashboard,MdBook,MdChat } from 'react-icons/md';
import {FaUsers } from 'react-icons/fa';
import {GiFiles } from 'react-icons/gi';

export const routes = [
    {
      icon:<MdDashboard size="1.3em" />,
      name: "Dashboard",
      link:"/dashboard",
      roles:["creator","admin"]
    },
    {
      icon:<MdBook size="1.3em" />,
      name: "Bücher",
      link:"/books",
      roles:["creator","admin"],
      isActive: (match, location) => {
        if(location.pathname === "/"){
          return true;
        }
        if(location.pathname.indexOf("/books") !== -1){
          return true;
        }
        return false;
        }
    },
    {
      icon:<MdBook size="1.3em" />,
      name: "Fächer",
      link:"/subjects",
      roles:["teacher","adminTeacher"],
      isActive: (match, location) => {
        if(location.pathname === "/"){
          return true;
        }
        if(location.pathname.indexOf("/subjects") !== -1){
          return true;
        }
        return false;
        }
    },
    {
      icon:<FaUsers size="1.3em" />,
      name: "Benutzer",
      link:"/users",
      roles:["admin","adminTeacher"]
    },
    {
      icon:<GiFiles size="1.3em" />,
      name: "Bibliothek",
      link:"/bibliothek",
      roles:["admin","adminTeacher","creator","admin","teacher"]
    },
    {
      icon:<MdChat size="1.3em" />,
      name: "Feedback",
      link:"/feedback",
      roles:["admin","creator"]
    },
  
  ]