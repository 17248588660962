import React,{useState} from 'react';
import {Modal,Form,Button} from 'react-bootstrap'
import QuizContent from '../content/QuizContent'
import ImageContent from '../content/ImageContent'
import {
    useFirestore,
  } from 'react-redux-firebase'
  import RichTextEditor from 'react-rte';
import VideoContent from '../content/VideoContent';
import AudioContent from '../content/AudioContent';
import MemoryContent from '../content/MemoryContent';
import PDFContent from '../content/PDFContent';
import BlanksContent from '../content/BlanksContent';
import { MdFullscreen,MdFullscreenExit } from 'react-icons/md';

const TaskContentModal = ({show,handleModalClose,contentPath,content,taskId}) => {
    const firestore = useFirestore()
    const editContent = (event) => {
        event.preventDefault();
        firestore.update(
            contentPath+"/contents/"+content.id,{
                text:editorState.toString('markdown'),
            }).then(() => {
                handleModalClose(true);
            })
            .catch(err => {
                console.error('Error:', err)
                return Promise.reject(err)
            })
    }
    const editImageContent = (values) => {
        firestore.update(
            contentPath+"/contents/"+content.id,{
                ...values,
                updated:Date.now()
            }).then(() => {
                handleModalClose(true);
            })
            .catch(err => {
                console.error('Error:', err)
                return Promise.reject(err)
            })
    }
    const editVideoContent = (values) => {
        firestore.update(
            contentPath+"/contents/"+content.id,{
                ...values,
                updated:Date.now()
            }).then(() => {
                handleModalClose(true);
            })
            .catch(err => {
                console.error('Error:', err)
                return Promise.reject(err)
            })
    }
    const editPdfContent = (values) => {
        firestore.update(
            contentPath+"/contents/"+content.id,{
                ...values,
                updated:Date.now()
            }).then(() => {
                handleModalClose(true);
            })
            .catch(err => {
                console.error('Error:', err)
                return Promise.reject(err)
            })
    }
    const [editorState, setEditorState] = React.useState(
        RichTextEditor.createValueFromString(content.text,"markdown"),
      );
      const toolbarConfig = {
        // Optionally specify the groups to display (displayed in the order listed).
        display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
        INLINE_STYLE_BUTTONS: [
          {label: 'Fett', style: 'BOLD'},
          {label: 'Kursiv', style: 'ITALIC'},
        ],
        BLOCK_TYPE_DROPDOWN: [
          {label: 'Normal', style: 'unstyled'},
          {label: 'Überschrift Groß', style: 'header-one'},
          {label: 'Überschrift Medium', style: 'header-two'},
          {label: 'Überschrift Klein', style: 'header-three'}
        ],
        BLOCK_TYPE_BUTTONS: [
          {label: 'UL', style: 'unordered-list-item'},
          {label: 'OL', style: 'ordered-list-item'}
        ]
      };
    const [modeEdit, setModeEdit] = useState(true);
    const [isFullscreen, setFullscreen] = useState(false)
    return(
        <Modal show={show} backdrop="static" onHide={() => {handleModalClose(false);}} className={isFullscreen ? "modal-fullscreen" : ""}>
            <Modal.Header closeButton>
                <div className="d-flex align-items-center justify-content-between w-100">
                    <Modal.Title>Inhalt bearbeiten</Modal.Title>
                    {isFullscreen && <MdFullscreenExit className="fullscreen-icon" size="1.6em" onClick={() => setFullscreen(!isFullscreen)}/>}
                    {!isFullscreen && <MdFullscreen className="fullscreen-icon" size="1.6em" onClick={() => setFullscreen(!isFullscreen)}/>}
                </div>
            </Modal.Header>
            <div className="d-flex px-3 py-3" style={{backgroundColor:"rgb(226, 226, 226)"}}>
                <div className="d-flex align-items-center mr-2">
                    <div className="d-flex align-items-center text-primary">
                        <span className="ml-2 text-uppercase  text-small">{content.type}</span>
                    </div>
                </div>
            </div>
            <Modal.Body className="bg-light p-0">
            {content.type === "Textblock" &&
                <div>
                     <Form onSubmit={editContent} > 
                        <Form.Group style={{minHeight:200}} className="p-3">
                        <RichTextEditor 
                            value={editorState}
                            onChange={setEditorState}
                            rows="8"
                            placeholder="Seiteninhalt"
                            toolbarConfig={toolbarConfig}
                        />
                        </Form.Group>   
                    
                        <div className="p-3 bg-white d-flex justify-content-end">
                            <Button variant="primary" type="submit">Speichern</Button>
                        </div>
                    </Form>
                </div>
            }
            {content.type === "Quiz" && <QuizContent quiz={content.quizId} handleSubmit={editImageContent}/>}
            {content.type === "Memory" && <MemoryContent dbQuiz={content.quizId} handleSubmit={editImageContent}/>}
            {content.type === "Lückentext" && <BlanksContent dbQuiz={content.quizId} handleSubmit={editImageContent}/>}
            {content.type === "Image" && <ImageContent handleSubmit={editImageContent} taskId={taskId} image={content.path} format={content.format} modeEdit={modeEdit} changeMode={setModeEdit}/>}
            {content.type === "Video" && <VideoContent handleSubmit={editVideoContent} taskId={taskId} video={content} modeEdit={modeEdit} changeMode={setModeEdit}/>}
            {content.type === "Audio" && <AudioContent handleSubmit={editPdfContent} taskId={taskId} audio={content} modeEdit={modeEdit} changeMode={setModeEdit}/>}
            {content.type === "Pdf" && <PDFContent handleSubmit={editPdfContent} taskId={taskId} pdf={content} modeEdit={modeEdit} changeMode={setModeEdit}/>}

            </Modal.Body>
        </Modal>
    )

}
export default TaskContentModal;