import React from 'react';
import error_img from 'theme/404.svg'

const Error = () => {
    return(
        <div className="d-flex align-items-center justify-content-center flex-column" style={{height:"100vh"}}>
            <img alt="Email Sent" src={error_img} style={{width:"40%"}}/>
        </div>
    )
};

export default Error;