import React, {useMemo, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import {uploadFile} from '../helpers/upload'
import { useFirebase,useFirestore } from 'react-redux-firebase';
import { useSelector } from 'react-redux';

const baseStyle = {
  flex: 1,
  display: 'flex',
  width:"100%",
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

function ImageDropzone(props) {
 const [files, setFiles] = useState([]);
 const {handleUploadSuccess,refId,folder} = props 
 const firebase = useFirebase()
 const profile = useSelector(state => state.firebase.profile)
 const firestore = useFirestore()
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
      accept: 'image/*',
      multiple:false,
      onDrop: acceptedFiles => {
        setFiles(acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
          })));
          uploadFile(firebase,acceptedFiles[0],folder)
          .then( (path) => {
            firestore.add('files', {
              path:path,
              type:"ArRefImage",
              name:acceptedFiles[0].name,
              publisherId:profile.publisherId
            })
            handleUploadSuccess(refId,path,acceptedFiles[0].name)
          })
      }
    });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);

    return (
        <div {...getRootProps({style})}>
            <input {...getInputProps()} />
            <p>Datei einfach per Drag 'n' drop ablegen, oder Klicken um eine Datei auszuwählen</p>
            <em>(Nur *.jpeg und *.png Bilder werden akzeptiert)</em>
        </div>
    );
}

export default ImageDropzone