import React, { useState } from "react";
import "react-image-crop/dist/ReactCrop.css";

const ImageCropper = ({setCroppedImage,image}) => {
  const [upImg, setUpImg] = useState();
  const [isAnimated,setIsAnimated] = useState(false);

  const onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      if(e.target.files[0].type === "image/gif"){
        reader.addEventListener("load", () => setIsAnimated(reader.result));
        reader.readAsDataURL(e.target.files[0]);
        setCroppedImage(e.target.files[0]);
      }else{
        setIsAnimated(false);
        reader.addEventListener("load", () => setUpImg(reader.result));
        reader.readAsDataURL(e.target.files[0]);
        setCroppedImage(e.target.files[0]);
      }
    }
  };


  return (
    <div>
      <div>
        <input type="file" required accept="image/*" onChange={onSelectFile}/>
      </div>
       <div className="d-flex align-items-start mt-3">
       {/*{!isAnimated && <ReactCrop
            src={upImg}
            onImageLoaded={onLoad}
            crop={crop}
            onChange={c => setCrop(c)}
            onComplete={makeClientCrop}
            style={{maxHeight:300}}
        />} */}
        <img alt="Bildvorschau" src={isAnimated ? isAnimated : upImg} style={{maxHeight:300,maxWidth:"100%"}}  />
      </div>
    </div>
  );
}
export default ImageCropper;