import React, {useState} from 'react'
import {Image} from 'react-bootstrap'
import {MdDeleteForever} from 'react-icons/md';
import DeleteModal from '../views/helpers/DeleteModal';

const ImagePreview = ({image,handleImageDelete,showDeleteQuestion}) => {

    const [deleteDialogOpen, changeDialogStateDelete] = useState(false)
    const toggleDialogDelete = () => {
      changeDialogStateDelete(!deleteDialogOpen)
    }

    const deleteImage = () => {
        if(showDeleteQuestion){
            handleImageDelete().then(() => {
                toggleDialogDelete();
            })
        }else{
            handleImageDelete()
        }
       
    }

    const onClickHandler = () => {
        if(showDeleteQuestion){
            toggleDialogDelete();
        }else{
            deleteImage()
        }
    }

    const [showHover, changeShowHover] = useState(false)

    return(
        <div style={{position:"relative"}}  onMouseEnter={() => {changeShowHover(true)}} onMouseLeave={() => {changeShowHover(false)}}>
            <Image src={image} rounded className="w-100" />
            <div className="imagehover" style={{position:"absolute",top:0,left:0,width:"100%",height:"100%",background:"#00000080",display: showHover ?  'block' : 'none'}}>
            <div onClick={onClickHandler}  className="d-flex align-items-center justify-content-center w-100 h-100 text-button" >
                <MdDeleteForever  size="2em" color="white"/>
            </div>
            </div>
            <DeleteModal show={deleteDialogOpen} handleModalClose={toggleDialogDelete} handleSubmit={deleteImage} ></DeleteModal>
        </div>
    )
};

export default ImagePreview;