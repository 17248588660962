import React, { useState } from "react";
import Content from "../layout/Content";
import { useSelector } from "react-redux";
import ImageDropzone from "../../components/ImageDropzone";
import FileDropzone from "../../components/3DFileDropzone";
import ImagePreview from "../../components/ImagePreview";
import { Dropdown } from "react-bootstrap";
import TaskEdit from "./TaskEdit";
import DeleteModal from "../helpers/DeleteModal";
import { Row, Col } from "react-bootstrap";
import {
  isLoaded,
  isEmpty,
  useFirestoreConnect,
  useFirestore,
  useFirebase,
} from "react-redux-firebase";
import {
  MdArrowBack,
  MdMoreHoriz,
  MdModeEdit,
  MdDeleteForever,
  MdPerson,
  MdAddCircleOutline,
} from "react-icons/md";
import TaskContent from "./TaskContent";
import TaskContentAdd from "./TaskContentAdd";
import Moment from "react-moment";
import { AiOutlineClockCircle } from "react-icons/ai";
import { Link } from "react-router-dom";

const Task = ({ history, match }) => {
  const firestore = useFirestore();
  const firebase = useFirebase();
  const app = firebase.app();
  const functions = app.functions("europe-west3");
  const recursiveDelete = functions.httpsCallable("recursiveDelete");
  const myTask = "myTask" + match.params.task;
  useFirestoreConnect([
    {
      collection: `books`,
      doc: match.params.book,
      subcollections: [
        {
          collection: "chapters",
          doc: match.params.chapter,
          subcollections: [{ collection: "tasks", doc: match.params.task }],
        },
      ],
      storeAs: myTask,
    },
  ]);
  const taskData = useSelector((state) => state.firestore.ordered[myTask]);
  const updateTaskImage = (taskId, path, filename) => {
    firestore
      .update(
        "books/" +
          match.params.book +
          "/chapters/" +
          match.params.chapter +
          "/tasks/" +
          taskId,
        {
          arRefImage: path,
          arImageWidth: 8,
          imageUpdated: firestore.Timestamp.fromDate(new Date()),
        }
      )
      .then(() => {})
      .catch((err) => {
        console.error("Error:", err); // eslint-disable-line no-console
      });
  };

  const updateModelUrl = (taskId, path, filename) => {
    firestore
      .update(
        "books/" +
          match.params.book +
          "/chapters/" +
          match.params.chapter +
          "/tasks/" +
          taskId,
        {
          model: {
            url: path,
            filename: filename,
            timestamp: Date.now(),
          },
        }
      )
      .then(() => {
        changeUploadShown(false);
      })
      .catch((err) => {
        console.error("Error:", err); // eslint-disable-line no-console
      });
  };
  function editTask(instance) {
    return firestore
      .update(
        "books/" +
          match.params.book +
          "/chapters/" +
          match.params.chapter +
          "/tasks/" +
          taskData[0].id,
        {
          ...instance,
          bookId: match.params.book,
          chapterId: match.params.chapter,
        }
      )
      .then(() => {
        toggleDialogEdit();
      })
      .catch((err) => {
        console.error("Error:", err); // eslint-disable-line no-console
        //showError(err.message || 'Could not add project')
        return Promise.reject(err);
      });
  }

  function deleteTask() {
    recursiveDelete({
      path:
        "books/" +
        match.params.book +
        "/chapters/" +
        match.params.chapter +
        "/tasks/" +
        taskData[0].id,
      project: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    }).then(() => {
      firestore.update(
        "books/" + match.params.book + "/chapters/" + match.params.chapter,
        {
          pageCount: firebase.firestore.FieldValue.increment(-1),
        }
      );
      history.push(
        "/books/" + match.params.book + "/chapters/" + match.params.chapter
      );
    });
  }

  function deleteTaskImage() {
    return firestore.update(
      "books/" +
        match.params.book +
        "/chapters/" +
        match.params.chapter +
        "/tasks/" +
        taskData[0].id,
      {
        arRefImage: null,
      }
    );
  }
  const [showUpload, changeUploadShown] = useState(false);

  const [newDialogOpen, changeDialogState] = useState(false);
  const toggleDialog = () => {
    changeDialogState(!newDialogOpen);
  };

  const [editDialogOpen, changeDialogStateEdit] = useState(false);
  const toggleDialogEdit = () => {
    changeDialogStateEdit(!editDialogOpen);
  };

  const [deleteDialogOpen, changeDialogStateDelete] = useState(false);
  const toggleDialogDelete = () => {
    changeDialogStateDelete(!deleteDialogOpen);
  };

  if (!isLoaded(taskData)) {
    return <p>Loading...</p>;
  } else if (isEmpty(taskData)) {
    return <div></div>;
  } else {
    const task = taskData[0];
    return (
      <div style={{ height: "calc(100vh - 121px)" }}>
        <Content
          leftHeadline={
            <div className="d-flex align-items-center justify-content-between">
              <Link
                to={`/books/${match.params.book}/chapters/${match.params.chapter}`}
                className="d-flex align-items-center"
              >
                <MdArrowBack />
                <span className="ml-2">Seiten Details</span>
              </Link>
              <Dropdown alignRight={true}>
                <Dropdown.Toggle
                  className="icon-toggle"
                  variant="light"
                  id="dropdown-basic"
                >
                  <MdMoreHoriz />
                </Dropdown.Toggle>
                <Dropdown.Menu className="border-0 shadow">
                  <Dropdown.Item
                    onClick={toggleDialogEdit}
                    className="d-flex align-items-center text-button"
                  >
                    <MdModeEdit />
                    <span className="ml-2">Bearbeiten</span>
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={toggleDialogDelete}
                    className="d-flex align-items-center text-button"
                  >
                    <MdDeleteForever />
                    <span className="ml-2">Löschen</span>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          }
          rightHeadline={
            <span
              className="d-flex align-items-center justify-content-between text-button text-primary"
              onClick={toggleDialog}
            >
              <span>Inhalte</span>
              <MdAddCircleOutline size="1.5em" />
            </span>
          }
          left={
            <div>
              <div className="bg-white p-4 shadow-border">
                <h1>{task.title}</h1>
                <div className="d-flex mt-4 flex-wrap">
                  <div className="mr-5">
                    <p className="text-muted">Erstellt von</p>
                    <div
                      className="d-flex align-items-center font-weight-medium small font-weight-bold"
                      style={{ height: 35 }}
                    >
                      {" "}
                      <span
                        className="bg-secondary text-white rounded-circle d-flex align-items-center justify-content-center mr-2"
                        style={{
                          width: 23,
                          height: 23,
                          overflow: "hidden",
                          flexShrink: 0,
                        }}
                      >
                        <MdPerson size="3em" />
                      </span>{" "}
                      {task.creator.name}
                    </div>
                  </div>
                  <div className="mr-4">
                    <p className="text-muted">Erstellt am</p>
                    <div
                      className="d-flex align-items-center font-weight-medium small font-weight-bold"
                      style={{ height: 35 }}
                    >
                      {" "}
                      <span
                        className="bg-secondary text-white rounded-circle d-flex align-items-center justify-content-center mr-2"
                        style={{
                          width: 23,
                          height: 23,
                          overflow: "hidden",
                          flexShrink: 0,
                        }}
                      >
                        <AiOutlineClockCircle size="3em" />
                      </span>{" "}
                      <Moment format="LL" date={task.created} />
                    </div>
                  </div>
                </div>
              </div>
              <Row>
                <Col md="6" lg="5">
                  <div className="bg-white p-4 shadow-border mt-4">
                    <h5>Bilderkennung</h5>
                    {task.arRefImage ? (
                      <ImagePreview
                        image={task.arRefImage}
                        handleImageDelete={deleteTaskImage}
                        showDeleteQuestion={true}
                      />
                    ) : (
                      <ImageDropzone
                        handleUploadSuccess={updateTaskImage}
                        refId={task.id}
                        folder={"ar-reference/" + task.id + "/"}
                      />
                    )}
                  </div>
                </Col>

                <Col md="12" lg="7">
                  <div className="bg-white p-4 shadow-border mt-4">
                    <h5>Beschreibung</h5>
                    <p>{task.description}</p>
                  </div>
                  {task.type === "ar" && !task.isPremiumTask ? (
                    <div className="bg-white p-4 shadow-border mt-4">
                      <h5>3D Modell Upload</h5>
                      {!showUpload && task.model ? (
                        <div className="d-flex align-items-center justify-content-between ">
                          <span>{task.model.filename}</span>
                          <MdDeleteForever
                            onClick={() => changeUploadShown(true)}
                            className="text-button"
                          />
                        </div>
                      ) : (
                        <FileDropzone
                          handleUploadSuccess={updateModelUrl}
                          refId={task.id}
                        />
                      )}
                    </div>
                  ) : (
                    <span></span>
                  )}
                  {task.type === "ar" && task.isPremiumTask ? (
                    <div className="bg-white p-4 shadow-border mt-4">
                      <h5>3D Modell</h5>
                      <p>
                        Ihre Interaktive AR Aufgabe wurde vom thea Team in der
                        App bereitgestellt.
                      </p>
                    </div>
                  ) : (
                    <span></span>
                  )}
                </Col>
              </Row>
            </div>
          }
          right={
            <div>
              <TaskContent
                task={task}
                bookId={match.params.book}
                chapterId={match.params.chapter}
              />
            </div>
          }
        />
        <TaskEdit
          show={editDialogOpen}
          handleModalClose={toggleDialogEdit}
          handleSubmit={editTask}
          task={task}
        ></TaskEdit>
        <DeleteModal
          show={deleteDialogOpen}
          handleModalClose={toggleDialogDelete}
          handleSubmit={deleteTask}
        ></DeleteModal>
        <TaskContentAdd
          show={newDialogOpen}
          handleModalClose={() => {
            toggleDialog();
          }}
          taskCount={task.taskCount}
          orderArray={task.contentOrder}
          contentPath={
            "books/" +
            match.params.book +
            "/chapters/" +
            match.params.chapter +
            "/tasks/" +
            task.id
          }
          taskId={task.id}
        />
      </div>
    );
  }
};

export default Task;
