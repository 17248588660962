import React,{useState} from 'react';
import {Button, Form} from 'react-bootstrap'
import FileDropzone from '../../components/FileDropzone';
import { MdDeleteForever } from 'react-icons/md';

const AudioContent = ({handleSubmit,audio,modeEdit,changeMode,taskId }) =>  {
    const saveAudioContent = (event) => {
        event.preventDefault();
        if(uploadedAudio){
            const {title} = event.target.elements
            handleSubmit({
                path:uploadedAudio.path,
                type:"Audio",
                text:title.value,
                filename:uploadedAudio.name
            })
        }
    }
    const [uploadedAudio, setUploadedAudio] = useState();
    if(modeEdit && !uploadedAudio){
        setUploadedAudio({
            name:audio.filename,
            path:audio.path
        })
    }
    const changeModes = () => {
        if(changeMode){
            changeMode(false)
        }
        setUploadedAudio(false);
    }
    return  (
        <div className="modal-body-container">
            <Form onSubmit={saveAudioContent} className="modal-body-container">
                 <div className="p-4">
                 <Form.Group>
                        <Form.Label >Titel</Form.Label>
                        <Form.Control type="text" name="title" defaultValue={audio ? audio.text : ""}  required placeholder="Titel der Audio Datei" />
                    </Form.Group>
                    {!uploadedAudio && <FileDropzone handleUploadSuccess={(refId,path,filename) => setUploadedAudio({name:filename,path:path})} refId={taskId} maxSize={10485760} acceptedFileTypes="audio/*" type="Audio" pathType="task-audios/" description="(Nur *.mp3 Dateien werden akzeptiert, max. 10Mb)"  />}
                    {uploadedAudio && 
                       <div  className="d-flex align-items-center justify-content-between ">
                       <span>{uploadedAudio.name}</span>
                       <MdDeleteForever onClick={changeModes} className="text-button"/>
                     </div>}
                </div>
                <div className="p-3 bg-white d-flex justify-content-end">
                    <Button disabled={uploadedAudio ? false : true} variant="primary" type="submit" >Speichern</Button>
                </div>
            </Form>
        </div>
    )
}

export default AudioContent;