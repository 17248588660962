import React, {useState} from 'react'
import { useSelector } from 'react-redux';
import { MdAddCircleOutline } from 'react-icons/md';
import { Row,Col } from 'react-bootstrap';
import FeedbackNew from './FeedbackNew';
import FeedbackShow from './FeedbackShow';
import Moment from 'react-moment';
import {
    useFirestoreConnect,
    useFirestore,
    isLoaded,
  } from 'react-redux-firebase'
  import { MdArrowForward,MdArrowBack } from 'react-icons/md';

const Feedback = () => {
    const profile = useSelector(state => state.firebase.profile)
    const firestore = useFirestore()
    const [newDialogOpen, changeDialogState] = useState(false)
    const [selectedFeedback, setSelectedFeedback] = useState(false)
    const toggleDialog = () => {
      changeDialogState(!newDialogOpen)
    }
    useFirestoreConnect([
        {
          collection: 'feedback',
          orderBy:['created','desc']
        }
      ])
    const feedback = useSelector(state => state.firestore.ordered.feedback)

    const handleOpenClick = (feedback) => {
        setSelectedFeedback(feedback)
    }

    const changeFeedbackStatus = (e,feedback,newStatus) => {
        e.stopPropagation();
        return firestore.update('feedback/'+feedback.id,{
            status:newStatus
        })
    } 
    function addFeedback(newInstance) {
        return firestore
          .add('feedback', {
            ...newInstance,
            status:"todo"
          })
          .then(() => {
            toggleDialog()
            firestore.add('mails',{
                from: process.env.REACT_APP_SITE_TITLE+' <info@thea-ar.de>',
                to: ['marius.lindenmeier@thea-ar.de','info@thea-ar.de'],
                message: {
                  subject: 'Neues Feedback',
                  text: 'Neues Feedback von '+profile.displayName+' vom Verlag '+profile.publisherName+'<br><br><strong>'+newInstance.topic+'</strong><br><br>'+newInstance.description ,
                  html: 'Neues Feedback von '+profile.displayName+' vom Verlag '+profile.publisherName+'<br><br><strong>'+newInstance.topic+'</strong><br><br>'+newInstance.description ,
                } 
            })
          })
          .catch(err => {
            console.error('Error:', err)
            return Promise.reject(err)
          })
      }
    return(
        <div>
            <FeedbackNew  show={newDialogOpen} handleSubmit={addFeedback} handleModalClose={() => {toggleDialog()}}/>
            <FeedbackShow show={selectedFeedback} handleModalClose={() => setSelectedFeedback(false)} feedback={selectedFeedback}/>
            <Row>
                    <Col>
                    <div className="d-flex justify-content-end">
                      <span className="d-flex align-items-center text-primary" style={{cursor:"pointer"}} onClick={toggleDialog} ><MdAddCircleOutline className="mr-2"/>Neues Feedback melden</span>
                    </div>
                    </Col>
                </Row>
            <div style={{position:"relative"}} className="mt-4">
                {
                    isLoaded(feedback) &&
                     <div className="d-flex">
                        <div style={{width:300}} className="pr-5">
                            <div className="w-100 bg-white p-3 mb-4  d-flex justify-content-between align-items-center" style={{borderTop:"2px solid #a4b0be"}}>
                                <h6 className="text-uppercase mb-0">Todo </h6><span className="badge badge-secondary">{feedback.filter(feedback => feedback.status === "todo").length}</span>
                            </div>
                            <div className="d-flex flex-column">
                                {feedback.filter(feedback => feedback.status === "todo").map(feedback => (
                                    <div className="p-3 bg-white w-100 rounded mb-2 text-button shadow-border" onClick={() => {handleOpenClick(feedback)}}>
                                        <h6>{feedback.topic}</h6>
                                        
                                        <div className="d-flex justify-content-between">
                                            <Moment className="text-muted" format="DD.MM.YYYY" date={feedback.created} />
                                            {
                                            profile.isSuperAdmin
                                            && <div className="d-flex">
                                                    <span onClick={e => changeFeedbackStatus(e,feedback,"progress")} className="badge badge-secondary d-flex align-items-center ml-1 text-button"><MdArrowForward /></span>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div style={{width:300}} className="pr-5">
                            <div className="w-100 bg-white p-3 mb-4  d-flex justify-content-between align-items-center" style={{borderTop:"2px solid #ff7f50"}}>
                                <h6 className="text-uppercase mb-0">in Bearbeitung </h6><span className="badge badge-secondary">{feedback.filter(feedback => feedback.status === "progress").length}</span>
                            </div>
                            <div className="d-flex flex-column">
                                {feedback.filter(feedback => feedback.status === "progress").map(feedback => (
                                    <div className="p-3 bg-white w-100 rounded mb-2 text-button shadow-border" onClick={() => {setSelectedFeedback(feedback)}}>
                                        <h6>{feedback.topic}</h6>
                                        <div className="d-flex justify-content-between">
                                            <Moment className="text-muted" format="DD.MM.YYYY" date={feedback.created} />
                                            {
                                            profile.isSuperAdmin
                                            && <div className="d-flex">
                                                    <span onClick={e => changeFeedbackStatus(e,feedback,"todo")} className="badge badge-secondary d-flex align-items-center text-button mr-1"><MdArrowBack /></span>
                                                    <span onClick={e => changeFeedbackStatus(e,feedback,"done")} className="badge badge-secondary d-flex align-items-center text-button"><MdArrowForward /></span>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div style={{width:300}}>
                            <div className="w-100 bg-white p-3 mb-4  d-flex justify-content-between align-items-center" style={{borderTop:"2px solid #2ed573"}}>
                                <h6 className="text-uppercase mb-0">Erledigt </h6><span className="badge badge-secondary">{feedback.filter(feedback => feedback.status === "done").length}</span>
                            </div>
                            <div className="d-flex flex-column">
                                {feedback.filter(feedback => feedback.status === "done").map(feedback => (
                                    <div className="p-3 bg-white w-100 rounded mb-2 text-button shadow-border" onClick={() => {setSelectedFeedback(feedback)}}>
                                        <h6>{feedback.topic}</h6>
                                        <div className="d-flex justify-content-between">
                                            <Moment className="text-muted" format="DD.MM.YYYY" date={feedback.created} />
                                            {
                                            profile.isSuperAdmin
                                            && <div className="d-flex">
                                                    <span onClick={e => changeFeedbackStatus(e,feedback,"progress")} className="badge badge-secondary d-flex align-items-center text-button "><MdArrowBack /></span>
                                                    
                                                </div>
                                            }
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                }
               
               
            </div>
        </div>
    )
}

export default Feedback