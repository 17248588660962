import React,{useState} from 'react';
import {useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { AiOutlineQuestion,AiFillAudio} from 'react-icons/ai';
import { BsFillCameraVideoFill,BsPuzzle } from 'react-icons/bs';
import { GiBrain } from 'react-icons/gi';
import { MdPictureAsPdf } from 'react-icons/md';
import { useResizeDetector } from 'react-resize-detector';

const ConnectContent = ({handleSubmit,fixedType }) =>  {
    const profile = useSelector(state => state.firebase.profile)
    useFirestoreConnect([
        {
          collection: 'quizes',
          where: [['publisherId','==',profile.publisherId]]
        }
      ])
    const quizes = useSelector(state => state.firestore.ordered.quizes)

    useFirestoreConnect([
        {
          collection: 'files',
          where: [['publisherId','==',profile.publisherId]]
        }
      ])
    const files = useSelector(state => state.firestore.ordered.files)
    const excludedFileTypes = ["3D Model","Cover Image","ArRefImage"];
    const [activeType, setActiveType] = useState(false)
    const [curPage, setNextPage] = useState(0)
    let types = [];
    if(quizes && files){
        if(!fixedType){
            let quiztypes = quizes.map((quiz) => {
                return quiz.type;
            })
            let filetypes = files.map((file) => {
                return file.type;
            })
            types = [...quiztypes,...filetypes]
            types = types.filter((type,index) => {
                return types.indexOf(type) === index && !excludedFileTypes.includes(type)
            })
            types.sort();
        }else{
            types = [fixedType]
        }
        if(types.length > 0 && !activeType){
            setActiveType(types[0])
        }
        
        
    }

    const { width, height, ref } = useResizeDetector();
    const rowSize = Math.floor((height-50) / 210);
    const colSize = Math.floor(width / 210);
    const maxElements = rowSize * colSize;

    const getQuizIcon = (type) => {
        switch (type) {
            case "Quiz": return <AiOutlineQuestion />
            case "Memory": return <GiBrain />
            case "Lückentext": return <BsPuzzle />
            default: return <AiOutlineQuestion />
        }
           
    }

    const connectFile = (file) => {
        switch (file.type) {
            case "Image" : return handleSubmit({
                format:"original",
                path:file.path,
                type:file.type,
                text:file.name
            });
            default : return handleSubmit({
                filename:file.name,
                path:file.path,
                type:file.type,
                text:file.name
            });
        }
    }

    const getFilePreview = (file) => {
        switch (file.type) {
            case "Image": return <div className="m-2 d-flex flex-column align-items-center bg-white border border-gray-900 rounded text-button" style={{width:200,wordBreak:"break-all",textAlign:"center",boxSizing: "content-box",overflow:"hidden"}}>
                                    <div style={{maxWidth: 200,overflow: 'hidden'}}>
                                        <div style={{width:200,height:180,backgroundImage:`url(${file.path})`,backgroundSize: 'contain',backgroundRepeat:'no-repeat',backgroundPosition:'center'}}></div>
                                        <small style={{width: 180,whiteSpace: "nowrap",overflow: "hidden",textOverflow: "ellipsis",display:"block",marginLeft:10,marginTop:5,marginBottom:5}}> {file.name}</small>
                                    </div>
                                </div>
                                    
                                   
            case "Audio": return    <div className="m-2 p-2 px-3 d-flex flex-column align-items-center bg-white border border-gray-900 rounded text-button" style={{width:200,wordBreak:"break-all",textAlign:"center"}}>
                                        <div className="p-3 rounded-circle bg-primary text-white d-flex align-items-center justify-content-center" style={{width: "3em",height: "3em"}}>
                                            <AiFillAudio />
                                        </div>
                                        <small className="mt-2">{file.name}</small>
                                    </div>
            case "Video": return <div className="m-2 p-2 px-3 d-flex flex-column align-items-center bg-white border border-gray-900 rounded text-button" style={{width:200,wordBreak:"break-all",textAlign:"center"}}>
                                    <div className="p-3 rounded-circle bg-primary text-white d-flex align-items-center justify-content-center" style={{width: "3em",height: "3em"}}>
                                        <BsFillCameraVideoFill />
                                    </div>
                                    <small className="mt-2">{file.name}</small>
                                </div>
            case "Pdf": return  <div className="m-2 p-2 px-3 d-flex flex-column align-items-center bg-white border border-gray-900 rounded text-button" style={{width:200,wordBreak:"break-all",textAlign:"center"}}>
                                    <div className="p-3 rounded-circle bg-primary text-white d-flex align-items-center justify-content-center" style={{width: "3em",height: "3em"}}>
                                        <MdPictureAsPdf />
                                    </div>
                                    <small className="mt-2">{file.name}</small>
                                </div>
            default: return <div></div>
        }
           
    }

    const pagination = (files) => {
        const items = []
        if (files){
            var length = files.filter((file) => {return file.type === activeType}).length;
            if(length > maxElements){
                for(var i=0;i< length/maxElements;i++){
                    items.push(i+1);
                    
                }
            }
        }
        return items;
        
    }

    return  (
        <div className={"" + (fixedType ? 'h-90' : 'modal-body-container p-4')}>
            <div className={"" + (fixedType ? 'h-100 d-flex flex-column justify-content-between' : 'modal-content-container-fixed')} ref={ref}>

                <div >
            <div className="d-flex">
                {
                    !fixedType && types.map((type) => {
                        var active = activeType === type ? "bg-primary text-white" : "";
                        return <span onClick={() => {setActiveType(type)}} key={type} className={`px-2 py-1 mr-2 text-button rounded ${active}`}>{type}</span>
                    })
                }
            </div>
            <div className={"d-flex flex-wrap " + (fixedType ? "" : "mt-4") } >
                {files && 
                    files.filter((file) => {
                        return file.type === activeType
                    }).flatMap((file,index) => {
                        if(index >= curPage && index < curPage+maxElements){
                           
                            return <div key={file.id} onClick={() => connectFile(file)}>
                                {getFilePreview(file)}
                                </div>
                        }
                       
                    })
                    
                }
                
                {quizes && 
                    quizes.filter((quiz) => {
                        return quiz.type === activeType
                    }).map((quiz) => {
                        return <div key={quiz.id} onClick={() => {handleSubmit({
                            text:quiz.title,
                            quizId:quiz.id,
                            type:quiz.type
                        })}}  className="m-2 p-2 px-3 d-flex flex-column align-items-center bg-white border border-gray-900 rounded text-button" style={{width:200,wordBreak:"break-all",textAlign:"center"}}>
                        <div className="p-3 rounded-circle bg-primary text-white d-flex align-items-center justify-content-center" style={{width: "3em",height: "3em"}}>
                            {getQuizIcon(quiz.type)}
                        </div>
                        <span className="mt-2">{quiz.title}</span>
                        <small>{quiz.creator.name}</small>
                    </div>
                    })
                }
                
                
            </div>
            
            </div>
            {pagination(files).length > 0 &&
            <div className="d-flex align-items-center justify-content-center">
               
                    <div>
                        {pagination(files).map((num,index) => {
                            if(index*maxElements === curPage){
                                return <button onClick={() => setNextPage(index*maxElements)} type="button" key={num} className="mr-2 btn btn-primary">{num}</button>
                            }
                            return <button onClick={() => setNextPage(index*maxElements)} type="button" key={num} className="mr-2 btn btn-secondary">{num}</button>
                            
                        })}
                    </div>
                   
                </div>
            }
            {pagination(quizes).length > 0 &&
            <div className="d-flex align-items-center justify-content-center">

                    <div>
                        {pagination(quizes).map((num,index) => {
                            if(index === curPage){
                                return <button onClick={() => setNextPage(index*maxElements)} type="button" key={num} className="mr-2 btn btn-primary">{num}</button>
                            }
                            return <button onClick={() => setNextPage(index*maxElements)} type="button" key={num} className="mr-2 btn btn-secondary">{num}</button>
                            
                        })}
                    </div>
                    
                </div>
            } 
            </div>
        </div>
    )
}

export default ConnectContent;