import React from "react";
import { Form, Button, InputGroup } from "react-bootstrap";
import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai";
import ConnectContent from "./ConnectContent";
import FileDropzone from "../../components/FileDropzone";
import {
  MdArrowBack,
  MdArrowForward,
  MdDeleteForever,
  MdClose,
} from "react-icons/md";

const QuizQuestionForm = (props) => {
  const update = (e) => {
    props.update(e.target.name, e.target.value, props.number);
  };
  const addImage = (image) => {
    props.update("image", image.path, props.number);
    props.showImagePicker(false);
  };
  const removeImage = (image) => {
    props.update("image", null, props.number);
  };

  const addNewImage = (image) => {
    props.firestore.add("files", {
      path: image.path,
      type: "Image",
      name: image.filename,
      publisherId: props.profile.publisherId,
    });
    addImage(image);
  };
  const nav = [...Array(props.max - 1)].map((i, index) => {
    return (
      <Button
        key={index}
        variant={index === props.number ? "primary" : "secondary"}
        className="mr-2"
        onClick={() => props.jumpToStep(index + 1)}
      >
        {index + 1}
      </Button>
    );
  });
  console.log(props.numberAnswers);
  const numberAnswers = props.numberAnswers ? props.numberAnswers : 4;
  return (
    <div className="modal-content-container">
      {!props.imagepicker && [
        <Form key="form">
          <Form.Group>
            <div className="d-flex align-items-center justify-content-between">
              <Form.Label>Frage {props.stepNum}</Form.Label>{" "}
              <Form.Label>
                <span
                  onClick={() => {
                    if (props.removeQuestion(props.number) !== false) {
                      props.jumpToStep(props.number);
                    }
                  }}
                  className="text-button text-primary d-flex align-items-center"
                >
                  <MdDeleteForever className="mr-1" /> Frage löschen
                </span>
              </Form.Label>
            </div>
            <Form.Control
              type="text"
              name="question"
              defaultValue={
                props.question.question ? props.question.question : ""
              }
              onChange={update}
              required
              placeholder="Welche Frage soll dem Nutzer gestellt werden?"
            />
          </Form.Group>
          <Form.Group>
            {props.question.image && (
              <div
                className="bg-white preview-image"
                style={{
                  position: "relative",
                  backgroundImage: `url(${props.question.image})`,
                  width: "100%",
                  height: 200,
                  backgroundPosition: "center",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <div
                  className="preview-image-delete"
                  onClick={() => removeImage()}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    background: "#00000080",
                  }}
                >
                  <div className="d-flex align-items-center justify-content-center w-100 h-100 text-button">
                    <MdClose size="2em" color="white" />
                  </div>
                </div>
              </div>
            )}
            {!props.question.image && (
              <div>
                <Form.Label>Bild (optional)</Form.Label>
                <div>
                  <span
                    className="text-button text-primary d-flex align-items-center"
                    onClick={() => props.showImagePicker(true)}
                  >
                    Aus Bibliothek auswählen <MdArrowForward className="ml-1" />
                  </span>
                  <div className="text-muted">oder</div>
                </div>
                <FileDropzone
                  handleUploadSuccess={(refId, path, filename) =>
                    addNewImage({ path, filename })
                  }
                  refId={123}
                  maxSize={10485760}
                  acceptedFileTypes="image/*"
                  type="Image"
                  pathType="quiz-images/"
                  description="(Nur *.jpeg, *.png oder *.gif Dateien werden akzeptiert, max. 10Mb)"
                />
              </div>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label>Antwortmöglichkeiten</Form.Label>
            <div className="row">
              {[...Array.from({ length: numberAnswers }).keys()].map(
                (index) => {
                  return (
                    <InputGroup
                      className="mb-2 col-6"
                      key={"questions" + index}
                    >
                      <InputGroup.Prepend>
                        <InputGroup.Checkbox
                          name={"solution" + index}
                          defaultChecked={
                            props.question.answers[index]
                              ? props.question.answers[index].correct
                              : false
                          }
                          onChange={update}
                          aria-label="Checkbox for following text input"
                        />
                      </InputGroup.Prepend>
                      <Form.Control
                        type="text"
                        name={"answer" + index}
                        defaultValue={
                          props.question.answers[index]
                            ? props.question.answers[index].answer
                            : ""
                        }
                        onChange={update}
                        required
                        placeholder={"Antwort " + (index + 1)}
                      />
                    </InputGroup>
                  );
                }
              )}
            </div>
          </Form.Group>
        </Form>,
        <div
          key="navi"
          className="d-flex align-items-center justify-content-between"
        >
          <span
            className="text-button text-primary d-flex align-items-center mr-2"
            onClick={() => {
              props.jumpToStep(0);
            }}
          >
            <AiFillCaretLeft className="mr-1" /> Zurück
          </span>
          <div>{nav}</div>
          <span
            className="text-button text-primary d-flex align-items-center"
            onClick={() => {
              props.add();
              props.jumpToStep(props.max);
            }}
          >
            Neue Frage <AiFillCaretRight className="ml-1" />
          </span>
        </div>,
      ]}
      {props.imagepicker && (
        <div className="h-100">
          <span
            className="text-button ml-2 d-flex align-items-center "
            onClick={() => props.showImagePicker(false)}
          >
            <MdArrowBack className="mr-1" /> Zurück zum Quiz
          </span>
          <ConnectContent handleSubmit={addImage} fixedType="Image" />
        </div>
      )}
    </div>
  );
};

export default QuizQuestionForm;
