import React from 'react';
import {Form,Col,Row} from 'react-bootstrap'
import { MdDeleteForever } from 'react-icons/md';

const MemoryQuestionForm = (props) => {
    const update = (e) => {
         props.update(e.target.name, e.target.value,props.index);
    };
    return(
        <div>
        <Form as={Row}>
            <Form.Group as={Col}>
                <Form.Label>Frage {props.index +1}</Form.Label>
                <Form.Control type="text" onChange={update} defaultValue={props.question.question ? props.question.question :  ""}  name="question"  required placeholder="Frage?"/>
            </Form.Group>
            <Form.Group as={Col}>
                <Form.Label>Antwort {props.index +1}</Form.Label>
                <div className="d-flex align-items-center">
                <Form.Control type="text" onChange={update}  defaultValue={props.question.answer ? props.question.answer :  ""} name="answer" required placeholder="Antwort!"/>
                    <span onClick={() =>  {props.removeQuestionPair(props.index)}} className="text-button text-primary ml-3"><MdDeleteForever /></span>
                </div>
                
            </Form.Group>
        </Form>
      </div>
    )
}

export default MemoryQuestionForm;