import React from 'react';

const AGB = () => {
    return (
        <div className="container pt-5 pb-5">
            <div className="text-center">
                <h2>AGB</h2> 
                <h5>
                Allgemeine Geschäftsbedingungen der Firma 
                Hagstotz, Lindenmeier, Schmidt & Weiß, thea GbR
                </h5>
                
            </div>
            <ol className="pt-5">
                <li>
                    <h6 className="font-weight-bold">Vertragsabschluss</h6>
                    Verträge mit thea GbR kommen durch schriftliche Bestätigung zustande. Nachträgliche Änderungen von Verträgen müssen durch thea GbR bestätigt werden. Entgegenstehende AGB des Auftragsgebers werden nicht anerkannt und sind kein Bestandteil des Vertrages. Der Vertrag wird auf unbestimmte Zeit geschlossen. Das Vertragsverhältnis beginnt mit der Unterzeichnung dieses Vertrages und kann von beiden Parteien nach Ablauf eines Jahres jederzeit schriftlich mit einer Frist von 30 Tagen zum Ende eines Kalenderjahres beendet werden. 
                    Unberührt bleibt das Recht jeder Vertragspartei, den Vertrag aus wichtigem Grunde fristlos zu kündigen. Zur fristlosen Kündigung ist der Provider insbesondere berechtigt, wenn der Kunde fällige Zahlungen trotz Mahnung und Nachfristsetzung nicht leistet oder die vertraglichen Bestimmungen über die Nutzung der SaaS-Dienste verletzt. Eine fristlose Kündigung setzt in jedem Falle voraus, dass der andere Teil schriftlich abgemahnt und aufgefordert wird, den vermeintlichen Grund zur fristlosen Kündigung in angemessener Zeit zu beseitigen.

                </li>
                <li className="mt-3">
                    <h6 className="font-weight-bold">Preise</h6>
                    Alle Preise verstehen sich netto zzgl. gesetzliche Mehrwertsteuer. Preisangebote werden erst mit Abschluss des Vertrages verbindlich. Inzwischen eingetretene Lohnerhöhungen und feststellbare Kostensteigerungen berechtigen thea GbR, wenn zwischen Vertragsabschluss und Leistung mehr als vier Monate liegen, die Angebotspreise entsprechend zu erhöhen. Vertragsgegenstand. Thea GbR behält sich vor, Preisänderungen oder Irrtümer seitens der Medien an den Kunden zu verrechnen.

                </li>
                <li className="mt-3">
                    <h6 className="font-weight-bold">Lieferbedingungen</h6>
                    Liefertermin gilt zum schriftlich vereinbarten Termin. Bei Nichteinhaltung der vereinbarten Lieferfrist übernimmt thea GbR keine Haftung für Umstände, die thea GbR nicht verschuldet hat. Dies gilt besonders in Fällen von höherer Gewalt (z.B. wetterbedingte Überschreitungen, bei Foto- und Filmaufträgen etc.). Unverschuldete Schwierigkeiten bei Lieferverzug entbinden den Auftraggeber nicht von der Zahlungspflicht.
                </li>
                <li className="mt-3">
                    <h6 className="font-weight-bold">Eigentumsrecht</h6>
                    Thea GbR stellt Kund:innen für die Dauer dieses Vertrages die SOFTWARE in der jeweils aktuellen Version über das Internet entgeltlich zur Verfügung. Zu diesem Zweck richtet der Provider die SOFTWARE auf einem Server ein, der über das Internet Kund:innen erreichbar ist.
                Kund:innen behalten alle Rechte und das Eigentum an Ihren Inhalten. Thea GbR beansprucht keine Eigentumsrechte an Ihren Inhalten.
                Mit Beendigung des Vertragsverhältnisses wird der Provider dem Kunden unverzüglich sämtliche Daten, die auf dem ihm zugewiesenen Speicherplatz abgelegt sind, binnen drei Wochen herausgeben.                
                </li>
                <li className="mt-3">
                    <h6 className="font-weight-bold">Haftung</h6>
                    Für den rechtlichen Bestand aller vom Auftraggeber gemachten Angaben und Inhalte, insbesondere über Warenzeichen, Geschmacksmuster, Ausstattungen, Firmen- und Warenbezeichnungen, haftet der Auftraggeber. Daraus gegen thea GbR entstehende Ansprüche sind ausgeschlossen. In diesem Fall stellt der Auftraggeber thea GbR von jeder Haftung frei. 
                </li>
                <li className="mt-3">
                    <h6 className="font-weight-bold">Zahlungsbedingungen</h6>
                    Vergütungen sind innerhalb der getroffenen Vereinbarungen pünktlich zu zahlen.
                </li>
                <li className="mt-3">
                    <h6 className="font-weight-bold">Rücktrittsrechte</h6>
                    Bei Umständen, die die prompte Erfüllung der Zahlungsverpflichtungen in Frage stellen (Konkurseröffnung, Vergleichsverfahren oder wiederholte Zwangsvollstreckungen etc.), hat thea GbR das Recht, vom Vertrag zurückzutreten. Offene Rechnungen werden mit dem Vertragsrücktritt sofort fällig. Für noch nicht erbrachte Leistungen können Vorauszahlungen verlangt werden, wenn der Auftraggeber die Ausführung der weiteren Arbeiten zum Ausdruck bringt. Die vereinbarte Vergütung ist in jedem Falle zu zahlen, zuzüglich evtl. anfallender Neben- oder Fremdkosten; ersparte Aufwendungen für noch nicht erbrachte Leistungen werden mit 50% vergütet. Wird der Auftrag vom Auftraggeber storniert, sind die erbrachten Leistungen sofort zu bezahlen, im Übrigen gilt die im vorhergehenden Absatz festgelegte Regelung: Schadenersatzansprüche gegen den Auftraggeber wegen Vertragsverletzung bleiben ausdrücklich vorbehalten. Aufrechnung gegen die Ansprüche von thea GbR ist ausgeschlossen.
                </li>
                <li className="mt-3">
                    <h6 className="font-weight-bold">Aktualisierungen der Bedingungen</h6>
                    Aktualisierung der Bedingungen bedürfen der Schriftform. Eine vertragliche Anpassung ist zum Ablauf eines Geschäftsjahres für das kommende Geschäftsjahr möglich. Ein Antrag auf Aktualisierung kann nach Ablauf eines Jahres jederzeit schriftlich mit einer Frist von 30 Tagen zum Ende eines Kalenderjahres gestellt werden.
               </li>
               <li className="mt-3">
                    <h6 className="font-weight-bold">Geheimhaltung</h6>
                    Aktualisierung der Bedingungen bedürfen der Schriftform. Eine vertragliche Anpassung ist zum Ablauf eines Geschäftsjahres für das kommende Geschäftsjahr möglich. Ein Antrag auf Aktualisierung kann nach Ablauf eines Jahres jederzeit schriftlich mit einer Frist von 30 Tagen zum Ende eines Kalenderjahres gestellt werden.
                </li>
                <li className="mt-3">
                    <h6 className="font-weight-bold">Datenschutz</h6>
                    Thea GbR verpflichtet sich, mit allen von ihm im Zusammenhang mit der Vorbereitung, Durchführung und Erfüllung dieses Vertrages eingesetzten Mitarbeitenden und Nachunternehmer:innen eine mit vorstehendem Abs. 3 inhaltsgleiche Regelung zu vereinbaren.
                    Um die abgelegten Daten unserer Kunden zu verwalten nutzen wir die NoSQL-Dokumentendatenbank Cloud Firestore von Google zur Speicherung von Texten & Kundeninformationen. Zum Speichern von Dateien nutzen wir Google - Cloud Storage (https://cloud.google.com/terms/service-terms?hl=de ). Standort der Server und daher Datenstandort ist Frankfurt in Deutschland.
                </li>
                <li className="mt-3">
                    <h6 className="font-weight-bold">Gerichtsstand</h6>
                    Auf einen zustande kommenden Vertrag findet deutsches Recht unter Ausschluss des UN-Kaufrechts Anwendung.  Als Erfüllungsort und Gerichtsstand für alle aus diesem Vertrag sich ergebenden Ansprüche wird Konstanz festgelegt. Es gilt das Recht der Bundesrepublik Deutschland als vereinbart. Ist ein Vertragspartner kein Vollkaufmann, gilt die allgemeine Gerichtsstand Regelung.
                </li>
            </ol>
            <p className="text-center mt-5">Diese AGBs wurden letztmals am [22.01.2021] aktualisiert.</p>
    
        </div>
    );
};

export default AGB;