import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
    useFirestore,
  } from 'react-redux-firebase'

const DraggableList = ({items,sortArray,contentPath}) => {
    const firestore = useFirestore()
    const editContentOrder = (newOrder) => {
        firestore.update(
            contentPath,{
                contentOrder:newOrder
            }).then(() => {
            })
            .catch(err => {
                console.error('Error:', err) // eslint-disable-line no-console
                //showError(err.message || 'Could not add project')
                return Promise.reject(err)
            })
    }
    const onDragEnd = result => {
      let newOrder = [...sortArray];
      var id = newOrder.splice(result.source.index, 1)
      newOrder.splice(result.destination.index, 0, id[0])
      editContentOrder(newOrder);
    }
  
    return (
    <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
        {(provided, snapshot) => (
            <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            >
            {items.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided, snapshot) => (
                    <div className="mb-3"
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                   
                    >
                    {item.content}
                    </div>
                )}
                </Draggable>
            ))}
            {provided.placeholder}
            </div>
        )}
        </Droppable>
    </DragDropContext>
    );
  }
export default DraggableList;