import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { BsFillCameraVideoFill,BsThreeDots } from 'react-icons/bs';
import { MdPictureAsPdf,MdDeleteForever} from 'react-icons/md';

const FilePreview = ({file,icon,setSelectedFile,changeDialogStateDelete}) => {
    const formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
    
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    
        const i = Math.floor(Math.log(bytes) / Math.log(k));
    
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
    return (
        <div className="d-flex p-2 flex-column align-items-center bg-white border border-gray-900 rounded" style={{wordBreak:"break-all",textAlign:"center",boxSizing: "content-box",overflow:"hidden"}}>
            <div className="text-right w-100 mr-2">
            <Dropdown>
                <Dropdown.Toggle variant="light" className="bg-white px-2 border-0">
                    <BsThreeDots />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                <Dropdown.Item onClick={() => {setSelectedFile(file);changeDialogStateDelete(true)} } className="d-flex align-items-center text-button"><MdDeleteForever /><span className="ml-2">Löschen</span></Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            </div>
            <div style={{maxWidth: 250,overflow: 'hidden'}}>
                {icon ? 
                    <div style={{height:180}} className="d-flex align-items-center justify-content-center">
                        <div className="p-4 rounded bg-primary text-white d-flex align-items-center justify-content-center">
                        {icon}
                        </div>
                    </div>
                    :
                    <div style={{width:250,height:180,backgroundImage:`url(${file.path})`,backgroundSize: 'contain',backgroundRepeat:'no-repeat',backgroundPosition:'center'}}></div>
                }
                
                <small style={{width: 230,whiteSpace: "nowrap",overflow: "hidden",textOverflow: "ellipsis",display:"block",marginLeft:10,marginTop:5,marginBottom:5}}> {file.name}</small>
                <small className="text-muted"> {formatBytes(file.fileSize)}</small>
            </div>
            
        </div>
    );
};

export default FilePreview;