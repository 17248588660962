import React from 'react'
import TopicForm from './TopicForm'
import {Modal} from 'react-bootstrap'


const TopicNew = ({show,handleModalClose,handleSubmit}) => (
  <div>
    <Modal show={show} backdrop="static" keyboard={false} onHide={() => handleModalClose(false)}>
        <Modal.Header closeButton>
            <Modal.Title>Neues Thema</Modal.Title>
        </Modal.Header>
          <TopicForm
             handleSubmit={handleSubmit}
            />
        </Modal>
  </div>
)

export default TopicNew
