import React,{useState} from 'react'
import {Modal,Button} from 'react-bootstrap'
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

const DeleteModal = ({show,handleModalClose,handleSubmit}) => {
    const [loading,changeLoading] = useState(false)
    if(!show && loading){
        changeLoading(false)
    }
    return (
        <Modal show={show} onHide={() => handleModalClose()}>
        <Modal.Header closeButton>
            <Modal.Title>Achtung</Modal.Title>
        </Modal.Header>
        <Modal.Body>Bist du dir wirklich sicher?</Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" disabled={loading} onClick={() => handleModalClose()} >
            Abbrechen
            </Button>
            <Button variant="danger" disabled={loading} onClick={() => {changeLoading(true);handleSubmit();}} >
                {!loading && "Löschen" }
                {loading && <span className="d-flex align-items-center"><span className="mr-2">Einen Moment</span> <AiOutlineLoading3Quarters  className="loading-icon"/></span> }
            </Button>
        </Modal.Footer>
        </Modal>
    );
}


export default DeleteModal
