import React,{useState} from 'react'
import  Content from '../layout/Content';
import TaskList from '../tasks/TaskList';
import TaskNew from '../tasks/TaskNew';
import ChapterEdit from './ChapterEdit';
import DeleteModal from '../helpers/DeleteModal';
import { useSelector } from 'react-redux';
import {
  useFirestore,
  useFirebase,
  useFirestoreConnect,
  isEmpty,
  isLoaded
} from 'react-redux-firebase'
import { MdArrowBack,MdAddCircleOutline,MdMoreHoriz,MdModeEdit,MdDeleteForever,MdPerson } from 'react-icons/md';
import { AiOutlineClockCircle } from 'react-icons/ai';
import {Dropdown} from 'react-bootstrap';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';


const useChapterData = (bookId,chapterId,history) => {
  const firebase = useFirebase()
  const firestore = useFirestore()
  const app = firebase.app();
  const functions = app.functions("europe-west3");
  const recursiveDelete = functions.httpsCallable('recursiveDelete');
  const myChapter = 'myChapter'+chapterId
  useFirestoreConnect([
    {
      collection: `books`,
      doc: bookId,
      subcollections: [{ collection: "chapters",doc:chapterId }],
      storeAs: myChapter
    }
  ])
  const chapterData = useSelector(state => state.firestore.ordered[myChapter])
  function addTask(newInstance) {
    return firestore
      .add('books/'+bookId+"/chapters/"+chapterId+"/tasks", {
        ...newInstance,
        bookId:bookId,
        chapterId:chapterId
      })
      .then(() => {
        firestore.update('books/'+bookId+"/chapters/"+chapterId, {
          pageCount:firebase.firestore.FieldValue.increment(1)
        });
        toggleDialog()
        //showSuccess('Project added successfully')
      })
      .catch(err => {
        console.error('Error:', err) // eslint-disable-line no-console
        //showError(err.message || 'Could not add project')
        return Promise.reject(err)
      })
  }

  function editChapter(instance){
    return firestore.update(
      'books/'+bookId+"/chapters/"+chapterId,{
        ...instance,
        bookId:bookId,
      }).then(() => {
        toggleDialogEdit()
      })
      .catch(err => {
        console.error('Error:', err) // eslint-disable-line no-console
        //showError(err.message || 'Could not add project')
        return Promise.reject(err)
      })
  }

  function deleteChapter(){
    recursiveDelete({path:'books/'+bookId+'/chapters/'+chapterId,project:process.env.REACT_APP_FIREBASE_PROJECT_ID}).then(() => {
      firestore.update('books/'+bookId, {
        chapterCount:firebase.firestore.FieldValue.increment(-1)
      });
      history.push('/books/'+bookId)
    });
  }
  const [newDialogOpen, toggleDialog] = useState(false)
  const [editDialogOpen, toggleDialogEdit] = useState(false)
  const [deleteDialogOpen, toggleDialogDelete] = useState(false)

  return {newDialogOpen, toggleDialog,editDialogOpen, toggleDialogEdit,deleteDialogOpen, toggleDialogDelete,deleteChapter,editChapter,addTask,chapterData}

}


const Chapter = ({history,match}) => {
  const {newDialogOpen, toggleDialog,editDialogOpen, toggleDialogEdit,deleteDialogOpen, toggleDialogDelete,deleteChapter,editChapter,addTask,chapterData} 
  = useChapterData(match.params.book,match.params.chapter,history);
  if(!isLoaded(chapterData)){
    return <p>Loading...</p>
  }
  else if(isEmpty(chapterData)){
    return <div></div>
  }
  else{
    const chapter = chapterData[0];
    return(
      <div style={{height:"calc(100vh - 121px)"}}>
        <Content 
        leftHeadline={
          <div className="d-flex align-items-center justify-content-between">
            <Link to={`/books/${match.params.book}`} className="d-flex align-items-center"><MdArrowBack /><span className="ml-2">Kapitel Details</span></Link>
            <Dropdown alignRight={true}>
              <Dropdown.Toggle className="icon-toggle" variant="light" id="dropdown-basic">
                <MdMoreHoriz />
              </Dropdown.Toggle>
              <Dropdown.Menu className="border-0 shadow">
                <Dropdown.Item  onClick={() => {toggleDialogEdit(!editDialogOpen)}} className="d-flex align-items-center text-button"><MdModeEdit /><span className="ml-2">Bearbeiten</span></Dropdown.Item>
                <Dropdown.Item onClick={() => {toggleDialogDelete(!deleteDialogOpen)}} className="d-flex align-items-center text-button"><MdDeleteForever /><span className="ml-2">Löschen</span></Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        }
        rightHeadline={
          <span className="d-flex align-items-center justify-content-between text-button text-primary" onClick={() => {toggleDialog(!newDialogOpen)}}><span>Seiten</span><MdAddCircleOutline size="1.5em" /></span>
        }
        left={
          <div>
            <div className="bg-white p-4 shadow-border">
              <h1>{chapter.number}. {chapter.title}</h1>
              <div className="d-flex mt-4 flex-wrap">
                  <div className="mr-5">
                    <p className="text-muted">Erstellt von</p>
        <div className="d-flex align-items-center font-weight-medium small font-weight-bold" style={{height:35}}> <span className="bg-secondary text-white rounded-circle d-flex align-items-center justify-content-center mr-2" style={{width:23,height:23,overflow:'hidden',flexShrink:0}}><MdPerson size="3em" /></span> {chapter.creator.name}</div>
                  </div>
                  <div className="mr-4">
                    <p className="text-muted">Erstellt am</p>
                    <div className="d-flex align-items-center font-weight-medium small font-weight-bold" style={{height:35}}> <span className="bg-secondary text-white rounded-circle d-flex align-items-center justify-content-center mr-2" style={{width:23,height:23,overflow:'hidden',flexShrink:0}}><AiOutlineClockCircle size="3em" /></span> <Moment format="LL" date={chapter.created} /></div>
              
                  </div>
                </div>
            </div>
            {/* <div className="p-4 mt-4">
              <div className="d-flex justify-content-between">
                <h5>Zusammenfassungen</h5>
                <span className="text-button text-primary d-flex align-items-center"><MdAddCircleOutline size="1.5em" className="mr-1" /> Neue Zusammenfassung</span>
              </div>
                <p></p>
              </div> */}
          </div>
        } 
        right={
          <TaskList chapter={chapter} bookId={match.params.book} />
        }
        
        />
         <ChapterEdit show={editDialogOpen} handleModalClose={() => {toggleDialogEdit(!editDialogOpen)}} handleSubmit={editChapter} chapter={chapter}></ChapterEdit>
        <DeleteModal show={deleteDialogOpen} handleModalClose={() => {toggleDialogDelete(!deleteDialogOpen)}} handleSubmit={deleteChapter}></DeleteModal>
         <TaskNew show={newDialogOpen} handleModalClose={() => {toggleDialog(!newDialogOpen)}} handleSubmit={addTask}></TaskNew>
        </div>
    )
  }
}

export default Chapter
