import React from 'react'
import {Link} from 'react-router-dom'
import { MdChevronRight } from 'react-icons/md';
import { useSelector } from 'react-redux';
import {
  useFirestoreConnect,
  isLoaded,
} from 'react-redux-firebase'

const useTopicList = (subjectId) => {
  const myTopics = 'myTopics'+subjectId
  useFirestoreConnect([
    {
      collection: `subjects`,
      doc: subjectId,
      subcollections: [{ collection: "topics",orderBy: ['number'], }],
      storeAs: myTopics
    }
  ])
  
  const topics = useSelector(state => state.firestore.ordered[myTopics])

  return { topics}
}
const TopicList  = ({subject}) => {
  const {topics} = useTopicList(subject.id)
    if(isLoaded(topics)){
      return(
          <div >
            <div className="list-group">
                {topics.map(topic => (
                <Link key={topic.id} to={{pathname:subject.id+`/topics/${topic.id}`}} className="list-group-item border-0 list-group-item-action px-4 shadow-border mb-3">
                    <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex flex-column">
                        <span className="small text-muted">Thema {topic.number}</span>
                        <span>{topic.title}</span>
                    </div>
                    <MdChevronRight className="text-muted" size="1.5em" />
                    </div>
                </Link>
                ))}
            </div>
          </div>
      )
  }else{
    return <></>
  }
}

export default TopicList