import React,{useState} from 'react'
import {Form,Button, Modal} from 'react-bootstrap'
import Select from 'react-select'
import { useFirestoreConnect,useFirestore, isLoaded } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import {selectStyles} from '../../helpers/selectColors'

const UserForm = ({handleSubmit,user}) => {
  const profile = useSelector(state => state.firebase.profile)
  const firestore = useFirestore()
  useFirestoreConnect([{collection: 'publishers',}])
  const publishers = useSelector(state => state.firestore.ordered.publishers)

  const [selectedRole, changeSelectedRole] = useState(user ? {label:user.role,value:user.role} : "")
  const selectRole = (role) => {
    changeSelectedRole(role)
  }

  const [selectedPublisher, changeSelectedPublisher] = useState(user ? {label:user.publisherName,value:user.publisherId} : "")

  const onSubmit = event => {
    event.preventDefault()
    if(!user){
      const {email} = event.target.elements
      if(profile.role === "adminTeacher"){
        firestore.add(
          'publishers',{
            "name":"private"
          }).then((publisher) => {
            const values = {
              email:email.value,
              role:selectedRole.value,
              publisherId:publisher.id,
              publisherName:"private"
            }
            handleSubmit(values);
          })
      }else if(profile.isSuperAdmin){
        const values = {
          email:email.value,
          role:selectedRole.value,
          publisherId:selectedPublisher.value,
          publisherName:selectedPublisher.label,
        }
        handleSubmit(values);
      }else{
        const values = {
          email:email.value,
          role:selectedRole.value,
          publisherId:profile.publisherId,
          publisherName:profile.publisherName,
        }
        handleSubmit(values);
      }
     
    }else{
      if(selectedPublisher){
        const values = {
          role:selectedRole.value,
          publisherId:selectedPublisher.value,
          publisherName:selectedPublisher.label
        }
        handleSubmit(values);
      }else{
        const values = {
          role:selectedRole.value,
        }
        handleSubmit(values);
      }
     
      
    }

   
  }

  const roleDefault = {label:user ? user.role : null,value:user ? user.role : null}
  let roles = [{label:"admin",value:"admin"},{label:"creator",value:"creator"}];
  if(profile.role === "adminTeacher"){
    roles = [{label:"adminTeacher",value:"adminTeacher"},{label:"teacher",value:"teacher"}];
  }
 

  if(isLoaded(publishers)){
    return (
      <Form onSubmit={onSubmit}>
       <Modal.Body>
       {!user && 
       <Form.Group>
          <Form.Label>E-Mail Adresse</Form.Label>
          <Form.Control type="text" name="email"  defaultValue={user ? user.email : ""} required placeholder="E-Mail"/>
        </Form.Group>
      }
        <Form.Group>
          <Form.Label >Rolle</Form.Label>
          <React.Fragment>
          {user &&
          <Select
            placeholder={<span>Wähle eine Rolle für den Benutzer aus</span>}  
            defaultValue={roleDefault}
            onChange={selectRole}
            options={roles}
            styles={selectStyles}
          />
          }
          {!user &&
          <Select
            placeholder={<span>Wähle eine Rolle für den Benutzer aus</span>}  
            onChange={selectRole}
            options={roles}
            styles={selectStyles}
          />
          }
          <input
                tabIndex={-1}
                autoComplete="off"
                style={{ opacity: 0, height: 0,position:"absolute",width:"100%" }}
                required={true}
                onChange={selectRole}
                value={selectedRole}
              />
          </React.Fragment>
           </Form.Group>
          {profile.isSuperAdmin &&
            <div>
           <Form.Group>
          <Form.Label >Verlag</Form.Label>
          <React.Fragment>
          {user &&
          <Select
            placeholder={<span>Wähle einen Verlag für den Benutzer aus</span>}  
            defaultValue={selectedPublisher}
            onChange={changeSelectedPublisher}
            options={publishers.map(publisher => {
              return {label:publisher.name,value:publisher.id}
            })}
            styles={selectStyles}
          />
          }
          {!user &&
          <Select
            placeholder={<span>Wähle eine Rolle für den Benutzer aus</span>}  
            onChange={changeSelectedPublisher}
            options={publishers.map(publisher => {
              return {label:publisher.name,value:publisher.id}
            })}
            styles={selectStyles}
          />
          }
          <input
                tabIndex={-1}
                autoComplete="off"
                style={{ opacity: 0, height: 0,position:"absolute",width:"100%" }}
                required={true}
                onChange={changeSelectedPublisher}
                value={selectedPublisher}
              />
          </React.Fragment>
           </Form.Group>
           </div>
  }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" type="submit">Speichern</Button>
        </Modal.Footer>
      </Form>
    )

  }else{
    return(
      <div></div>
    )
  }
  
}

export default UserForm
