import React,{useState} from 'react';
import {Button,Form} from 'react-bootstrap'
import {
    useFirestore,
  } from 'react-redux-firebase'
import MemoryTitleForm from './MemoryTitleForm';
import MemoryQuestionForm from './MemoryQuestionForm';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import {AiFillCaretLeft} from 'react-icons/ai'
import { useSelector } from 'react-redux';
import {NotificationContainer, NotificationManager} from 'react-notifications';


const MemoryContent = ({dbQuiz,handleSubmit}) =>  {
    const profile = useSelector(state => state.firebase.profile)
    const auth = useSelector(state => state.firebase.auth)
    const firestore = useFirestore()
    const [state, updateState] = useState({
        step:1,
        quiz: {
        },
        questions:[
            {
                question:"",
                answer:""
            },
        ],
        title:"",
        quizquestionsId:""
    });

    if(dbQuiz && Object.keys(state.quiz).length === 0 ){
        let quizId = dbQuiz;
        firestore.get({ collection: 'quizes',doc:dbQuiz}).then((doc) => {
            const quiz = {...doc.data(),id:quizId}
            firestore.get({ collection: 'quizes/'+doc.id+'/quizquestions'}).then((querySnapshot) => {
                let questions = [];
                var title = "";
                var quizquestionsId = "";
                querySnapshot.forEach(function(doc) {
                    var dbQuestions = doc.data().questions
                    var dbAnswers = doc.data().answers
                    title = doc.data().task
                    quizquestionsId = doc.id
                    for(var i= 0;i<dbQuestions.length;i++){
                        questions.push({
                            question:dbQuestions[i],
                            answer:dbAnswers[i]
                        })
                    }
                });
                updateState({
                    ...state,
                    quiz,
                    questions,
                    title,
                    quizquestionsId
                });
            })
        })
    }

    const goToStep = (step) =>{
        updateState({
            ...state,
            step:step,
        });
    }

    const addQuestionPair = () => {
        const { questions } = state;
        questions.push({
            question:"",
            answer:""
        })
        updateState({
            ...state,
            questions
        });
    }

    const removeQuestionPair = (index) => {
        const { questions } = state;
        if(questions.length === 1){
            NotificationManager.error('Achtung Memory muss mindestens ein Paar enthalten.','Löschen Fehlgeschlagen!');
            return false;
        }
        let updatedQuestions = questions.filter((q,i) => i !== index)
        updateState({
            ...state,
            questions:updatedQuestions
        });
    }

    const updateMemory = (key, value) => {
        const { quiz } = state;
        quiz[key] = value;
        updateState({
            ...state,
            quiz,
        });
    };

    const updateQuestionPair = (key, value,index) => {
        const { questions } = state;
        questions[index][key] = value;
        updateState({
            ...state,
            questions
        });
    }
    const updateMemoryTask = (e) => {
        updateState({
            ...state,
            title:e.target.value
        });
    }

    const saveMemory = () => {
        if(!saveRequirements()){
            return false;
        }
        if(!dbQuiz){
            const { quiz,questions,title } = state;
            let answerArray = questions.map((question) => {
                return question.answer
            })
            let questionArray = questions.map((question) => {
                return question.question
            })
            firestore.add("quizes", {
                ...quiz,
                type:"Memory"
            }).then((data) => {
                let quizId = data.id
                firestore.add('quizes/'+data.id+'/quizquestions', {
                    answers:answerArray,
                    questions:questionArray,
                    task:title
                }).then(() => {
                    handleSubmit({
                        text:quiz.title,
                        type:'Memory',
                        quizId:quizId
                    })
                })
            })
        }else{
            updateMemoryFields();
        }
    }

    const updateMemoryFields = () => {
        const { quiz,questions,title,quizquestionsId } = state;
        let answerArray = questions.map((question) => {
            return question.answer
        })
        let questionArray = questions.map((question) => {
            return question.question
        })
        firestore.update("quizes/"+dbQuiz, {
                ...quiz,
                type:"Memory",
                publisherId:profile.publisherId,
                creator:{
                    id:auth.uid,
                    name:profile.displayName
                }
            }).then(() => {
                firestore.update('quizes/'+dbQuiz+'/quizquestions/'+quizquestionsId, {
                    answers:answerArray,
                    questions:questionArray,
                    task:title
                }).then(() => {
                    handleSubmit({
                        text:quiz.title
                    })
                })
            })
    }

    const saveRequirements = () => {
        const { questions,title,quiz } = state;
        let ready = true;
        if(!quiz.title){
            NotificationManager.error('Achtung ohne Titel kann das Memory nicht gespeichert werden.','Titel fehlt!');
            ready = false;
        }
        if(!title){
            NotificationManager.error('Achtung ohne Aufgabentitel kann das Memory nicht gespeichert werden.','Aufgabentitel fehlt!');
            ready = false;
        }
        questions.flatMap((question,index) => {
            if(question.question === ""){
                NotificationManager.error('Achtung Frage '+(index+1)+' hat keinen Text','Frage fehlt!');
                ready = false;
            }
            if(question.answer === ""){
                NotificationManager.error('Achtung Frage '+(index+1)+' hat keine Antwort','Antwort fehlt!');
                ready = false;
            }
        })
        return ready;
    }

    const pairforms = state.questions.map((question,index) => (
        <MemoryQuestionForm question={question} update={updateQuestionPair} index={index} key={index} removeQuestionPair={removeQuestionPair}/> 
    ))
   
    return(
        <div className="modal-body-container">
            <div className="p-3 modal-content-container-fixed">
                {state.step === 1 && <MemoryTitleForm quiz={state.quiz} update={updateMemory} goToStep={goToStep}/>}
                {state.step === 2 && 
                <div className="modal-content-container">
                    <div className="modal-content-scroll pr-3">
                    <Form.Group>
                        <Form.Label>Aufgabe</Form.Label>
                        <Form.Control type="text"  name="task" onChange={updateMemoryTask} defaultValue={state.title ? state.title :  ""} required placeholder="Aufgabentitel"/>
                    </Form.Group>
                    {pairforms}
                    </div>
                    <div className="d-flex align-items-center justify-content-between pt-3">
                        <span className="text-button text-primary d-flex align-items-center" onClick={() => goToStep(1)}> <AiFillCaretLeft className="mr-1"/> Zurück</span>
                        <span className="text-button text-primary d-flex align-items-center" onClick={() => addQuestionPair()} > Neues Paar <AiOutlinePlusCircle className="ml-1"/></span>
                    </div>
                </div>
                }
            </div>
            <div className="p-3 bg-white d-flex justify-content-end">
                <Button variant="primary" onClick={saveMemory}>Speichern</Button>
            </div>
            <NotificationContainer/>
        </div>
    )   
}

export default MemoryContent;