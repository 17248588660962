import React from 'react'
import UserForm from './UserForm'
import {Modal} from 'react-bootstrap'


const UserEdit = ({show,handleModalClose,handleSubmit,user}) => (
  <div>
    <Modal show={show} onHide={() => handleModalClose(false)}>
        <Modal.Header closeButton>
            <Modal.Title>Neuer Benutzer</Modal.Title>
        </Modal.Header>
          <UserForm
             handleSubmit={handleSubmit}
             user={user}
            />
        </Modal>
  </div>
)

export default UserEdit;