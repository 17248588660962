import React, {useState} from 'react'
import ChapterList from '../chapters/ChapterList'
import { useSelector } from 'react-redux';
import {
  useFirestoreConnect,
  useFirestore,
  isLoaded,
  isEmpty,
  useFirebase
} from 'react-redux-firebase'
import  Content from '../layout/Content';
import ChapterNew from '../chapters/ChapterNew'
import { MdArrowBack,MdAddCircleOutline,MdMoreHoriz,MdModeEdit,MdDeleteForever,MdPerson } from 'react-icons/md';
import { AiOutlineClockCircle } from 'react-icons/ai';
import {Dropdown} from 'react-bootstrap';
import BookEdit from './BookEdit';
import DeleteModal from '../helpers/DeleteModal'
import { Redirect } from 'react-router-dom';
import {Row,Col} from 'react-bootstrap'
import Moment from 'react-moment';
import { Link } from 'react-router-dom';

const useBookData = (bookId,history) => {
  const myBook = 'myBook'+bookId
  useFirestoreConnect([
    {
      collection: 'books',doc:bookId,storeAs: myBook
    }
  ])
  const bookData = useSelector(state => state.firestore.ordered[myBook])
  const firebase = useFirebase()
  const firestore = useFirestore()
  const app = firebase.app();
  const functions = app.functions("europe-west3");
  const recursiveDelete = functions.httpsCallable('recursiveDelete');
  function addChapter(newInstance) {
    return firestore
      .add('books/'+bookId+"/chapters", {
        ...newInstance,
        bookId:bookId,
      })
      .then(() => {
        firestore.update('books/'+bookId, {
          chapterCount:firebase.firestore.FieldValue.increment(1)
        });
        toggleDialog()
        //showSuccess('Project added successfully')
      })
      .catch(err => {
        console.error('Error:', err) // eslint-disable-line no-console
        //showError(err.message || 'Could not add project')
        return Promise.reject(err)
      })
  }
  function deleteBook() {
    recursiveDelete({path:'books/'+bookId,project:process.env.REACT_APP_FIREBASE_PROJECT_ID}).then(() => {
      history.push('/');
    });
  }

  function editBook(instance){
    return firestore.update(
      'books/'+bookId,{
        ...instance
      }).then(() => {
        toggleDialogEdit()
      })
      .catch(err => {
        console.error('Error:', err) // eslint-disable-line no-console
        //showError(err.message || 'Could not add project')
        return Promise.reject(err)
      })
  }
  const [newDialogOpen, changeDialogState] = useState(false)
  const toggleDialog = () => {
    changeDialogState(!newDialogOpen)
  }

  const [editDialogOpen, changeDialogStateEdit] = useState(false)
  const toggleDialogEdit = () => {
    changeDialogStateEdit(!editDialogOpen)
  }

  const [deleteDialogOpen, changeDialogStateDelete] = useState(false)
  const toggleDialogDelete = () => {
    changeDialogStateDelete(!deleteDialogOpen)
  }
  useFirestoreConnect([
    {
      collection: 'subjects'
    }
  ])
  // Get books from redux state
  const subjects = useSelector(state => state.firestore.ordered.subjects)
  const profile = useSelector(state => state.firebase.profile)
  return {subjects,profile,deleteDialogOpen,toggleDialogDelete,editDialogOpen,toggleDialogEdit,newDialogOpen,toggleDialog,editBook,deleteBook,addChapter,bookData}
}

const Book = ({match,history}) => {
  const {subjects,profile,deleteDialogOpen,toggleDialogEdit,editDialogOpen,toggleDialogDelete,newDialogOpen,toggleDialog,editBook,deleteBook,addChapter,bookData}
  = useBookData(match.params.book,history)

  if(!isLoaded(bookData,subjects)){
    return <p>Loading...</p>
  }
  if(isEmpty(bookData)){
    return <Redirect to={{pathname: "/"}}></Redirect>
  }
  if((profile.role !== "admin") && bookData[0].publisherId !== profile.publisherId){
    return <Redirect to={{pathname: "/"}}></Redirect>
  }
  else{
    const book = bookData[0];
    return(
      <div style={{height:"calc(100vh - 121px)"}}>
        <Content 
        leftHeadline={
          <div className="d-flex align-items-center justify-content-between">
            <Link to={`/books`} className="d-flex align-items-center"><MdArrowBack /><span className="ml-2">Buch Details</span></Link>
            <Dropdown alignRight={true}>
              <Dropdown.Toggle className="icon-toggle" variant="light" id="dropdown-basic">
                <MdMoreHoriz />
              </Dropdown.Toggle>
              <Dropdown.Menu className="border-0 shadow">
                <Dropdown.Item  onClick={toggleDialogEdit} className="d-flex align-items-center text-button"><MdModeEdit /><span className="ml-2">Bearbeiten</span></Dropdown.Item>
                <Dropdown.Item onClick={toggleDialogDelete} className="d-flex align-items-center text-button"><MdDeleteForever /><span className="ml-2">Löschen</span></Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        }
        rightHeadline={
          <span className="d-flex align-items-center justify-content-between text-button text-primary" onClick={toggleDialog}><span>Kapitel</span><MdAddCircleOutline size="1.5em" /></span>
        }
        left={
          <div>
            <div className="bg-white p-4 shadow-border">
              <h1>{book.title}</h1>
              <div className="d-flex mt-4 flex-wrap">
                <div className="mr-5">
                  <p className="text-muted">Unterrichtsfach</p>
                  <div className={ `px-3 py-2 subject-${book.subject.name} text-center rounded small font-weight-bold` } style={{height:35}}>{book.subject.name}</div>
                </div>
                <div className="mr-5">
                  <p className="text-muted">Erstellt von</p>
                  <div className="d-flex align-items-center font-weight-medium small font-weight-bold" style={{height:35}}> <span className="bg-secondary text-white rounded-circle d-flex align-items-center justify-content-center mr-2" style={{width:23,height:23,overflow:'hidden',flexShrink:0}}><MdPerson size="3em" /></span> {book.creator.name}</div>
                </div>
                <div className="mr-4">
                  <p className="text-muted">Erstellt am</p>
                  <div className="d-flex align-items-center font-weight-medium small font-weight-bold" style={{height:35}}> <span className="bg-secondary text-white rounded-circle d-flex align-items-center justify-content-center mr-2" style={{width:23,height:23,overflow:'hidden',flexShrink:0}}><AiOutlineClockCircle size="3em" /></span> <Moment format="LL" date={book.created} /></div>
            
                </div>
              </div>
            </div>
            <Row>
             
              <Col md="6" lg="5">
              <div className="bg-white p-4 shadow-border mt-4">
                <h5>Buchcover</h5>
                <img src={book.image} alt="Book Cover" className="w-100" />
              </div>
              </Col>

              <Col md="12" lg="7">
              <div className="bg-white p-4 shadow-border mt-4">
                <h5>Beschreibung</h5>
                <p>{book.description}</p>
              </div>
              
              </Col>
              
            </Row>
          </div>
        } 
        right={
          <ChapterList book={book}/>
        }
        
        />
        <BookEdit show={editDialogOpen} handleModalClose={toggleDialogEdit} handleSubmit={editBook} book={book} subjects={subjects}></BookEdit>
        <DeleteModal show={deleteDialogOpen} handleModalClose={toggleDialogDelete} handleSubmit={deleteBook}></DeleteModal>
        <ChapterNew show={newDialogOpen} handleModalClose={toggleDialog} handleSubmit={addChapter}></ChapterNew>
        </div>
    )
  }
}

export default Book