import React, { useState } from 'react';
import {Container,Row,Col} from 'react-bootstrap'
import { MdAddCircleOutline,MdFilterList } from 'react-icons/md';
import DeleteModal from '../helpers/DeleteModal'
import { useSelector } from 'react-redux';
import BookNew from './BookNew';
import BookEdit from './BookEdit';
import BookCard from './BookCard';
import {
  useFirestore,
  useFirestoreConnect,
  isLoaded,
} from 'react-redux-firebase'

const useBookList = () => {
  const profile = useSelector(state => state.firebase.profile)
  const firestore = useFirestore()

  var query = ['title','>',''];
  if(!profile.isSuperAdmin){
    query = ['publisherId','==',profile.publisherId]
  }
  useFirestoreConnect([
    {
      collection: 'books',
      where:query
    }
  ])
  useFirestoreConnect([
    {
      collection: 'subjects'
    }
  ])
  // Get books from redux state
  const books = useSelector(state => state.firestore.ordered.books)
  const subjects = useSelector(state => state.firestore.ordered.subjects)

  // New dialog
  const [newDialogOpen, changeDialogState] = useState(false)
  const toggleDialog = () => {
    changeDialogState(!newDialogOpen)
  }

  const [editDialogOpen, changeDialogStateEdit] = useState(false)
  const toggleDialogEdit = () => {
    changeDialogStateEdit(!editDialogOpen)
  }

  const [deleteDialogOpen, changeDialogStateDelete] = useState(false)
  const toggleDialogDelete = () => {
    changeDialogStateDelete(!deleteDialogOpen)
  }

  const [selectedBook, changeSelectedBook] = useState(false)
  
  function addBook(newInstance) {
    return firestore
      .add('books', {
        ...newInstance,
      })
      .then(() => {
        toggleDialog()
        //showSuccess('Project added successfully')
      })
      .catch(err => {
        console.error('Error:', err) // eslint-disable-line no-console
        //showError(err.message || 'Could not add project')
        return Promise.reject(err)
      })
  }
  function deleteBook() {
      return firestore
        .delete({ collection: 'books', doc: selectedBook.id })
        .then(() => {
          toggleDialogDelete()
          changeSelectedBook(null)
        })
        .catch(err => {
          console.error('Error:', err) // eslint-disable-line no-console
          //showError(err.message || 'Could not add project')
          return Promise.reject(err)
        })
  }

  function editBook(instance){
    return firestore.update(
      'books/'+selectedBook.id,{
        ...instance
      }).then(() => {
        toggleDialogEdit()
        changeSelectedBook(null)
      })
      .catch(err => {
        console.error('Error:', err) // eslint-disable-line no-console
        //showError(err.message || 'Could not add project')
        return Promise.reject(err)
      })
  }

  return { books, newDialogOpen, toggleDialog,addBook,deleteBook,deleteDialogOpen,toggleDialogDelete,selectedBook,editDialogOpen,toggleDialogEdit,editBook,subjects }
}



const BookList = () => {
      const {
        books, newDialogOpen,toggleDialog,addBook,deleteBook,deleteDialogOpen,toggleDialogDelete,selectedBook,editDialogOpen,toggleDialogEdit,editBook,subjects
       } = useBookList()
       if(!isLoaded){
        return(<div></div>)
       }else{
      return(
        <Container>
        
          <BookNew show={newDialogOpen} handleModalClose={toggleDialog} handleSubmit={addBook} subjects={subjects}></BookNew>
          <BookEdit show={editDialogOpen} handleModalClose={toggleDialogEdit} handleSubmit={editBook} book={selectedBook}></BookEdit>
          <DeleteModal show={deleteDialogOpen} handleModalClose={toggleDialogDelete} handleSubmit={deleteBook}></DeleteModal>
          <Row>
          <Col >
            <div className="d-flex justify-content-between">
              <div>
                <span className="d-flex align-items-center small text-uppercase" style={{cursor:"pointer"}} >
                  <span className="text-muted d-flex align-items-center">
                    <MdFilterList className="mr-2"/>Sortiert:
                  </span>
                  <span className="ml-2">
                    A-Z
                  </span>
                </span>
              </div>
              <span className="d-flex align-items-center text-primary" style={{cursor:"pointer"}} onClick={toggleDialog}><MdAddCircleOutline className="mr-2"/>Neues Buch</span>
            </div>
          </Col>
          </Row>
          <Row className="mt-4">
            
            {
              books === undefined
              ? <p>Loading</p> :
              <div style={{display:'flex',flexWrap: "wrap"}}>
              {books.map(book => (
                <div className="col col-12 col-md-6  col-xl-4 col-xxl-3 mb-4" key={book.id}>
                  <BookCard  path="books" id={book.id} title={book.title}  image={book.image} subject={book.subject.name} chapterCount={book.chapterCount}/>
                </div>
              ))}
              </div>
            }
          </Row>
          </Container>
      )
    }
  }

export default BookList;