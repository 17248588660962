import React, { useState } from 'react';
import {Container,Row,Col} from 'react-bootstrap'
import { MdAddCircleOutline,MdFilterList } from 'react-icons/md';
import { useSelector } from 'react-redux';
import SubjectNew from './SubjectNew';
import SubjectCard from './SubjectCard'
import {
  useFirestore,
  useFirestoreConnect,
  isLoaded,
} from 'react-redux-firebase'

const useSubjectList = () => {
  const profile = useSelector(state => state.firebase.profile)
  const firestore = useFirestore()

  var query = ['title','>',''];
  if(!profile.isSuperAdmin){
    query = ['publisherId','==',profile.publisherId]
  }
  useFirestoreConnect([
    {
      collection: 'subjects',
      where:query
    }
  ])

  useFirestoreConnect([
    {
      collection: 'icons'
    }
  ])

  useFirestoreConnect([
    {
      collection: 'uniqueIds'
    }
  ])
  const icons = useSelector(state => state.firestore.ordered.icons)
  const subjects = useSelector(state => state.firestore.ordered.subjects)
  const uniqueIds = useSelector(state => state.firestore.ordered.uniqueIds)

  const [newDialogOpen, changeDialogState] = useState(false)
  const [editDialogOpen, changeDialogStateEdit] = useState(false)
  const [deleteDialogOpen, changeDialogStateDelete] = useState(false)
  const [selected, changeSelected] = useState(false)

  function makeid() {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < 9; i++ ) {
      if(i%3 === 0 && i > 0){
        result += "-"
       }
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
 }
  
  function addItem(newInstance) {
    let id = makeid();
    if(uniqueIds){
      while(uniqueIds.filter((dbId) => dbId.id === id).length > 0){
        id = makeid();
      }
    }
    firestore.add('uniqueIds',{
      id:id
    })
    return firestore
      .add('subjects', {
        ...newInstance,
        joinId:id
      })
      .then(() => {
        changeDialogState(false)
      })
      .catch(err => {
        console.error('Error:', err) // eslint-disable-line no-console
        return Promise.reject(err)
      })
  }
  function deleteItem() {
      return firestore
        .delete({ collection: 'subjects', doc: selected.id })
        .then(() => {
            changeDialogStateDelete(false)
            changeSelected(null)
        })
        .catch(err => {
          console.error('Error:', err) // eslint-disable-line no-console
          return Promise.reject(err)
        })
  }

  function editItem(instance){
    return firestore.update(
      'subjects/'+selected.id,{
        ...instance
      }).then(() => {
        changeDialogStateEdit(false)
        changeSelected(null)
      })
      .catch(err => {
        console.error('Error:', err) // eslint-disable-line no-console
        return Promise.reject(err)
      })
  }

  return {subjects, icons, selected,addItem,deleteItem,editItem, changeSelected,deleteDialogOpen,changeDialogStateDelete,editDialogOpen,changeDialogStateEdit,newDialogOpen,changeDialogState }
}



const SubjectList = () => {
      const {
        icons,subjects,addItem,newDialogOpen,changeDialogState
       } = useSubjectList()
       if(!isLoaded){
        return(<div></div>)
       }else{
      return(
        <Container>
        <Row>
          <SubjectNew show={newDialogOpen} handleModalClose={() => changeDialogState(false)} handleSubmit={addItem} icons={icons} ></SubjectNew>
          {/*<SubjectEdit show={editDialogOpen} handleModalClose={() => changeDialogStateEdit(false)} handleSubmit={editItem} subject={selected} icons={icons} /> */} 
          {/* <DeleteModal show={deleteDialogOpen} handleModalClose={toggleDialogDelete} handleSubmit={deleteBook}></DeleteModal> */} 
          <Col >
            <div className="d-flex justify-content-between">
              <div>
                <span className="d-flex align-items-center small text-uppercase" style={{cursor:"pointer"}} >
                  <span className="text-muted d-flex align-items-center">
                    <MdFilterList className="mr-2"/>Sortiert:
                  </span>
                  <span className="ml-2">
                    A-Z
                  </span>
                </span>
              </div>
              <span className="d-flex align-items-center text-primary" style={{cursor:"pointer"}} onClick={() => changeDialogState(true)} ><MdAddCircleOutline className="mr-2"/>Neues Fach</span>
            </div>
          </Col>
          </Row>
          <Row  className="mt-4">
          {
            subjects === undefined
            ? <p>Loading</p> :
            <div style={{display:'flex',flexWrap: "wrap",width:"100%"}}>
            {subjects.map(subject => (
               <div className="col col-12 col-md-6  col-xl-4 col-xxl-3 mb-4" key={subject.id}>
               <SubjectCard id={subject.id}  path="subjects" title={subject.title}  joinId={subject.joinId} image={subject.image} subject={subject.className} description={subject.description}/>
             </div>
            ))}
            </div>
          }
          </Row>
         
          </Container>
      )
    }
  }

export default SubjectList;