import React,{useState} from 'react';
import {Button, Form} from 'react-bootstrap'
import FileDropzone from '../../components/FileDropzone';
import { MdDeleteForever } from 'react-icons/md';

const VideoContent = ({handleSubmit,video,modeEdit,changeMode,taskId }) =>  {
    const saveVideoContent = (event) => {
        event.preventDefault();
        if(uploadedVideo){
            const {title} = event.target.elements
            handleSubmit({
                path:uploadedVideo.path,
                type:"Video",
                text:title.value,
                filename:uploadedVideo.name
            })
        }else{
            const {video_url} = event.target.elements
            handleSubmit({
                path:video_url.value,
                type:"Video",
                text:video_url.value
            })
        }
       
    }
    const [uploadedVideo, setUploadedVideo] = useState();
    if(modeEdit && !uploadedVideo){
        setUploadedVideo({
            name:video.filename,
            path:video.path
        })
    }
    const changeModes = () => {
        if(changeMode){
            changeMode(false)
        }
        setUploadedVideo(false);
    }
    return  (
        <div className="modal-body-container">
            <Form onSubmit={saveVideoContent} className="modal-body-container">
                 <div className="p-4">
                 <Form.Group>
                        <Form.Label >Titel</Form.Label>
                        <Form.Control type="text" name="title" defaultValue={video ? video.text : ""}  required placeholder="Titel der Video Datei" />
                    </Form.Group>
                    {!uploadedVideo && <FileDropzone handleUploadSuccess={(refId,path,filename) => setUploadedVideo({name:filename,path:path})} refId={taskId} maxSize={10485760} type="Video" acceptedFileTypes="video/mp4" pathType="task-videos/" description="(Nur *.mp4 Dateien werden akzeptiert, max. 10Mb)"  />}
                    {uploadedVideo && 
                       <div  className="d-flex align-items-center justify-content-between ">
                       <span>{uploadedVideo.name}</span>
                       <MdDeleteForever onClick={changeModes} className="text-button"/>
                     </div>}
                </div> 
                 {/* <div className="p-4">
                 <Form.Group>
                    <Form.Label>Video URL</Form.Label>
                    <Form.Control type="text" name="video_url"  defaultValue={video ? video.path : ""} required placeholder="URL zum Video (zum Beispiel Youtube)"/>
                </Form.Group>
                 </div> */}
                <div className="p-3 bg-white d-flex justify-content-end">
                    <Button disabled={uploadedVideo ? false : true} variant="primary" type="submit" >Speichern</Button>
                </div>
            </Form>
        </div>
    )
}

export default VideoContent;